import { Play } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState, useEffect, useRef } from "react";

export const TutorialVideo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const preloadVideo = document.createElement('video');
    preloadVideo.src = '/seertotaltutorial.mp4';
    preloadVideo.preload = 'auto';
    
    preloadVideo.addEventListener('loadeddata', () => {
      console.log('Product tour video preloaded successfully');
      setIsLoaded(true);
    });

    preloadVideo.load();

    return () => {
      preloadVideo.remove();
    };
  }, []);

  useEffect(() => {
    if (isOpen && videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button 
          variant="ghost" 
          className="flex items-center gap-2 text-muted-foreground hover:text-foreground transition-colors"
        >
          <div className="w-8 h-8 rounded-full bg-[#F2FCE2] text-emerald-600 flex items-center justify-center">
            <Play className="w-4 h-4" />
          </div>
          Watch Product Tour
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl p-0 bg-black">
        <video 
          ref={videoRef}
          className="w-full aspect-video"
          src="/seertotaltutorial.mp4"
          controls
          autoPlay
          playsInline
          preload="auto"
        >
          Your browser does not support the video tag.
        </video>
      </DialogContent>
    </Dialog>
  );
};