import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Plus, Loader2, X } from "lucide-react";
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { KeywordInput } from "./KeywordInput";
import { KeywordBadge } from "./KeywordBadge";

interface KeywordManagementProps {
  isOpen: boolean;
  onClose: () => void;
}

export const KeywordManagement = ({ isOpen, onClose }: KeywordManagementProps) => {
  const [keywords, setKeywords] = useState<any[]>([]);
  const [newKeyword, setNewKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [deletingKeywords, setDeletingKeywords] = useState<Set<string>>(new Set());
  const { toast } = useToast();
  const { user } = useAuth();

  useEffect(() => {
    if (isOpen && user) {
      fetchKeywords();
    }
  }, [isOpen, user]);

  const fetchKeywords = async () => {
    if (!user) return;
    
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('subreddit_keywords')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setKeywords(data || []);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error fetching keywords",
        description: error.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddKeyword = async () => {
    const trimmedKeyword = newKeyword.trim();
    
    if (!trimmedKeyword || !user) return;

    if (keywords.some(k => k.keyword.toLowerCase() === trimmedKeyword.toLowerCase())) {
      toast({
        variant: "destructive",
        title: "Duplicate keyword",
        description: "This keyword already exists"
      });
      return;
    }

    setIsAdding(true);
    try {
      const { data, error } = await supabase
        .from('subreddit_keywords')
        .insert([{ 
          keyword: trimmedKeyword,
          user_id: user.id
        }])
        .select()
        .single();

      if (error) throw error;

      setKeywords((prev) => [data, ...prev]);
      setNewKeyword("");
      toast({
        title: "Keyword added",
        description: "Your keyword has been added successfully"
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error adding keyword",
        description: error.message
      });
    } finally {
      setIsAdding(false);
    }
  };

  const handleDeleteKeyword = async (id: string) => {
    if (!user) return;

    setDeletingKeywords(prev => new Set(prev).add(id));
    try {
      console.log('Deleting keyword matches...');
      // First delete related tweet matches
      const { error: tweetMatchesError } = await supabase
        .from('tweet_matches')
        .delete()
        .eq('keyword_id', id);

      if (tweetMatchesError) throw tweetMatchesError;
      
      console.log('Deleting keyword matches...');
      // Then delete related keyword matches
      const { error: keywordMatchesError } = await supabase
        .from('keyword_matches')
        .delete()
        .eq('keyword_id', id);

      if (keywordMatchesError) throw keywordMatchesError;

      console.log('Deleting keyword...');
      // Finally delete the keyword itself
      const { error } = await supabase
        .from('subreddit_keywords')
        .delete()
        .eq('id', id)
        .eq('user_id', user.id);

      if (error) throw error;

      setKeywords((prev) => prev.filter((kw) => kw.id !== id));
      toast({
        title: "Keyword deleted",
        description: "Your keyword has been removed"
      });
    } catch (error: any) {
      console.error('Error in deletion process:', error);
      toast({
        variant: "destructive",
        title: "Error deleting keyword",
        description: error.message
      });
    } finally {
      setDeletingKeywords(prev => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-xl">
            <Plus className="h-5 w-5" />
            Manage Keywords
          </DialogTitle>
          <p className="text-sm text-gray-500 mt-1">
            Add keywords to monitor across thousands of communities
          </p>
        </DialogHeader>

        <div className="space-y-6 pt-4">
          <KeywordInput
            value={newKeyword}
            onChange={setNewKeyword}
            onAdd={handleAddKeyword}
            isAdding={isAdding}
          />

          <div className="border-t pt-4">
            <div className="flex justify-between items-center mb-3">
              <h3 className="text-sm font-medium">Your keywords</h3>
              <span className="text-xs text-gray-400">{keywords.length}/20 keywords</span>
            </div>
            <div className="flex flex-wrap gap-2">
              {isLoading ? (
                <div className="w-full flex items-center justify-center py-4">
                  <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
                </div>
              ) : keywords.length > 0 ? (
                keywords.map((kw) => (
                  <KeywordBadge
                    key={kw.id}
                    keyword={kw.keyword}
                    id={kw.id}
                    isDeleting={deletingKeywords.has(kw.id)}
                    onDelete={handleDeleteKeyword}
                  />
                ))
              ) : (
                <p className="text-sm text-gray-500">No keywords added yet</p>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};