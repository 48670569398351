import { useState, useRef, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { 
  Globe, 
  X, 
  Rocket,
  Target,
  Sparkles,
  ArrowRight,
  ArrowLeft,
  Bot,
  Loader2,
  AlertCircle
} from "lucide-react";
import { cn } from "@/lib/utils";
import { useLaunchAgent } from "@/hooks/useLaunchAgent";

interface AgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsAgentLaunched: (launched: boolean) => void;
}

interface KeywordItem {
  id?: string;  // Optional since new keywords won't have an ID yet
  keyword: string;
}

export const AgentModal = ({ isOpen, onClose, setIsAgentLaunched }: AgentModalProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [domain, setDomain] = useState("");
  const [noDomain, setNoDomain] = useState(false);
  const [offerContext, setOfferContext] = useState("");
  const [customerContext, setCustomerContext] = useState("");
  const [keywordInput, setKeywordInput] = useState("");
  const [keywords, setKeywords] = useState<KeywordItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const { user } = useAuth();
  const { toast } = useToast();
  const { launchAgent, isLaunching } = useLaunchAgent();

  useEffect(() => {
    if (isOpen && user) {
      fetchUserContext();
      fetchKeywords();
    }
  }, [isOpen, user]);

  const fetchUserContext = async () => {
    try {
      const { data, error } = await supabase
        .from('user_context')
        .select('*')
        .eq('user_id', user?.id)
        .maybeSingle();

      if (error) throw error;

      if (data) {
        setDomain(data.domain || '');
        setOfferContext(data.offer_context || '');
        setCustomerContext(data.customer_context || '');
      }
    } catch (error) {
      console.error('Error fetching context:', error);
    }
  };

  const fetchKeywords = async () => {
    try {
      const { data, error } = await supabase
        .from('subreddit_keywords')
        .select('id, keyword')
        .eq('user_id', user?.id);

      if (error) throw error;

      if (data) {
        setKeywords(data);
      }
    } catch (error) {
      console.error('Error fetching keywords:', error);
    }
  };

  const steps = [
    {
      title: "Target Domain",
      description: "Enter your website URL or domain name. This helps our AI understand your online presence.",
      icon: Globe
    },
    {
      title: "Business Context",
      description: "Tell us about your business and target audience to help our AI provide more relevant insights.",
      icon: Target
    },
    {
      title: "Keywords",
      description: "Add important keywords related to your business to monitor across social media.",
      icon: Sparkles
    }
  ];

  const handleNext = async () => {
    if (currentStep === 1 && !validateDomain()) return;
    if (currentStep === 2 && !validateContext()) return;
    
    if (currentStep < 3) {
      setCurrentStep(prev => prev + 1);
    } else {
      if (keywords.length < 8) {
        toast({
          title: "More keywords needed",
          description: "Please add at least 8 keywords to launch your agent.",
          variant: "destructive"
        });
        return;
      }
      
      setIsLoading(true);
      try {
        await saveAllData();
        await launchAgent();
        onClose();
      } catch (error) {
        console.error('Error:', error);
        toast({
          title: "Error",
          description: error.message,
          variant: "destructive"
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validateDomain = () => {
    if (!domain && !noDomain) {
      toast({
        title: "Domain required",
        description: "Please enter a domain or select 'I don't have a domain'",
        variant: "destructive"
      });
      return false;
    }
    return true;
  };

  const validateContext = () => {
    if (offerContext.length < 10 || customerContext.length < 10) {
      toast({
        title: "More information needed",
        description: "Please provide at least 10 characters for both business and customer descriptions",
        variant: "destructive"
      });
      return false;
    }
    return true;
  };

  const saveAllData = async () => {
    if (!user) return;
    setIsLoading(true);
    
    try {
      const { error: contextError } = await supabase
        .from('user_context')
        .upsert({
          user_id: user.id,
          domain,
          offer_context: offerContext,
          customer_context: customerContext,
          agent_launched: true
        }, {
          onConflict: 'user_id'
        });

      if (contextError) throw contextError;

      const newKeywords = keywords.filter(k => !k.id);
      for (const keywordItem of newKeywords) {
        const { error: keywordError } = await supabase
          .from('subreddit_keywords')
          .insert({ keyword: keywordItem.keyword, user_id: user.id })
          .single();

        if (keywordError && !keywordError.message.includes('duplicate')) {
          throw keywordError;
        }
      }
      
      setIsAgentLaunched(true);
      onClose();
    } catch (error: any) {
      toast({
        title: "Error saving data",
        description: error.message,
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleAddKeyword = () => {
    if (keywordInput.trim() && keywords.length < 10) {
      setKeywords(prev => [...prev, { keyword: keywordInput.trim() }]);
      setKeywordInput("");
    }
  };

  const handleDeleteKeyword = async (keywordToDelete: string) => {
    const keywordItem = keywords.find(k => k.keyword === keywordToDelete);
    
    if (keywordItem?.id) {
      try {
        console.log('Deleting keyword with ID:', keywordItem.id);
        
        const { error: tweetMatchesError } = await supabase
          .from('tweet_matches')
          .delete()
          .eq('keyword_id', keywordItem.id);

        if (tweetMatchesError) throw tweetMatchesError;

        const { error: keywordMatchesError } = await supabase
          .from('keyword_matches')
          .delete()
          .eq('keyword_id', keywordItem.id);

        if (keywordMatchesError) throw keywordMatchesError;

        const { error: deleteError } = await supabase
          .from('subreddit_keywords')
          .delete()
          .eq('id', keywordItem.id)
          .eq('user_id', user?.id);

        if (deleteError) throw deleteError;

        toast({
          title: "Keyword deleted",
          description: "Your keyword has been removed"
        });
      } catch (error: any) {
        console.error('Error deleting keyword:', error);
        toast({
          variant: "destructive",
          title: "Error deleting keyword",
          description: error.message
        });
        return;
      }
    }
    
    setKeywords(prev => prev.filter(k => k.keyword !== keywordToDelete));
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px] p-0 overflow-hidden bg-gradient-to-br from-white to-gray-50">
        <DialogTitle className="sr-only">AI Agent Setup</DialogTitle>
        
        <div className="h-1 bg-gray-100 w-full">
          <div 
            className="h-full bg-emerald-400 transition-all duration-500 ease-out"
            style={{ width: `${((currentStep - 1) / 2) * 100}%` }}
          />
        </div>

        <div className="p-6 flex-1 flex flex-col h-[550px]">
          <div className="text-center mb-6">
            <div className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-emerald-50 mb-2">
              <Bot className="h-5 w-5 text-emerald-500" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900">AI Agent Setup</h2>
            <p className="text-xs text-gray-500 mt-1">Configure your AI agent in 3 simple steps</p>
          </div>

          <div className="space-y-6 mb-6">
            <div className="flex justify-between px-4">
              {steps.map((step, index) => {
                const StepIcon = step.icon;
                return (
                  <div key={step.title} className="flex flex-col items-center">
                    <div 
                      className={cn(
                        "flex items-center justify-center w-8 h-8 rounded-full border-2 transition-all duration-300",
                        index + 1 === currentStep 
                          ? "border-emerald-400 bg-emerald-50 text-emerald-500" 
                          : index + 1 < currentStep
                            ? "border-emerald-400 bg-emerald-400 text-white"
                            : "border-gray-200 text-gray-400"
                      )}
                    >
                      <StepIcon className="h-4 w-4" />
                    </div>
                    <span className={cn(
                      "text-[10px] mt-1 font-medium",
                      index + 1 === currentStep 
                        ? "text-emerald-500"
                        : "text-gray-400"
                    )}>
                      {step.title}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="text-center px-4">
              <p className="text-sm text-gray-500">
                {steps[currentStep - 1].description}
              </p>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
            {currentStep === 1 && (
              <div className="space-y-3">
                <div className="relative">
                  <Input
                    placeholder="e.g., example.com"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    className="pl-10 bg-white/50 backdrop-blur-sm placeholder:text-gray-400/70 focus-visible:ring-success"
                    disabled={noDomain}
                  />
                  <Globe className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400/70" />
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="noDomain" 
                    checked={noDomain}
                    onCheckedChange={(checked) => {
                      setNoDomain(!!checked);
                      if (checked) {
                        setDomain("No domain yet");
                      } else {
                        setDomain("");
                      }
                    }}
                    className="focus-visible:ring-success"
                  />
                  <label htmlFor="noDomain" className="text-sm text-gray-600">
                    I don't have a domain yet
                  </label>
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div className="space-y-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Your business in 1 sentence</label>
                  <Textarea
                    placeholder="Describe what your business does..."
                    value={offerContext}
                    onChange={(e) => setOfferContext(e.target.value)}
                    className="h-[80px] bg-white/50 backdrop-blur-sm resize-none placeholder:text-gray-400/70 focus-visible:ring-success focus-visible:ring-offset-0"
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Your ideal customers</label>
                  <Textarea
                    placeholder="Describe your target audience..."
                    value={customerContext}
                    onChange={(e) => setCustomerContext(e.target.value)}
                    className="h-[80px] bg-white/50 backdrop-blur-sm resize-none placeholder:text-gray-400/70 focus-visible:ring-success focus-visible:ring-offset-0"
                  />
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div className="space-y-3">
                <Input
                  placeholder="Type keyword and press Enter"
                  value={keywordInput}
                  onChange={(e) => setKeywordInput(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddKeyword()}
                  className="bg-white/50 backdrop-blur-sm placeholder:text-gray-400/70 focus-visible:ring-success"
                  disabled={keywords.length >= 10}
                />
                
                <div className="flex flex-wrap gap-2 min-h-[80px] p-3 bg-white/30 backdrop-blur-sm rounded-lg border border-gray-100">
                  {keywords.map((keyword) => (
                    <Badge
                      key={keyword.keyword}
                      variant="secondary"
                      className="bg-emerald-50 text-emerald-700 border border-emerald-200 h-6 px-2 hover:bg-emerald-100 animate-fade-in"
                    >
                      {keyword.keyword}
                      <button
                        onClick={() => handleDeleteKeyword(keyword.keyword)}
                        className="ml-1 hover:text-emerald-900"
                      >
                        <X className="h-3 w-3" />
                      </button>
                    </Badge>
                  ))}
                  {keywords.length === 0 ? (
                    <div className="w-full h-full flex items-center justify-center text-gray-400 text-xs">
                      Add up to 10 keywords to monitor
                    </div>
                  ) : keywords.length < 8 ? (
                    <div className="w-full mt-2 flex items-center gap-2 text-amber-600 text-xs">
                      <AlertCircle className="h-3.5 w-3.5" />
                      Add {8 - keywords.length} more keyword{8 - keywords.length === 1 ? '' : 's'} to launch your agent. We recommend at least 10.
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>

          <div className="mt-6 pt-4 border-t border-gray-100 flex items-center justify-between">
            <Button
              variant="outline"
              onClick={handleBack}
              className="h-10 px-4 gap-2"
              disabled={currentStep === 1}
            >
              <ArrowLeft className="h-4 w-4" />
              Back
            </Button>

            <Button
              onClick={handleNext}
              disabled={
                (currentStep === 3 && (keywords.length < 8 || isLoading || isLaunching)) ||
                (currentStep === 1 && !domain && !noDomain) ||
                (currentStep === 2 && (offerContext.length < 10 || customerContext.length < 10))
              }
              className="bg-emerald-500 hover:bg-emerald-600 text-white h-10 px-6 gap-2 min-w-[120px]"
            >
              {currentStep === 3 ? (
                isLaunching ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Launching...
                  </>
                ) : (
                  <>
                    <Rocket className="h-4 w-4" />
                    Launch Agent
                  </>
                )
              ) : (
                <>
                  Next
                  <ArrowRight className="h-4 w-4" />
                </>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};