import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ExternalLink, Twitter } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { SearchItem } from "@/hooks/useSearchData";

interface ResultCardProps {
  result: SearchItem;
  onSelect: (result: SearchItem) => void;
}

export const ResultCard = ({ result, onSelect }: ResultCardProps) => {
  const isTwitter = result.source === 'twitter';
  const displayText = isTwitter ? result.text : result.body;
  const displayUrl = isTwitter ? result.twitter_url : 
    (result.permalink?.startsWith('http') ? result.permalink : `https://reddit.com${result.permalink}`);
  
  const getRelativeTime = () => {
    try {
      const date = isTwitter 
        ? new Date(result.created_at || 0)
        : new Date((result.created_utc || 0) * 1000);
      
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', date);
        return 'Date unavailable';
      }

      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date unavailable';
    }
  };

  return (
    <Card className="overflow-hidden relative rounded-2xl border border-gray-200 bg-white p-8">
      <div className="space-y-4">
        <div className="flex items-start justify-between">
          <div className="flex items-start gap-3">
            {isTwitter ? (
              <Twitter className="h-5 w-5 mt-0.5 text-blue-400" />
            ) : (
              <img 
                src="/redditlogo.png"
                alt="Reddit"
                className="h-5 w-5 mt-0.5"
              />
            )}
            <div className="space-y-1">
              <p className="text-sm font-medium text-gray-900">
                {isTwitter ? `@${result.author}` : `r/${result.subreddit}`}
              </p>
              <p className="text-xs text-gray-500">
                {result.author}
              </p>
            </div>
          </div>
          {isTwitter && (
            <div className="flex gap-2">
              <Badge variant="secondary" className="bg-blue-50/60 text-blue-700 border-2 border-blue-200">
                {result.retweet_count} RT
              </Badge>
              <Badge variant="secondary" className="bg-blue-50/60 text-blue-700 border-2 border-blue-200">
                {result.like_count} Likes
              </Badge>
            </div>
          )}
        </div>

        <div 
          className="text-xs text-gray-600 line-clamp-3 cursor-pointer hover:text-gray-900"
          onClick={() => onSelect(result)}
        >
          {displayText}
        </div>

        <div className="pt-3 flex items-center justify-between border-t border-gray-100">
          <span className="text-[11px] text-gray-500">
            {getRelativeTime()}
          </span>
          <Button 
            variant="ghost" 
            size="sm" 
            className="h-7 text-xs gap-1.5"
            onClick={() => window.open(displayUrl, '_blank')}
          >
            View {isTwitter ? 'Tweet' : 'Post'}
            <ExternalLink className="h-3 w-3" />
          </Button>
        </div>
      </div>
    </Card>
  );
};