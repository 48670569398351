import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { HelpCircle } from "lucide-react";
import { Link } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface SentimentFilterProps {
  selectedSentiments: string[];
  onSentimentToggle: (sentiment: string) => void;
}

export const SentimentFilter = ({ selectedSentiments, onSentimentToggle }: SentimentFilterProps) => {
  const sentiments = ['high', 'moderate', 'low'];
  
  const getSentimentColor = (sentiment: string, isSelected: boolean) => {
    const baseStyles = isSelected ? 'opacity-100' : 'opacity-50 hover:opacity-75';
    
    switch (sentiment.toLowerCase()) {
      case 'low':
        return cn('bg-red-100/60 text-red-700 border-2 border-red-200', baseStyles);
      case 'moderate':
        return cn('bg-yellow-100/60 text-yellow-700 border-2 border-yellow-200', baseStyles);
      case 'high':
        return cn('bg-green-100/60 text-green-700 border-2 border-green-200', baseStyles);
      default:
        return cn('bg-gray-100/60 text-gray-700 border-2 border-gray-200', baseStyles);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <HelpCircle className="h-4 w-4 text-gray-500 hover:text-gray-700 cursor-help transition-colors" />
          </TooltipTrigger>
          <TooltipContent className="max-w-[300px]">
            <div className="space-y-2">
              <p className="text-xs">Filter mentions by sentiment level:</p>
              <p className="text-xs text-gray-500">High: Very positive mentions that show strong enthusiasm, praise, or satisfaction</p>
              <p className="text-xs text-gray-500">Moderate: Balanced or neutral mentions, or mixed positive and negative sentiments</p>
              <p className="text-xs text-gray-500">Low: Critical or negative mentions that express concerns, dissatisfaction, or issues</p>
              <div className="mt-4">
                <Link to="/docs" className="text-xs hover:underline">
                  Learn more
                </Link>
              </div>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      
      <div className="flex flex-wrap gap-2">
        {sentiments.map((sentiment) => (
          <Badge
            key={sentiment}
            variant="secondary"
            className={cn(
              "cursor-pointer transition-opacity",
              getSentimentColor(sentiment, selectedSentiments.includes(sentiment))
            )}
            onClick={() => onSentimentToggle(sentiment)}
          >
            {sentiment.charAt(0).toUpperCase() + sentiment.slice(1)}
          </Badge>
        ))}
      </div>
    </div>
  );
};