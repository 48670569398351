import { useState, useEffect } from "react";
import { Search, Clock } from "lucide-react";
import { Input } from "@/components/ui/input";
import Sidebar from "@/components/Sidebar";
import { CommentTable } from "@/components/feed/CommentTable";
import { FeedTabs } from "@/components/feed/FeedTabs";
import { PlaygroundAgentButton } from "@/components/playground/PlaygroundAgentButton";
import { AgentModal } from "@/components/playground/AgentModal";
import { useApifyResults } from "@/hooks/useApifyResults";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { FeedKeywordFilter } from "@/components/feed/FeedKeywordFilter";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { useSearchParams } from 'react-router-dom';
import { cn } from "@/lib/utils";
import { ApifyResult } from "@/hooks/useApifyResults";

const Playground = () => {
  const [savedComments, setSavedComments] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedComments, setSelectedComments] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'feed');
  const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false);
  const [showUnmatched, setShowUnmatched] = useState(false);
  const [selectedSentiments, setSelectedSentiments] = useState<string[]>([]);
  const [platformFilter, setPlatformFilter] = useState<'all' | 'reddit' | 'twitter'>('all');
  const [sentimentSort, setSentimentSort] = useState<'asc' | 'desc' | null>(null);
  const [isAgentLaunched, setIsAgentLaunched] = useState(false);
  
  const { comments, isLoading, sortOrder, setSortOrder } = useApifyResults();
  const { toast } = useToast();
  const { user } = useAuth();

  const handleSentimentFilter = (sentiment: string) => {
    setSelectedSentiments(prev => {
      if (prev.includes(sentiment)) {
        return prev.filter(s => s !== sentiment);
      }
      return [...prev, sentiment];
    });
  };

  const handleAddSearchKeyword = (keyword: string) => {
    if (!searchKeywords.includes(keyword)) {
      setSearchKeywords([...searchKeywords, keyword]);
    }
  };

  const handleRemoveSearchKeyword = (keyword: string) => {
    setSearchKeywords(searchKeywords.filter(k => k !== keyword));
  };

  const filteredComments = activeTab === 'feed' 
    ? comments.filter(comment => {
        const matchesSearch = searchKeywords.length === 0 ? true : 
          searchKeywords.some(keyword => 
            comment.body.toLowerCase().includes(keyword.toLowerCase())
          );

        const matchesSentiment = selectedSentiments.length === 0 ? true :
          selectedSentiments.includes((comment as ApifyResult).sentiment || '');

        const isUnmatched = comment.keyword === 'unmatched';
        return matchesSearch && matchesSentiment && (showUnmatched ? true : !isUnmatched) && (platformFilter === 'all' ? true : comment.source === platformFilter);
      })
    : savedComments.filter(comment => {
        const matchesSearch = searchKeywords.length === 0 ? true : 
          searchKeywords.some(keyword => 
            comment.body.toLowerCase().includes(keyword.toLowerCase())
          );
        return matchesSearch && (platformFilter === 'all' ? true : comment.source === platformFilter);
      });

  // Add sentiment sorting to your filtered comments
  const sortedComments = [...filteredComments].sort((a, b) => {
    if (!sentimentSort) return 0;
    
    const sentimentOrder = { 'High': 3, 'Moderate': 2, 'Low': 1 };
    const aValue = sentimentOrder[(a as ApifyResult).sentiment] || 0;
    const bValue = sentimentOrder[(b as ApifyResult).sentiment] || 0;
    
    return sentimentSort === 'desc' 
      ? bValue - aValue 
      : aValue - bValue;
  });

  const handleSaveComments = async () => {
    if (!selectedComments.length || !user) return;

    const commentsToSave = comments.filter(c => selectedComments.includes(c.comment_id));
    
    try {
      const { error } = await supabase
        .from('exported_comments')
        .insert(
          commentsToSave.map(comment => ({
            user_id: user.id,
            comment_id: comment.comment_id,
            keyword: comment.keyword,
            body: comment.body,
            author: comment.author,
            score: comment.score,
            created_utc: comment.created_utc,
            permalink: comment.permalink
          }))
        );

      if (error) throw error;

      toast({
        title: "Comments saved",
        description: `${selectedComments.length} comments have been saved successfully.`,
      });
      
      setSelectedComments([]);
      if (activeTab === 'saved') {
        // Refresh saved comments
        fetchSavedComments();
      }
    } catch (error) {
      console.error('Error saving comments:', error);
      toast({
        variant: "destructive",
        title: "Error saving comments",
        description: "There was an error saving your comments. Please try again.",
      });
    }
  };

  const fetchSavedComments = async () => {
    if (!user) return;
    
    try {
      const { data, error } = await supabase
        .from('exported_comments')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Transform the data to match FeedItem structure
      const transformedData = data.map(item => ({
        comment_id: item.comment_id,
        author: item.author,
        body: item.body,
        created_utc: item.created_utc,
        score: item.score,
        permalink: item.permalink,
        source: 'reddit' as const,
        keyword: item.keyword
      }));

      setSavedComments(transformedData);
    } catch (error) {
      console.error('Error fetching saved comments:', error);
    }
  };

  useEffect(() => {
    if (activeTab === 'saved') {
      fetchSavedComments();
    }
  }, [activeTab, user]);

  useEffect(() => {
    const checkAgentStatus = async () => {
      if (!user?.id) return;
      
      try {
        const { data, error } = await supabase
          .from('user_context')
          .select('agent_launched')
          .eq('user_id', user.id)
          .single();
        
        if (error) throw error;
        
        setIsAgentLaunched(!!data?.agent_launched);
      } catch (error) {
        console.error('Error checking agent status:', error);
      }
    };

    checkAgentStatus();
  }, [user?.id]);

  // Update URL when tab changes
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setSearchParams(tab === 'feed' ? {} : { tab });
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white">
      <Sidebar />
      <div className="flex-1 flex flex-col min-w-0">
        <div className="sticky top-0 z-10 backdrop-blur-sm bg-white/80 p-6 pb-4 border-b">
          <div className="max-w-[1200px] mx-auto">
            <div className="flex items-center justify-between mb-2">
              <div>
                <h1 className="text-xl font-semibold text-gray-900">Playground</h1>
                <p className="text-xs text-gray-500 mt-1">Experiment with AI agent</p>
              </div>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div className="w-[400px]">
                  <FeedKeywordFilter
                    selectedKeywords={searchKeywords}
                    onKeywordAdd={handleAddSearchKeyword}
                    onKeywordRemove={handleRemoveSearchKeyword}
                  />
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <Switch
                    checked={showUnmatched}
                    onCheckedChange={setShowUnmatched}
                    className="data-[state=checked]:bg-blue-600"
                  />
                  <span className="text-sm text-gray-600">
                    See unmatched
                  </span>
                </div>
                <PlaygroundAgentButton 
                  onClick={() => setIsAgentModalOpen(true)} 
                  hasResults={filteredComments.length > 0}
                  isAgentLaunched={isAgentLaunched}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-x-auto scrollbar-custom">
          <div className="max-w-[1200px] mx-auto px-6 pt-4">
            <div className="flex items-center justify-between mb-4">
              <FeedTabs 
                tab={activeTab}
                setTab={handleTabChange}
                postCount={filteredComments.length}
                platformFilter={platformFilter}
                onPlatformChange={setPlatformFilter}
                currentPage={currentPage}
                totalPages={Math.ceil(filteredComments.length / 10)}
                onPageChange={setCurrentPage}
                selectedComments={selectedComments}
                onSaveComments={handleSaveComments}
              />
            </div>

            {activeTab === 'feed' ? (
              <>
                {isLoading && comments.length === 0 ? (
                  <div className="flex flex-col items-center justify-center py-12">
                    <div className="w-48 h-1 bg-gray-100 rounded-full overflow-hidden">
                      <div className="h-full bg-gray-300 animate-[loading_1s_ease-in-out_infinite]" />
                    </div>
                  </div>
                ) : comments.length === 0 ? (
                  <div className="flex items-center justify-center py-24 text-gray-500">
                    {isAgentLaunched ? (
                      <div className="flex flex-col items-center gap-3">
                        <div className="flex items-center gap-2 text-sm">
                          <Clock className="h-4 w-4 text-blue-500 animate-pulse" />
                          <p>The agent has been launched. Please wait for an email update.</p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm">
                        No results yet.{' '}
                        <Button 
                          variant="link" 
                          className="text-emerald-600 hover:text-emerald-700 p-0 h-auto font-normal"
                          onClick={() => setIsAgentModalOpen(true)}
                        >
                          Create your agent
                        </Button>
                        {' '}to get started.
                      </p>
                    )}
                  </div>
                ) : (
                  <CommentTable 
                    comments={sortedComments}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                    selectedComments={selectedComments}
                    onCommentSelect={setSelectedComments}
                    sortOrder={sortOrder}
                    onSortChange={setSortOrder}
                    platformFilter={platformFilter}
                    onPlatformChange={setPlatformFilter}
                    sentimentSort={sentimentSort}
                    onSentimentSortChange={setSentimentSort}
                  />
                )}
              </>
            ) : (
              <>
                {savedComments.length === 0 ? (
                  <div className="flex items-center justify-center py-24 text-gray-500">
                    <p className="text-sm">
                      No saved comments yet. Save comments in the Playground to see them here.
                    </p>
                  </div>
                ) : (
                  <CommentTable 
                    comments={sortedComments}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                    selectedComments={selectedComments}
                    onCommentSelect={setSelectedComments}
                    sortOrder={sortOrder}
                    onSortChange={setSortOrder}
                    platformFilter={platformFilter}
                    onPlatformChange={setPlatformFilter}
                    sentimentSort={sentimentSort}
                    onSentimentSortChange={setSentimentSort}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <AgentModal 
        isOpen={isAgentModalOpen}
        onClose={() => setIsAgentModalOpen(false)}
        setIsAgentLaunched={setIsAgentLaunched}
      />
    </div>
  );
};

export default Playground;