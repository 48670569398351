import { LucideIcon } from "lucide-react";
import { Card } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";

interface SocialPlatformCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

export const SocialPlatformCard = ({
  icon: Icon,
  title,
  description,
}: SocialPlatformCardProps) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate("/playground")}
      className="p-6 hover:shadow-md transition-all relative overflow-hidden cursor-pointer group"
    >
      <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-gray-100 opacity-0 group-hover:opacity-100 transition-opacity" />
      <div className="relative z-10">
        <div className="flex items-center justify-between mb-3">
          <h3 className="text-sm font-medium text-gray-900">{title}</h3>
          <Icon className="h-4 w-4 text-gray-600 group-hover:text-primary transition-colors" />
        </div>
        <p className="text-xs text-gray-500 leading-relaxed">{description}</p>
      </div>
    </Card>
  );
};