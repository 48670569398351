import { useState, useEffect } from "react";
import Sidebar from "@/components/Sidebar";
import { CommentTable } from "@/components/feed/CommentTable";
import { FeedKeywordFilter } from "@/components/feed/FeedKeywordFilter";
import { useToast } from "@/components/ui/use-toast";
import { FeedTabs } from "@/components/feed/FeedTabs";
import { useAuth } from "@/contexts/AuthContext";
import { useFeedData } from "@/hooks/useFeedData";
import { supabase } from "@/lib/supabase";

const Feed = () => {
  const [savedComments, setSavedComments] = useState<any[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedComments, setSelectedComments] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState('feed');
  const { toast } = useToast();
  const { user } = useAuth();
  const { 
    comments, 
    isLoading, 
    sortOrder, 
    setSortOrder,
    hasMore 
  } = useFeedData();

  useEffect(() => {
    const fetchSavedComments = async () => {
      if (!user) return;
      
      console.log('Fetching saved comments for user:', user.id);
      const { data, error } = await supabase
        .from('saved_comments')
        .select('*')
        .eq('user_id', user.id)
        .order('saved_at', { ascending: false });

      if (error) {
        console.error('Error fetching saved comments:', error);
        toast({
          variant: "destructive",
          title: "Error fetching saved comments",
          description: "There was an error loading your saved comments. Please try again.",
        });
        return;
      }

      console.log('Successfully fetched saved comments:', data?.length || 0);
      setSavedComments(data || []);
    };

    if (activeTab === 'saved') {
      fetchSavedComments();
    }
  }, [activeTab, user, toast]);

  const handleKeywordAdd = (keyword: string) => {
    setSelectedKeywords(prev => 
      prev.includes(keyword)
        ? prev
        : [...prev, keyword]
    );
    setCurrentPage(1);
  };

  const handleKeywordRemove = (keyword: string) => {
    setSelectedKeywords(prev => prev.filter(k => k !== keyword));
  };

  const handleSaveComments = async () => {
    if (!selectedComments.length || !user) return;

    const commentsToSave = comments.filter(comment => 
      selectedComments.includes(comment.comment_id)
    );

    for (const comment of commentsToSave) {
      try {
        const { error } = await supabase
          .from('saved_comments')
          .insert([{
            user_id: user.id,
            comment_id: comment.comment_id,
            author: comment.author,
            body: comment.body,
            created_utc: comment.created_utc,
            score: comment.score,
            permalink: comment.permalink
          }]);

        if (error && error.code !== '23505') {
          console.error('Error saving comment:', error);
          throw error;
        }
      } catch (error) {
        console.error('Error saving comment:', error);
        toast({
          variant: "destructive",
          title: "Error saving comment",
          description: "There was an error saving your comment. Please try again.",
        });
        return;
      }
    }

    toast({
      title: "Comments saved",
      description: `Successfully saved ${selectedComments.length} comment${selectedComments.length > 1 ? 's' : ''}.`,
    });

    // Refresh saved comments after saving
    const { data } = await supabase
      .from('saved_comments')
      .select('*')
      .eq('user_id', user.id)
      .order('saved_at', { ascending: false });
    
    console.log('Refreshed saved comments after save:', data?.length || 0);
    setSavedComments(data || []);
    setSelectedComments([]);
  };

  const filterComments = (comments: any[]) => {
    return comments.filter(comment => {
      // Keyword filter
      if (selectedKeywords.length === 0) return true;
      return selectedKeywords.some(keyword => 
        comment.body.toLowerCase().includes(keyword.toLowerCase())
      );
    });
  };

  // Apply filters to both feed and saved comments
  const currentComments = activeTab === 'feed' ? comments : savedComments;
  const filteredComments = filterComments(currentComments);
  const totalPages = Math.ceil(filteredComments.length / 10);

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white">
      <Sidebar />
      <div className="flex-1 flex flex-col min-w-0">
        <div className="sticky top-0 z-10 backdrop-blur-sm bg-white/80 p-6 pb-4 border-b">
          <div className="max-w-[1200px] mx-auto">
            <div className="flex items-center justify-between mb-2">
              <div>
                <h1 className="text-xl font-semibold text-gray-900">Feed</h1>
                <p className="text-xs text-gray-500 mt-1">Latest comments from monitored sources</p>
              </div>
            </div>
            <div className="mt-4">
              <FeedKeywordFilter 
                onKeywordAdd={handleKeywordAdd}
                selectedKeywords={selectedKeywords}
                onKeywordRemove={handleKeywordRemove}
              />
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-x-auto scrollbar-custom">
          <div className="max-w-[1200px] mx-auto px-6 pt-4">
            <div className="flex items-center justify-between mb-4">
              <FeedTabs 
                activeTab={activeTab} 
                onTabChange={setActiveTab}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                filteredCount={filteredComments.length}
                hasFilters={selectedKeywords.length > 0}
                selectedComments={selectedComments}
                onSaveComments={handleSaveComments}
              />
            </div>

            {isLoading && comments.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-12">
                <div className="w-48 h-1 bg-gray-100 rounded-full overflow-hidden">
                  <div className="h-full bg-gray-300 animate-[loading_1s_ease-in-out_infinite]" />
                </div>
              </div>
            ) : (
              <CommentTable 
                comments={filteredComments}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                selectedComments={selectedComments}
                onCommentSelect={setSelectedComments}
                sortOrder={sortOrder}
                onSortChange={setSortOrder}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feed;