import { useState, useEffect } from 'react';
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import Sidebar from "@/components/Sidebar";
import { MessageList } from "@/components/inbox/MessageList";
import { MessageDetail } from "@/components/inbox/MessageDetail";

type Message = RedditMessage | TwitterMessage;

interface BaseMessage {
  id: string;
  keyword: string;
  author: string;
  created_at: string;
  sentiment: string;
  sentiment_reasoning: string | null;
  permalink: string;
}

interface RedditMessage extends BaseMessage {
  type: 'reddit';
  body: string;
  subreddit: string;
  comment_created_at: string;
}

interface TwitterMessage extends BaseMessage {
  type: 'twitter';
  text: string;
  twitter_url: string;
  retweet_count?: number;
  like_count?: number;
}

const Inbox = () => {
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const { user } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');

  const { data: messages = [] } = useQuery({
    queryKey: ['high_and_moderate_sentiment_matches', user?.id],
    queryFn: async () => {
      console.log('Starting fetch for high and moderate sentiment matches...');
      console.log('User ID:', user?.id);
      
      if (!user?.id) {
        console.log('No user ID available, skipping fetch');
        return [];
      }

      try {
        // Fetch Reddit mentions with High or Moderate sentiment
        const { data: redditData, error: redditError } = await supabase
          .from('keyword_matches')
          .select('*')
          .eq('user_id', user.id)
          .in('sentiment', ['High', 'Moderate'])
          .order('created_at', { ascending: false });

        if (redditError) throw redditError;

        // Fetch Twitter mentions with High or Moderate sentiment
        const { data: twitterData, error: twitterError } = await supabase
          .from('tweet_matches')
          .select('*')
          .eq('user_id', user.id)
          .in('sentiment', ['High', 'Moderate'])
          .order('created_at', { ascending: false });

        if (twitterError) throw twitterError;

        // Transform Reddit data
        const redditMessages: RedditMessage[] = (redditData || []).map(item => ({
          ...item,
          type: 'reddit',
        }));

        // Transform Twitter data
        const twitterMessages: TwitterMessage[] = (twitterData || []).map(item => ({
          ...item,
          type: 'twitter',
          permalink: item.twitter_url // For consistency in the UI
        }));

        // Combine all messages
        const allMessages = [...redditMessages, ...twitterMessages];

        // Deduplicate messages based on content
        const uniqueMessages = allMessages.reduce((acc, current) => {
          const isDuplicate = acc.some(item => {
            if (current.type === 'reddit' && item.type === 'reddit') {
              return (item as RedditMessage).body === (current as RedditMessage).body;
            }
            if (current.type === 'twitter' && item.type === 'twitter') {
              return (item as TwitterMessage).text === (current as TwitterMessage).text;
            }
            return false;
          });

          if (!isDuplicate) {
            acc.push(current);
          }
          return acc;
        }, [] as Message[]);

        // Sort by date
        const sortedMessages = uniqueMessages.sort((a, b) => {
          const dateA = new Date(a.type === 'reddit' ? a.comment_created_at : a.created_at);
          const dateB = new Date(b.type === 'reddit' ? b.comment_created_at : b.created_at);
          return dateB.getTime() - dateA.getTime();
        });

        console.log('Combined messages:', sortedMessages);
        return sortedMessages;

      } catch (error) {
        console.error('Error in queryFn:', error);
        throw error;
      }
    },
    enabled: !!user?.id,
    refetchInterval: 30000
  });

  useEffect(() => {
    if (messages.length > 0 && !selectedMessage) {
      setSelectedMessage(messages[0]);
    }
  }, [messages, selectedMessage]);

  const handleNext = () => {
    if (!selectedMessage || !messages.length) return;
    
    const currentIndex = messages.findIndex(m => m.id === selectedMessage.id);
    if (currentIndex < messages.length - 1) {
      setSelectedMessage(messages[currentIndex + 1]);
    }
  };

  const handlePrevious = () => {
    if (!selectedMessage || !messages.length) return;
    
    const currentIndex = messages.findIndex(m => m.id === selectedMessage.id);
    if (currentIndex > 0) {
      setSelectedMessage(messages[currentIndex - 1]);
    }
  };

  const getCurrentMessageIndex = () => {
    if (!selectedMessage || !messages.length) return -1;
    return messages.findIndex(m => m.id === selectedMessage.id);
  };

  const currentIndex = getCurrentMessageIndex();

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white">
      <Sidebar />
      
      <div className="flex-1 flex">
        <div className="w-[380px] bg-white border-r flex flex-col">
          <div className="border-b p-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-3.5 w-3.5 text-gray-400" />
              <Input 
                type="search" 
                placeholder="Search messages..." 
                className="pl-9 h-8 text-xs bg-gray-50"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <MessageList 
            messages={messages}
            selectedMessage={selectedMessage}
            onSelectMessage={setSelectedMessage}
            searchQuery={searchQuery}
          />
        </div>

        <div className="flex-1 p-8">
          <MessageDetail 
            message={selectedMessage}
            onNext={handleNext}
            onPrevious={handlePrevious}
            hasNext={currentIndex < messages.length - 1}
            hasPrevious={currentIndex > 0}
          />
        </div>
      </div>
    </div>
  );
};

export default Inbox;