import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useAuth } from "@/contexts/AuthContext";

export const Header = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <header className="fixed top-0 left-0 right-0 bg-white border-b border-gray-200 z-50">
      <div className="max-w-[1200px] mx-auto px-4 h-[72px] flex items-center justify-between">
        {/* Logo */}
        <div className="flex items-center">
          <a href="/" className="flex items-center">
            <img 
              src="/seerlonglogo.png"
              alt="Logo" 
              className="h-8 w-auto md:h-11"
            />
          </a>
        </div>

        {/* Auth buttons */}
        <div className="flex items-center space-x-4">
          {user ? (
            <Button
              onClick={() => navigate('/dashboard')}
              className="bg-[#18181B] hover:bg-[#18181B]/90 text-white rounded-lg px-4 py-2 text-[15px] font-medium"
            >
              Dashboard
            </Button>
          ) : (
            <Button
              onClick={() => navigate('/auth')}
              className="bg-[#18181B] hover:bg-[#18181B]/90 text-white rounded-lg px-4 py-2 text-[15px] font-medium"
            >
              Sign In
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};