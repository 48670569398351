import { useState } from 'react';
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { formatDistanceToNow } from 'date-fns';
import { cn } from "@/lib/utils";
import { Twitter } from "lucide-react";

interface BaseMessage {
  id: string;
  keyword: string;
  author: string;
  created_at: string;
  sentiment: string;
  sentiment_reasoning: string | null;
  permalink: string;
}

interface RedditMessage extends BaseMessage {
  type: 'reddit';
  body: string;
  subreddit: string;
  comment_created_at: string;
}

interface TwitterMessage extends BaseMessage {
  type: 'twitter';
  text: string;
  twitter_url: string;
  retweet_count?: number;
  like_count?: number;
}

type Message = RedditMessage | TwitterMessage;

interface MessageListProps {
  messages: Message[];
  selectedMessage: Message | null;
  onSelectMessage: (message: Message) => void;
  searchQuery: string;
}

export const MessageList = ({ messages, selectedMessage, onSelectMessage, searchQuery }: MessageListProps) => {
  const filteredMessages = messages.filter(message => {
    if (!searchQuery) return true;
    const content = message.type === 'reddit' ? message.body : message.text;
    return content.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const getRelativeTime = (message: Message) => {
    try {
      // For Reddit messages, use comment_created_at if available, otherwise fallback to created_at
      let dateStr = message.type === 'reddit' 
        ? (message.comment_created_at || message.created_at)
        : message.created_at;

      // Handle Unix timestamp (seconds since epoch)
      if (typeof dateStr === 'number' || !isNaN(Number(dateStr))) {
        const timestamp = Number(dateStr);
        // Check if timestamp is in seconds (Unix timestamp) or milliseconds
        const date = timestamp > 1e12 ? new Date(timestamp) : new Date(timestamp * 1000);
        if (!isNaN(date.getTime())) {
          return formatDistanceToNow(date, { addSuffix: true });
        }
      }

      // Handle ISO string date
      const date = new Date(dateStr);
      if (!isNaN(date.getTime())) {
        return formatDistanceToNow(date, { addSuffix: true });
      }

      console.error('Invalid date value:', dateStr);
      return 'Date unavailable';
    } catch (error) {
      console.error('Error formatting date:', error, message);
      return 'Date unavailable';
    }
  };

  return (
    <div className="flex-1 overflow-y-auto">
      {filteredMessages.map((message) => (
        <div
          key={message.id}
          className={cn(
            "p-4 border-b cursor-pointer transition-colors",
            selectedMessage?.id === message.id 
              ? "bg-blue-50/50" 
              : "hover:bg-gray-50"
          )}
          onClick={() => onSelectMessage(message)}
        >
          <div className="flex flex-col gap-2 max-w-full pr-4">
            <div className="flex items-start justify-between gap-3">
              <div className="flex-1 min-w-0">
                <h3 className="text-xs font-medium truncate leading-tight">
                  {message.type === 'reddit' 
                    ? `New mention from r/${message.subreddit}`
                    : `New mention on X`}
                </h3>
              </div>
              <div className="flex items-center gap-2 shrink-0">
                <time className="text-[10px] text-gray-400 whitespace-nowrap">
                  {getRelativeTime(message)}
                </time>
                {message.type === 'reddit' ? (
                  <img 
                    src="/redditlogo.png" 
                    alt="Reddit"
                    className="h-4 w-4 opacity-30"
                  />
                ) : (
                  <Twitter className="h-4 w-4 text-blue-400 opacity-30" />
                )}
              </div>
            </div>
            
            <p className="text-[11px] text-gray-500 line-clamp-2 break-words overflow-hidden">
              {message.type === 'reddit' ? message.body : message.text}
            </p>
            
            <div className="flex flex-wrap items-center gap-2 mt-1">
              <Badge 
                variant="secondary" 
                className="bg-blue-50/60 text-blue-700 border-2 border-blue-200"
              >
                {message.keyword}
              </Badge>
              <Badge 
                variant="secondary" 
                className="bg-emerald-50/60 text-emerald-700 border-2 border-emerald-200"
              >
                {message.author}
              </Badge>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};