import { useState, useEffect, useRef } from "react";
import { supabase } from "@/lib/supabase";

export interface SearchItem {
  id: string;
  comment_id?: string;
  tweet_id?: string;
  author: string;
  body?: string;
  text?: string;
  subreddit?: string;
  category?: string;
  score?: number;
  created_utc?: number;
  created_at?: string;
  permalink?: string;
  twitter_url?: string;
  url?: string;
  source: 'reddit' | 'twitter';
  retweet_count?: number;
  like_count?: number;
}

export const useSearchData = (keywords: string[] = []) => {
  const [items, setItems] = useState<SearchItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [batchCount, setBatchCount] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);
  
  const ITEMS_PER_SOURCE = 2500; // 2.5k per source per batch = 5k total per batch
  const MAX_ITEMS_PER_SOURCE = 25000; // 25k per source = 50k total
  const MAX_BATCHES = Math.ceil(MAX_ITEMS_PER_SOURCE / ITEMS_PER_SOURCE);

  const fetchBatch = async (currentOffset: number) => {
    try {
      // Stop if we've reached the max items
      if (items.length >= MAX_ITEMS_PER_SOURCE * 2) {
        return;
      }

      const [redditResult, tweetResult] = await Promise.all([
        supabase
          .from('reddit_comments')
          .select('*')
          .neq('author', 'AutoModerator')
          .order('created_utc', { ascending: false })
          .range(currentOffset, currentOffset + ITEMS_PER_SOURCE - 1),
        supabase
          .from('tweets')
          .select('*')
          .order('created_at', { ascending: false })
          .range(currentOffset, currentOffset + ITEMS_PER_SOURCE - 1)
      ]);

      const redditData = redditResult.data || [];
      const tweetData = tweetResult.data || [];

      setItems(prevItems => {
        // Ensure we don't exceed the total limit
        const newItems = [
          ...prevItems,
          ...redditData.map(comment => ({ ...comment, source: 'reddit' as const })),
          ...tweetData.map(tweet => ({
            ...tweet,
            body: tweet.text,
            permalink: tweet.twitter_url,
            score: tweet.like_count,
            source: 'twitter' as const
          }))
        ]
        .sort((a, b) => {
          const dateA = a.created_utc ? new Date(a.created_utc * 1000) : new Date(a.created_at || 0);
          const dateB = b.created_utc ? new Date(b.created_utc * 1000) : new Date(b.created_at || 0);
          return dateB.getTime() - dateA.getTime();
        })
        .slice(0, MAX_ITEMS_PER_SOURCE * 2); // Ensure total limit of 50k

        return newItems;
      });

      setBatchCount(prev => prev + 1);
      setOffset(currentOffset + ITEMS_PER_SOURCE);

    } catch (error) {
      console.error('Error fetching batch:', error);
    } finally {
      if (batchCount === 0) setIsLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    setItems([]);
    setOffset(0);
    setBatchCount(0);
    setIsLoading(true);
    fetchBatch(0);
    return () => abortControllerRef.current?.abort();
  }, []);

  // Load subsequent batches with minimal delay
  useEffect(() => {
    if (!isLoading && batchCount > 0 && batchCount < MAX_BATCHES) {
      setTimeout(() => fetchBatch(offset), 100);
    }
  }, [batchCount, isLoading]);

  const filteredComments = items.filter(item => {
    if (keywords.length === 0) return true;
    const content = item.body || item.text || '';
    return keywords.some(keyword => 
      content.toLowerCase().includes(keyword.toLowerCase())
    );
  });

  return {
    comments: filteredComments,
    isLoading: isLoading || (batchCount > 0 && batchCount < MAX_BATCHES),
    refetch: () => {
      setItems([]);
      setOffset(0);
      setBatchCount(0);
      setIsLoading(true);
      fetchBatch(0);
    }
  };
};