import { Search } from "lucide-react";

export const NoMatchesFound = () => {
  return (
    <div className="text-center py-12">
      <Search className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-4 text-lg font-medium text-gray-900">No matches found</h3>
      <p className="mt-2 text-sm text-gray-500">
        Try adjusting your filters to see more results.
      </p>
    </div>
  );
};