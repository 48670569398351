import { ArrowLeft, Search, MessageSquare, Globe, BookOpen, Settings, Plus, AlertTriangle, HelpCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const Documentation = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white">
      <div className="flex-1 overflow-auto">
        <div className="max-w-4xl mx-auto p-8">
          <Button 
            variant="ghost" 
            size="sm" 
            className="mb-8 text-gray-600 hover:text-gray-900"
            onClick={() => navigate('/dashboard')}
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>

          <div className="space-y-12">
            <div>
              <h1 className="text-3xl font-medium text-gray-900 mb-3">Documentation</h1>
              <p className="text-gray-600 text-sm leading-relaxed">
                Learn how to use Seer to find and monitor potential customers across social platforms.
              </p>
            </div>

            <Tabs defaultValue="keywords" className="space-y-8">
              <TabsList>
                <TabsTrigger value="keywords">Keywords</TabsTrigger>
                <TabsTrigger value="context">Business Context</TabsTrigger>
                <TabsTrigger value="social">Social Monitoring</TabsTrigger>
              </TabsList>

              <TabsContent value="keywords" className="space-y-8">
                <section className="space-y-4">
                  <h2 className="text-xl font-medium text-gray-900">Managing Keywords</h2>
                  
                  <div className="space-y-6">
                    <div className="bg-white rounded-lg border p-6">
                      <h3 className="text-base font-medium mb-4">Adding Keywords</h3>
                      <div className="space-y-3 text-sm text-gray-600">
                        <p>Keywords are used to monitor conversations across social platforms. Here's how to add them:</p>
                        <ol className="list-decimal pl-4 space-y-2">
                          <li>Navigate to the Social page</li>
                          <li>Click "Manage Keywords" button</li>
                          <li>Enter your keyword in the input field</li>
                          <li>Click "Add" or press Enter</li>
                        </ol>
                        <div className="mt-4 p-4 bg-amber-50 rounded-lg border border-amber-200">
                          <h4 className="font-medium text-amber-800 mb-2">Important Notes:</h4>
                          <ul className="list-disc pl-4 space-y-1 text-amber-700">
                            <li>Maximum 20 keywords per account</li>
                            <li>Duplicate keywords are not allowed</li>
                            <li>Keywords are case-insensitive</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white rounded-lg border p-6">
                      <h3 className="text-base font-medium mb-4">Best Practices</h3>
                      <div className="space-y-3 text-sm text-gray-600">
                        <ul className="space-y-3">
                          <li className="flex gap-2">
                            <span className="w-1.5 h-1.5 rounded-full bg-emerald-400 mt-2" />
                            <span>Use specific keywords related to your product or service</span>
                          </li>
                          <li className="flex gap-2">
                            <span className="w-1.5 h-1.5 rounded-full bg-emerald-400 mt-2" />
                            <span>Include common misspellings or variations</span>
                          </li>
                          <li className="flex gap-2">
                            <span className="w-1.5 h-1.5 rounded-full bg-emerald-400 mt-2" />
                            <span>Monitor competitor brand names</span>
                          </li>
                          <li className="flex gap-2">
                            <span className="w-1.5 h-1.5 rounded-full bg-emerald-400 mt-2" />
                            <span>Include industry-specific terminology</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </TabsContent>

              <TabsContent value="context" className="space-y-8">
                <section className="space-y-4">
                  <h2 className="text-xl font-medium text-gray-900">Business Context</h2>
                  
                  <div className="space-y-6">
                    <div className="bg-white rounded-lg border p-6">
                      <h3 className="text-base font-medium mb-4">Setting Up Context</h3>
                      <div className="space-y-3 text-sm text-gray-600">
                        <p>Business context helps our AI understand your needs better. Here's what to include:</p>
                        
                        <div className="space-y-4">
                          <div>
                            <h4 className="font-medium text-gray-700 mb-2">Domain</h4>
                            <p>Your website URL where customers can learn more about your product</p>
                          </div>
                          
                          <div>
                            <h4 className="font-medium text-gray-700 mb-2">Offer Context</h4>
                            <p>Describe what you're selling and its key benefits</p>
                          </div>
                          
                          <div>
                            <h4 className="font-medium text-gray-700 mb-2">Customer Context</h4>
                            <p>Define your ideal customer profile and their pain points</p>
                          </div>
                        </div>

                        <div className="mt-4 p-4 bg-blue-50 rounded-lg border border-blue-200">
                          <h4 className="font-medium text-blue-800 mb-2">Pro Tips:</h4>
                          <ul className="list-disc pl-4 space-y-1 text-blue-700">
                            <li>Be specific about your target audience</li>
                            <li>Include common customer problems</li>
                            <li>Mention unique selling points</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </TabsContent>

              <TabsContent value="social" className="space-y-8">
                <section className="space-y-4">
                  <h2 className="text-xl font-medium text-gray-900">Social Monitoring</h2>
                  
                  <div className="space-y-6">
                    <div className="bg-white rounded-lg border p-6">
                      <h3 className="text-base font-medium mb-4">Using the Social Page</h3>
                      <div className="space-y-3 text-sm text-gray-600">
                        <p>The social page helps you monitor and engage with potential customers. Here's how to use it:</p>
                        
                        <div className="space-y-4">
                          <div>
                            <h4 className="font-medium text-gray-700 mb-2">Platform Tabs</h4>
                            <ul className="list-disc pl-4 space-y-2">
                              <li>All: View mentions across all platforms</li>
                              <li>Reddit: Monitor subreddit discussions</li>
                              <li>X: Track Twitter conversations</li>
                              <li>YouTube: Follow video comments</li>
                            </ul>
                          </div>
                          
                          <div>
                            <h4 className="font-medium text-gray-700 mb-2">Filtering Results</h4>
                            <ul className="list-disc pl-4 space-y-2">
                              <li>Filter by keywords to focus on specific topics</li>
                              <li>Use sentiment filters to find positive/negative mentions</li>
                              <li>Sort by date or engagement metrics</li>
                            </ul>
                          </div>
                          
                          <div>
                            <h4 className="font-medium text-gray-700 mb-2">Engaging with Mentions</h4>
                            <ul className="list-disc pl-4 space-y-2">
                              <li>Click on any mention to see full context</li>
                              <li>Use "View Post" to open original post</li>
                              <li>Track sentiment analysis for each mention</li>
                            </ul>
                          </div>
                        </div>

                        <div className="mt-4 p-4 bg-emerald-50 rounded-lg border border-emerald-200">
                          <h4 className="font-medium text-emerald-800 mb-2">Best Practices:</h4>
                          <ul className="list-disc pl-4 space-y-1 text-emerald-700">
                            <li>Check mentions daily for timely responses</li>
                            <li>Focus on high-sentiment matches first</li>
                            <li>Use filters to identify trends</li>
                            <li>Engage authentically with potential customers</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation;