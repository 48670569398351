import { create } from 'zustand';

interface SearchState {
  isSearching: boolean;
  currentStep: number;
  setSearching: (isSearching: boolean) => void;
  setCurrentStep: (step: number) => void;
  resetSearch: () => void;
}

export const useSearchStore = create<SearchState>((set) => ({
  isSearching: false,
  currentStep: 0,
  setSearching: (isSearching) => set({ isSearching }),
  setCurrentStep: (step) => set({ currentStep: step }),
  resetSearch: () => set({ isSearching: false, currentStep: 0 }),
}));