import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { Search, RefreshCw, Flag } from "lucide-react";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { NotificationItem } from "./notifications/NotificationItem";
import { useAuth } from "@/contexts/AuthContext";
import { useNavigate } from "react-router-dom";

interface Notification {
  id: string;
  created_at: string;
  type: 'monitoring_added' | 'playground_scraped' | 'keyword_added';
  domain: string;
}

const NotificationPanel = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const { data: domainCards = [] } = useQuery({
    queryKey: ['domainCards', user?.id],
    queryFn: async () => {
      console.log('Fetching domain cards for user:', user?.id);
      
      if (!user?.id) {
        console.log('No user ID available, returning empty notifications');
        return [];
      }
      
      // Fetch recent domain cards for current user
      const { data: domainCards, error: domainCardsError } = await supabase
        .from('domain_cards')
        .select('id, created_at, text, domain')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(5);

      if (domainCardsError) {
        console.error("Error fetching domain cards:", domainCardsError);
        throw domainCardsError;
      }

      // Fetch recent keyword additions for current user
      const { data: keywordResults, error: keywordError } = await supabase
        .from('subreddit_keywords')
        .select('id, created_at, keyword')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(5);

      if (keywordError) {
        console.error("Error fetching keywords:", keywordError);
        throw keywordError;
      }

      // Combine and format notifications
      const monitoringNotifications = (domainCards || [])
        .filter(domainCard => domainCard.domain || domainCard.text)
        .map(domainCard => ({
          id: domainCard.id,
          created_at: domainCard.created_at,
          type: 'monitoring_added' as const,
          domain: domainCard.domain || domainCard.text
        }));

      const keywordNotifications = (keywordResults || [])
        .map(keyword => ({
          id: keyword.id,
          created_at: keyword.created_at,
          type: 'keyword_added' as const,
          domain: keyword.keyword
        }));

      // Combine, sort by date, and return latest 10
      const combinedNotifications = [...monitoringNotifications, ...keywordNotifications]
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        .slice(0, 10);

      console.log('Combined notifications for user:', combinedNotifications);
      return combinedNotifications;
    },
    enabled: !!user?.id,
    staleTime: 30000,
    refetchOnWindowFocus: true
  });

  const filteredNotifications = domainCards.filter(n => 
    n.domain?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderNotifications = (filteredNotifications: Notification[]) => {
    if (filteredNotifications.length === 0) {
      return (
        <div className="p-8 text-center text-sm text-gray-500">
          No notifications yet
        </div>
      );
    }

    return filteredNotifications.map((notification) => (
      <NotificationItem key={notification.id} notification={notification} />
    ));
  };

  return (
    <div className="w-[320px] bg-white border-l flex flex-col h-screen">
      <div className="border-b p-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-3.5 w-3.5 text-gray-400" />
          <Input 
            type="search" 
            placeholder="Search notifications..." 
            className="pl-9 h-8 text-xs bg-gray-50"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <Tabs defaultValue="actions" className="flex-1 flex flex-col">
        <div className="border-b">
          <TabsList className="w-full grid grid-cols-2 h-12 bg-transparent">
            <TabsTrigger 
              value="actions" 
              className="flex items-center gap-2 text-xs data-[state=active]:bg-transparent data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-blue-600 rounded-none px-4 py-3 text-gray-500 hover:text-gray-900 transition-colors"
            >
              <RefreshCw className="h-3.5 w-3.5" />
              <span className="ml-1.5">Actions</span>
              <span className="ml-1.5 bg-gray-100 text-gray-600 px-1.5 py-0.5 rounded-full text-[10px]">
                {filteredNotifications.length}
              </span>
            </TabsTrigger>
            <TabsTrigger 
              value="monitor" 
              className="flex items-center gap-2 text-xs data-[state=active]:bg-transparent data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-blue-600 rounded-none px-4 py-3 text-gray-500 hover:text-gray-900 transition-colors"
            >
              <Flag className="h-3.5 w-3.5" />
              <span className="ml-1.5">Monitor</span>
              <span className="ml-1.5 bg-gray-100 text-gray-600 px-1.5 py-0.5 rounded-full text-[10px]">0</span>
            </TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="actions" className="flex-1 p-0 m-0 overflow-hidden">
          <ScrollArea className="h-[calc(100vh-13rem)]">
            <div className="divide-y divide-gray-100">
              {renderNotifications(filteredNotifications)}
            </div>
          </ScrollArea>
        </TabsContent>

        <TabsContent value="monitor" className="flex-1 p-0 m-0">
          <ScrollArea className="h-[calc(100vh-13rem)]">
            <div className="p-8 text-center text-sm text-gray-500">
              Monitoring notifications coming soon
            </div>
          </ScrollArea>
        </TabsContent>
      </Tabs>

      <div className="p-4 border-t mt-auto">
        <Button 
          variant="ghost" 
          className="w-full justify-center text-xs text-gray-600 hover:text-gray-900"
          onClick={() => navigate('/inbox')}
        >
          View all notifications
        </Button>
      </div>
    </div>
  );
};

export default NotificationPanel;