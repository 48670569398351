import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/hooks/use-toast';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useNavigate } from 'react-router-dom';
import { Mail, Lock } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { PasswordReset } from './auth/PasswordReset';

interface AuthProps {
  returnUrl?: string;
}

export const Auth = ({ returnUrl }: AuthProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);
  const { signIn, signUp } = useAuth();
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleAuth = async (e: React.FormEvent, type: 'signin' | 'signup') => {
    e.preventDefault();
    try {
      setLoading(true);
      if (type === 'signup') {
        await signUp(email, password);
        
        const { data: { session } } = await supabase.auth.getSession();
        
        if (session?.access_token) {
          console.log('Creating incomplete subscription record...');
          const { error: subscriptionError } = await supabase
            .from('subscriptions')
            .insert({
              user_id: session.user.id,
              status: 'incomplete',
            });

          if (subscriptionError) {
            console.error('Error creating subscription:', subscriptionError);
            throw subscriptionError;
          }

          navigate('/loading-checkout');
        }
      } else {
        await signIn(email, password);
        toast({
          title: "Signed in successfully",
        });
        navigate(returnUrl || '/dashboard');
      }
    } catch (error: any) {
      console.error('Auth error:', error);
      toast({
        variant: "destructive",
        title: `Error ${type === 'signup' ? 'creating account' : 'signing in'}`,
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  if (isResetMode) {
    return <PasswordReset onBack={() => setIsResetMode(false)} />;
  }

  return (
    <div className="w-full max-w-md mx-auto p-6 space-y-8">
      <div className="text-center space-y-2">
        <h1 className="text-2xl font-semibold text-gray-900">Hey 👋</h1>
        <p className="text-sm text-gray-500">Please enter your details to continue</p>
      </div>

      <Tabs defaultValue="signup" className="w-full">
        <TabsList className="grid w-full grid-cols-2 mb-8">
          <TabsTrigger value="signin">Already a user?</TabsTrigger>
          <TabsTrigger value="signup">Sign Up</TabsTrigger>
        </TabsList>
        
        <TabsContent value="signin">
          <form onSubmit={(e) => handleAuth(e, 'signin')} className="space-y-4">
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
                <Mail className="h-4 w-4 text-gray-400" />
                Email
              </label>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="h-11"
                required
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
                <Lock className="h-4 w-4 text-gray-400" />
                Password
              </label>
              <Input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="h-11"
                required
              />
            </div>
            <Button
              type="button"
              variant="link"
              onClick={() => setIsResetMode(true)}
              className="p-0 h-auto text-sm text-emerald-600 hover:text-emerald-700"
            >
              Forgot password?
            </Button>
            <Button 
              type="submit" 
              disabled={loading} 
              className="w-full h-11 mt-6 bg-emerald-500 hover:bg-emerald-600"
            >
              {loading ? 'Signing in...' : 'Sign In'}
            </Button>
          </form>
        </TabsContent>
        
        <TabsContent value="signup">
          <form onSubmit={(e) => handleAuth(e, 'signup')} className="space-y-4">
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
                <Mail className="h-4 w-4 text-gray-400" />
                Email
              </label>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="h-11"
                required
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
                <Lock className="h-4 w-4 text-gray-400" />
                Password
              </label>
              <Input
                type="password"
                placeholder="Create a password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="h-11"
                required
                minLength={6}
              />
            </div>
            <Button 
              type="submit" 
              disabled={loading} 
              className="w-full h-11 mt-6 bg-emerald-500 hover:bg-emerald-600"
            >
              {loading ? 'Creating account...' : 'Start 7-day trial for $19'}
            </Button>
          </form>
        </TabsContent>
      </Tabs>
    </div>
  );
};