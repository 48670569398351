import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";

export interface Domain {
  id: number;
  domain: string;
  last_updated: string;
  user_id: string;
}

export const useDomains = () => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['domains', user?.id],
    queryFn: async () => {
      console.log('Fetching domains...');
      const { data, error } = await supabase
        .from('domain_cards')
        .select('*')
        .eq('user_id', user?.id)
        .not('domain', 'is', null);

      if (error) {
        console.error("Error fetching domains:", error);
        throw error;
      }

      console.log('Fetched domains:', data);
      return data || [];
    },
    enabled: !!user,
    staleTime: 30000, // Data considered fresh for 30 seconds
    gcTime: 300000, // Keep in cache for 5 minutes
  });
};