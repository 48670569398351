import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, Save } from "lucide-react";
import { Twitter, MessageCircle } from "lucide-react";
import { cn } from "@/lib/utils";

interface FeedTabsProps {
  tab: string;
  setTab: (value: string) => void;
  postCount: number;
  platformFilter: 'all' | 'reddit' | 'twitter';
  onPlatformChange: (platform: 'all' | 'reddit' | 'twitter') => void;
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
  selectedComments?: string[];
  onSaveComments?: () => void;
}

export const FeedTabs = ({ 
  tab, 
  setTab,
  postCount,
  platformFilter,
  onPlatformChange,
  currentPage,
  totalPages,
  onPageChange,
  selectedComments = [],
  onSaveComments,
}: FeedTabsProps) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-4">
        <div className="border-b flex items-center">
          <Button
            variant="ghost"
            className={cn(
              "relative h-9 rounded-none border-b-2 border-transparent px-4",
              tab === "feed" && "border-primary"
            )}
            onClick={() => setTab("feed")}
          >
            Feed
          </Button>
          <Button
            variant="ghost"
            className={cn(
              "relative h-9 rounded-none border-b-2 border-transparent px-4",
              tab === "saved" && "border-primary"
            )}
            onClick={() => setTab("saved")}
          >
            Saved
          </Button>
          <span className="px-4 text-sm text-muted-foreground border-b-2 border-transparent inline-flex items-center min-w-[100px] gap-1.5">
            <span className="w-[40px] text-right">{postCount}</span>
            <span>posts</span>
          </span>
        </div>

        <div className="flex items-center gap-2">
          <button
            onClick={() => onPlatformChange('all')}
            className={cn(
              "text-sm flex items-center gap-1.5 px-2",
              platformFilter === 'all' 
                ? "text-gray-900" 
                : "text-gray-500"
            )}
          >
            All
          </button>
          <div className="w-px h-4 bg-gray-200" />
          <button
            onClick={() => onPlatformChange('reddit')}
            className={cn(
              "text-sm flex items-center gap-1.5 px-2",
              platformFilter === 'reddit' 
                ? "text-orange-700" 
                : "text-gray-500"
            )}
          >
            <MessageCircle className="h-4 w-4" />
            Reddit
          </button>
          <div className="w-px h-4 bg-gray-200" />
          <button
            onClick={() => onPlatformChange('twitter')}
            className={cn(
              "text-sm flex items-center gap-1.5 px-2",
              platformFilter === 'twitter' 
                ? "text-blue-700" 
                : "text-gray-500"
            )}
          >
            <Twitter className="h-4 w-4" />
            Twitter
          </button>
        </div>
      </div>
      
      <div className="flex items-center gap-2">
        {tab === 'feed' && selectedComments.length > 0 && onSaveComments && (
          <Button
            variant="outline"
            size="sm"
            onClick={onSaveComments}
            className="mr-2 text-blue-600 border-blue-200 hover:bg-blue-50"
          >
            <Save className="w-4 h-4 mr-1" />
            Save {selectedComments.length} {selectedComments.length === 1 ? 'comment' : 'comments'}
          </Button>
        )}
        {currentPage && totalPages && onPageChange && (
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="h-8 w-8 p-0"
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>

            <Button
              variant="outline"
              size="sm"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="h-8 w-8 p-0"
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};