import { Badge } from "@/components/ui/badge";

interface NotificationBadgeProps {
  type: 'monitoring_added' | 'playground_scraped' | 'keyword_added';
  domain: string;
}

export const NotificationBadge = ({ type, domain }: NotificationBadgeProps) => {
  switch (type) {
    case 'monitoring_added':
      return (
        <Badge variant="outline" className="bg-emerald-50 text-emerald-600 hover:bg-emerald-100 border-emerald-200 text-[10px] px-1.5 py-0">
          {domain}
        </Badge>
      );
    case 'playground_scraped':
      return (
        <Badge className="bg-amber-50 text-amber-600 hover:bg-amber-100 border-amber-200 text-[10px] px-1.5 py-0">
          Playground
        </Badge>
      );
    case 'keyword_added':
      return (
        <Badge className="bg-blue-50 text-blue-600 hover:bg-blue-100 border-blue-200 text-[10px] px-1.5 py-0">
          Keywords
        </Badge>
      );
    default:
      return null;
  }
};