import { KeywordManagement } from "@/components/social/KeywordManagement";
import { SocialTabNav, SocialTabContent } from "@/components/social/SocialTabs";
import { useKeywords } from "@/hooks/useKeywords";
import { Tabs } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import Sidebar from "@/components/Sidebar";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { useState, useEffect } from "react";
import { SocialKeywordFilter } from "@/components/social/SocialKeywordFilter";
import { SentimentFilter } from "@/components/SentimentFilter";
import { Plus } from "lucide-react";

const Social = () => {
  const { user } = useAuth();
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [selectedSentiments, setSelectedSentiments] = useState<string[]>([]);
  const [isKeywordModalOpen, setIsKeywordModalOpen] = useState(false);
  const { keywords, fetchKeywords } = useKeywords();
  
  useEffect(() => {
    fetchKeywords();
  }, [fetchKeywords]);

  // Fetch Reddit mentions
  const { data: mentions = [] } = useQuery({
    queryKey: ['keyword_matches', user?.id],
    queryFn: async () => {
      if (!user?.id) return [];
      
      const { data, error } = await supabase
        .from('keyword_matches')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      return data || [];
    },
    enabled: !!user?.id
  });

  // Fetch tweet matches
  const { data: tweetMentions = [] } = useQuery({
    queryKey: ['tweet_matches', user?.id],
    queryFn: async () => {
      if (!user?.id) return [];
      
      const { data, error } = await supabase
        .from('tweet_matches')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });
      
      if (error) {
        console.error('Error fetching tweet matches:', error);
        throw error;
      }
      
      return data || [];
    },
    enabled: !!user?.id
  });

  const handleKeywordToggle = (keyword: string) => {
    setSelectedKeywords(prev => {
      const next = prev.includes(keyword) 
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword];
      return next;
    });
  };

  const handleSentimentToggle = (sentiment: string) => {
    setSelectedSentiments(prev => {
      const next = prev.includes(sentiment)
        ? prev.filter(s => s !== sentiment)
        : [...prev, sentiment];
      return next;
    });
  };

  // Filter function for both types of mentions
  const filterMatches = (matches: any[]) => {
    return matches.filter(match => {
      const matchesKeyword = selectedKeywords.length === 0 || selectedKeywords.includes(match.keyword);
      const matchesSentiment = selectedSentiments.length === 0 || (
        match.sentiment && selectedSentiments.includes(match.sentiment.toLowerCase())
      );
      return matchesKeyword && matchesSentiment;
    });
  };

  // Filter both types of mentions
  const filteredMentions = filterMatches(mentions);
  const filteredTweetMentions = filterMatches(tweetMentions);

  const counts = {
    all: filteredMentions.length + filteredTweetMentions.length,
    reddit: filteredMentions.length,
    x: filteredTweetMentions.length,
    youtube: 0
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white">
      <Sidebar />
      <div className="flex-1 flex flex-col min-w-0">
        <Tabs defaultValue="all" className="flex flex-col h-full">
          <div className="sticky top-0 z-10 backdrop-blur-sm bg-white/80 p-8 pb-0 border-b">
            <div className="flex items-center justify-between mb-6">
              <div className="flex-1 min-w-0">
                <h1 className="text-lg font-medium text-gray-900">Social Monitoring</h1>
                <p className="text-xs text-gray-500 mt-1">Monitor social media mentions</p>
              </div>
              <Button 
                variant="outline" 
                size="sm"
                onClick={() => setIsKeywordModalOpen(true)}
                className="gap-2 text-xs"
              >
                <Plus className="h-4 w-4" />
                Manage Keywords
              </Button>
            </div>

            <div className="mb-4 flex justify-between items-start">
              <SocialKeywordFilter
                keywords={keywords}
                selectedKeywords={selectedKeywords}
                onKeywordToggle={handleKeywordToggle}
              />
              <SentimentFilter
                selectedSentiments={selectedSentiments}
                onSentimentToggle={handleSentimentToggle}
              />
            </div>

            <SocialTabNav counts={counts} />
          </div>

          <div className="flex-1 overflow-y-auto">
            <div className="p-8">
              <div className="max-w-7xl mx-auto">
                <SocialTabContent 
                  mentions={mentions}
                  selectedKeywords={selectedKeywords}
                  selectedSentiments={selectedSentiments}
                />
              </div>
            </div>
          </div>
        </Tabs>
      </div>

      <KeywordManagement 
        isOpen={isKeywordModalOpen}
        onClose={() => setIsKeywordModalOpen(false)}
      />
    </div>
  );
};

export default Social;