import { cn } from "@/lib/utils";

interface Release {
  date: string;
  importance: "High" | "Medium" | "Low";
  feature: string;
}

const releases: Release[] = [
  {
    date: "28th Jan 2025",
    importance: "High",
    feature: "Added Playground for full-scale social monitoring"
  },
  {
    date: "1st Jan 2025",
    importance: "High",
    feature: "Added X/Twitter Keyword Monitoring"
  },
  {
    date: "30th Dec 2024",
    importance: "Medium",
    feature: "Added Inbox to manage all alerts"
  },
  {
    date: "28th Dec 2024",
    importance: "High",
    feature: "Released Unlimited Annual plan ($67)"
  },
  {
    date: "26th Dec 2024",
    importance: "Medium",
    feature: "Added AI Sentiment Analysis"
  },
  {
    date: "25th Dec 2024",
    importance: "Low",
    feature: "Added Dark Mode support"
  }
];

export const ReleasesTable = () => {
  return (
    <div className="mt-8 px-6 h-[400px] flex flex-col">
      <h3 className="text-sm font-medium text-gray-900 mb-4">Releases & Updates</h3>
      <div className="flex-1 min-h-0">
        <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
          <table className="w-full">
            <thead className="sticky top-0 bg-white">
              <tr className="text-left [&>th]:py-2 [&>th]:pr-4">
                <th className="text-xs font-medium text-gray-500">Date</th>
                <th className="text-xs font-medium text-gray-500">Importance</th>
                <th className="text-xs font-medium text-gray-500">Feature</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {releases.map((release, index) => (
                <tr 
                  key={index} 
                  className="border-t border-gray-100/50"
                >
                  <td className="py-2 pr-4 text-xs text-gray-600">{release.date}</td>
                  <td className="py-2 pr-4">
                    <span className={cn(
                      "text-xs px-2 py-0.5 rounded-full",
                      release.importance === "High" && "bg-blue-50 text-blue-700",
                      release.importance === "Medium" && "bg-yellow-50 text-yellow-700",
                      release.importance === "Low" && "bg-gray-50 text-gray-600"
                    )}>
                      {release.importance}
                    </span>
                  </td>
                  <td className="py-2 pr-4 text-xs text-gray-600">{release.feature}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};