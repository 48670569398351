import { LogOut } from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { SidebarNav } from "./sidebar/SidebarNav";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";

const Sidebar = () => {
  const { user, signOut } = useAuth();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: keywords = [] } = useQuery({
    queryKey: ['keywords', user?.id],
    queryFn: async () => {
      if (!user?.id) return [];
      const { data, error } = await supabase
        .from('subreddit_keywords')
        .select('*')
        .eq('user_id', user?.id)
        .limit(3);
      
      if (error) throw error;
      return data || [];
    },
    enabled: !!user?.id
  });

  const handleSignOut = async () => {
    try {
      await signOut();
      toast({
        title: "Signed out successfully",
      });
      navigate('/auth');
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error signing out",
        description: error.message,
      });
    }
  };

  return (
    <div className="w-64 border-r bg-white h-screen flex flex-col flex-shrink-0">
      <div className="p-5">
        <div className="flex items-center gap-2">
          <img 
            src="/lovable-uploads/4dfd401e-6daf-4d78-8f5e-7f7296483c4f.png" 
            alt="Seer Logo" 
            className="w-7 h-7 select-none" 
            draggable="false"
            loading="eager"
          />
          <div>
            <h2 className="font-medium text-sm">Seer</h2>
            <p className="text-[11px] text-gray-500">Unlimited Monitoring</p>
          </div>
        </div>
      </div>

      <SidebarNav />

      <div className="mt-auto">
        <div className="px-3 py-2 border-t">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="w-7 h-7 rounded-full bg-gray-100 flex items-center justify-center">
                <span className="text-xs font-medium text-gray-600">
                  {user?.email?.charAt(0).toUpperCase()}
                </span>
              </div>
              <span className="text-xs text-gray-600 truncate max-w-[120px]">{user?.email}</span>
            </div>
            <Button 
              variant="ghost" 
              size="sm" 
              className="h-7 w-7 p-0"
              onClick={handleSignOut}
            >
              <LogOut className="h-3.5 w-3.5" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;