import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/contexts/AuthContext";
import Sidebar from "@/components/Sidebar";
import { useEffect, useState } from "react";
import { supabase } from "@/lib/supabase";
import { ContextModal } from "@/components/context/ContextModal";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { HelpCircle } from "lucide-react";

const Settings = () => {
  const { user } = useAuth();
  const [domain, setDomain] = useState("");
  const [isContextModalOpen, setIsContextModalOpen] = useState(false);

  useEffect(() => {
    if (user) {
      fetchUserContext();
    }
  }, [user]);

  const fetchUserContext = async () => {
    try {
      const { data } = await supabase
        .from('user_context')
        .select('domain')
        .eq('user_id', user?.id)
        .single();
      
      if (data) {
        setDomain(data.domain || '');
      }
    } catch (error) {
      console.error('Error fetching context:', error);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-[#FAFAFA]">
      <Sidebar />
      <div className="flex-1 overflow-auto">
        <div className="container mx-auto p-8 max-w-5xl">
          <h1 className="text-xl font-medium mb-6 text-gray-900">Settings</h1>

          <Tabs defaultValue="account" className="space-y-6">
            <TabsList className="bg-white border">
              <TabsTrigger value="account" className="text-xs">Account</TabsTrigger>
              <TabsTrigger value="notifications" className="text-xs">Notifications</TabsTrigger>
              <TabsTrigger value="billing" className="text-xs">Billing</TabsTrigger>
              <TabsTrigger value="api" className="text-xs" disabled>API (Coming soon)</TabsTrigger>
            </TabsList>

            <TabsContent value="account">
              <Card>
                <CardHeader className="space-y-1">
                  <CardTitle className="text-base">Account Settings</CardTitle>
                  <CardDescription className="text-xs">
                    Manage your account preferences and settings
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="space-y-2">
                    <label className="text-xs font-medium text-gray-700">Email</label>
                    <Input 
                      value={user?.email || ''} 
                      readOnly 
                      className="max-w-md h-9 text-xs bg-gray-50 text-gray-500" 
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-xs font-medium text-gray-700">Domain for context</label>
                    <Input 
                      value={domain} 
                      readOnly 
                      className="max-w-md h-9 text-xs bg-gray-50 text-gray-500" 
                    />
                  </div>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="notifications">
              <Card>
                <CardHeader className="space-y-1">
                  <CardTitle className="text-base">Notification Preferences</CardTitle>
                  <CardDescription className="text-xs">
                    Choose how you want to be notified about important updates
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="flex items-center justify-between py-2">
                    <div className="flex items-center gap-2">
                      <p className="text-xs font-medium text-gray-700">Email for notifications</p>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p className="text-xs">Alert waves come twice a day: at 10am & 7pm Eastern Time (ET).</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    <p className="text-[11px] text-gray-500 mt-0.5">{user?.email}</p>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div className="flex items-center gap-2">
                      <p className="text-xs font-medium text-gray-700">Data Collection</p>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p className="text-xs">1M+ comments are scraped from various communities twice-daily, just prior to the alert waves so the Agent finds leads as recent as possible.</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    <p className="text-[11px] text-gray-500 mt-0.5">Active</p>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div>
                      <p className="text-xs font-medium text-gray-700">AI Sentiment Analysis</p>
                      <p className="text-[11px] text-gray-500 mt-0.5">Enabled</p>
                    </div>
                  </div>
                  <div className="pt-4">
                    <Button 
                      variant="outline" 
                      size="sm" 
                      onClick={() => setIsContextModalOpen(true)}
                    >
                      Update Context
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="billing">
              <Card>
                <CardHeader className="space-y-1">
                  <CardTitle className="text-base">Billing Information</CardTitle>
                  <CardDescription className="text-xs">
                    Manage your subscription and billing details
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="bg-gradient-to-br from-emerald-50 to-white p-4 rounded-lg border border-emerald-100">
                    <h3 className="text-xs font-medium text-gray-700 mb-1">Current Plan: Unlimited Monitoring</h3>
                    <div className="space-x-2">
                      <Button 
                        size="sm" 
                        className="h-8 text-xs"
                        onClick={() => window.open('https://billing.stripe.com/p/login/cN25n9fq96rD2VG4gg', '_blank')}
                      >
                        Edit Billing
                      </Button>
                      <Button 
                        size="sm" 
                        variant="outline" 
                        className="h-8 text-xs"
                        onClick={() => window.open('https://billing.stripe.com/p/login/cN25n9fq96rD2VG4gg', '_blank')}
                      >
                        View Invoice History
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </div>

      <ContextModal 
        isOpen={isContextModalOpen}
        onClose={() => setIsContextModalOpen(false)}
      />
    </div>
  );
};

export default Settings;