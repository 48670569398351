import { Button } from "@/components/ui/button";
import { Flame } from "lucide-react";

interface PlaygroundAgentButtonProps {
  onClick: () => void;
  hasResults: boolean;
  isAgentLaunched: boolean;
}

export const PlaygroundAgentButton = ({ onClick, hasResults, isAgentLaunched }: PlaygroundAgentButtonProps) => {
  return (
    <Button
      onClick={onClick}
      className="bg-white text-gray-700 border border-gray-200 hover:bg-gray-50 gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
      disabled={isAgentLaunched || hasResults}
    >
      {isAgentLaunched || hasResults ? (
        <>
          <Flame className="h-4 w-4 text-emerald-500" />
          Agent Active
        </>
      ) : (
        <>
          <Flame className="h-4 w-4" />
          Start Agent
        </>
      )}
    </Button>
  );
};