import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { useSearchStore } from "@/stores/searchStore";
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { InitialEmptyState } from "./states/InitialEmptyState";
import { NoMatchesFound } from "./states/NoMatchesFound";
import { useEffect, useRef } from "react";

interface EmptyStateProps {
  hasExistingMatches: boolean;
}

export const EmptyState = ({ hasExistingMatches }: EmptyStateProps) => {
  const { toast } = useToast();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const pollingIntervalRef = useRef<number | null>(null);
  const startTimeRef = useRef<string | null>(null);
  const { 
    isSearching, 
    currentStep, 
    setSearching, 
    setCurrentStep, 
    resetSearch 
  } = useSearchStore();

  // Cleanup function for polling
  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        console.log('Cleaning up polling interval on unmount');
        window.clearInterval(pollingIntervalRef.current);
      }
    };
  }, []);

  const checkForNewMatches = async (startTime: string) => {
    console.log('Checking for new matches since:', startTime);
    
    try {
      const { data: matches, error } = await supabase
        .from('keyword_matches')
        .select('*')
        .eq('user_id', user?.id)
        .gt('created_at', startTime);

      if (error) throw error;
      
      console.log(`Found ${matches?.length || 0} new matches`);
      
      if (matches && matches.length > 0) {
        console.log('New matches found, completing search');
        handleSearchCompletion();
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Error checking for matches:', error);
      return false;
    }
  };

  const startPolling = (startTime: string) => {
    console.log('Starting polling for new matches');
    startTimeRef.current = startTime;
    let attempts = 0;
    const maxAttempts = 10; // Reduced to 30 seconds (10 attempts * 3 seconds)
    
    // Clear any existing interval
    if (pollingIntervalRef.current) {
      window.clearInterval(pollingIntervalRef.current);
    }
    
    pollingIntervalRef.current = window.setInterval(async () => {
      console.log(`Polling attempt ${attempts + 1} of ${maxAttempts}`);
      
      const found = await checkForNewMatches(startTime);
      attempts++;
      
      if (found || attempts >= maxAttempts) {
        console.log('Stopping polling:', found ? 'matches found' : 'max attempts reached');
        if (pollingIntervalRef.current) {
          window.clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
        }
        
        if (!found && attempts >= maxAttempts) {
          console.log('Search timed out');
          // We don't show the timeout toast anymore since we show the refresh message
          resetSearch();
        }
      }
    }, 3000); // Check every 3 seconds
  };

  const handleSearchCompletion = async () => {
    console.log('=== Starting search completion process ===');
    
    setCurrentStep(3);
    
    // Invalidate queries
    await queryClient.invalidateQueries({ queryKey: ['keyword_matches'] });
    await queryClient.invalidateQueries({ queryKey: ['tweet_matches'] });
    
    // Small delay to ensure UI updates smoothly
    setTimeout(async () => {
      toast({
        title: "Search completed",
        description: "New mentions have been found and added"
      });
      
      // Force a refresh of the data
      try {
        await Promise.all([
          queryClient.refetchQueries({ queryKey: ['keyword_matches'] }),
          queryClient.refetchQueries({ queryKey: ['tweet_matches'] })
        ]);
      } catch (error) {
        console.error('Error refreshing queries:', error);
      }
      
      resetSearch();
    }, 1000);
  };

  const handleStartSearch = async () => {
    if (!user) {
      toast({
        title: "Error",
        description: "You must be logged in to start a search",
        variant: "destructive"
      });
      return;
    }

    console.log("=== Starting search process ===");
    
    setSearching(true);
    setCurrentStep(0);
    
    try {
      const { data: keywords, error: keywordError } = await supabase
        .from('subreddit_keywords')
        .select('keyword')
        .eq('user_id', user.id)
        .eq('enabled', true);

      if (keywordError) throw keywordError;

      if (!keywords?.length) {
        toast({
          title: "No keywords found",
          description: "Please add some keywords to monitor first",
          variant: "destructive"
        });
        resetSearch();
        return;
      }

      setCurrentStep(1);

      // Get current timestamp to check for new matches
      const startTime = new Date().toISOString();

      // Subscribe to function logs and broadcast channel
      const channel = supabase.channel('function-logs')
        .on(
          'postgres_changes',
          { 
            event: '*', 
            schema: 'public',
            table: 'keyword_matches'
          },
          (payload) => {
            console.log('Database change detected:', payload);
          }
        )
        .on(
          'broadcast',
          { event: 'function-progress' },
          (payload) => {
            console.log('Received broadcast message:', payload);
            
            if (payload.payload?.event_message) {
              if (payload.payload.event_message.includes('Processing')) {
                setCurrentStep(2);
              }
            }
          }
        )
        .subscribe();

      const { error } = await supabase.functions.invoke('trigger-search', {
        body: { userId: user.id }
      });

      if (error) throw error;
      
      // Start polling for new matches
      startPolling(startTime);
      
      return () => {
        supabase.removeChannel(channel);
      };

    } catch (error: any) {
      console.error("Error in search process:", error);
      toast({
        title: "Error",
        description: "Failed to start search. Please try again later.",
        variant: "destructive"
      });
      resetSearch();
    }
  };

  // If there are existing matches but none match the current filters,
  // show the "No matches found" message instead of the initial empty state
  if (hasExistingMatches) {
    return <NoMatchesFound />;
  }

  // Otherwise show the initial empty state with the start search button
  return (
    <InitialEmptyState
      isSearching={isSearching}
      currentStep={currentStep}
      onStartSearch={handleStartSearch}
    />
  );
};
