import { useState, useEffect, useRef } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import { Plus } from "lucide-react";
import {
  Dialog,
  DialogContent,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ContextField } from './ContextField';
import { ContextResetButton } from './ContextResetButton';
import { ContextModalHeader } from './ContextModalHeader';

export interface ContextModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ContextModal = ({ isOpen, onClose }: ContextModalProps) => {
  const [domain, setDomain] = useState("");
  const [offerContext, setOfferContext] = useState("");
  const [customerContext, setCustomerContext] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [completedFields, setCompletedFields] = useState({
    domain: false,
    offer: false,
    customer: false
  });
  
  const { user } = useAuth();
  const { toast } = useToast();
  
  // Create refs for each field
  const domainRef = useRef<HTMLInputElement>(null);
  const offerRef = useRef<HTMLTextAreaElement>(null);
  const customerRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isOpen && user) {
      fetchUserContext();
    }
  }, [isOpen, user]);

  const fetchUserContext = async () => {
    try {
      const { data, error } = await supabase
        .from('user_context')
        .select('*')
        .eq('user_id', user?.id)
        .single();

      if (error) throw error;

      if (data) {
        setDomain(data.domain || '');
        setOfferContext(data.offer_context || '');
        setCustomerContext(data.customer_context || '');
        setCompletedFields({
          domain: !!data.domain,
          offer: !!data.offer_context,
          customer: !!data.customer_context
        });
      }
    } catch (error: any) {
      console.error('Error fetching context:', error);
    }
  };

  const handleSave = async () => {
    if (!user) return;
    
    // Validate minimum lengths before saving
    if (offerContext.length < 20) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Business/offer description must be at least 20 characters"
      });
      return;
    }

    if (customerContext.length < 20) {
      toast({
        variant: "destructive",
        title: "Validation Error",
        description: "Customer description must be at least 20 characters"
      });
      return;
    }
    
    setIsLoading(true);
    try {
      const { error } = await supabase
        .from('user_context')
        .upsert({
          user_id: user.id,
          domain,
          offer_context: offerContext,
          customer_context: customerContext,
        }, {
          onConflict: 'user_id'
        });

      if (error) throw error;

      setCompletedFields({
        domain: !!domain,
        offer: !!offerContext,
        customer: !!customerContext
      });

      toast({
        title: "Context saved",
        description: "Your business context has been updated successfully"
      });
      
      onClose();
    } catch (error: any) {
      console.error('Error saving context:', error);
      toast({
        variant: "destructive",
        title: "Error saving context",
        description: error.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = async () => {
    if (!user) return;
    
    try {
      const { error } = await supabase
        .from('user_context')
        .delete()
        .eq('user_id', user.id);

      if (error) throw error;

      setDomain('');
      setOfferContext('');
      setCustomerContext('');
      setCompletedFields({
        domain: false,
        offer: false,
        customer: false
      });
      
      toast({
        title: "Context reset",
        description: "Your business context has been cleared"
      });
      
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error resetting context",
        description: error.message
      });
    }
  };

  const handleDomainComplete = () => {
    if (domain) {
      setCompletedFields(prev => ({ ...prev, domain: true }));
    }
  };

  const handleOfferComplete = () => {
    if (offerContext) {
      setCompletedFields(prev => ({ ...prev, offer: true }));
    }
  };

  const handleCustomerComplete = () => {
    if (customerContext) {
      setCompletedFields(prev => ({ ...prev, customer: true }));
      handleSave();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <ContextModalHeader />
        
        <div className="space-y-6 mt-6">
          <ContextField
            ref={domainRef}
            label="Your domain"
            value={domain}
            onChange={setDomain}
            placeholder="e.g., example.com"
            onEnter={handleDomainComplete}
            isComplete={completedFields.domain}
            nextFieldRef={offerRef}
          />

          <ContextField
            ref={offerRef}
            label="Your business/offer in 1 sentence"
            value={offerContext}
            onChange={setOfferContext}
            placeholder="e.g., We provide AI-powered analytics software for e-commerce businesses"
            multiline
            onEnter={handleOfferComplete}
            isComplete={completedFields.offer}
            nextFieldRef={customerRef}
            minLength={20}
            maxLength={200}
          />

          <ContextField
            ref={customerRef}
            label="Describe your ideal customer in 1 sentence"
            value={customerContext}
            onChange={setCustomerContext}
            placeholder="e.g., Mid-sized online retailers looking to optimize their operations and increase revenue"
            multiline
            onEnter={handleCustomerComplete}
            isComplete={completedFields.customer}
            minLength={20}
            maxLength={200}
          />

          <div className="flex gap-3">
            <Button 
              onClick={handleSave} 
              className="flex-1 gap-2"
              disabled={isLoading}
            >
              <Plus className="h-4 w-4" />
              Save Context
            </Button>
            
            <ContextResetButton onReset={handleReset} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
