import { format } from "date-fns";
import { NotificationBadge } from "./NotificationBadge";

interface Notification {
  id: string;
  created_at: string;
  type: 'monitoring_added' | 'playground_scraped' | 'keyword_added';
  domain: string;
}

interface NotificationItemProps {
  notification: Notification;
}

export const NotificationItem = ({ notification }: NotificationItemProps) => {
  const getNotificationTitle = (notification: Notification) => {
    switch (notification.type) {
      case 'monitoring_added':
        return `Added: ${notification.domain}`;
      case 'playground_scraped':
        return `Update: ${notification.domain}`;
      case 'keyword_added':
        return `Added: ${notification.domain}`; // Removed r/ prefix
      default:
        return 'Notification';
    }
  };

  const getNotificationText = (notification: Notification) => {
    switch (notification.type) {
      case 'monitoring_added':
        return `${notification.domain} has been added to Monitoring Pool`;
      case 'playground_scraped':
        return `Scraped multiple ${notification.domain} routes`;
      case 'keyword_added':
        return `${notification.domain} has been added as a keyword for monitoring`;
      default:
        return "New update";
    }
  };

  return (
    <div className="p-5 hover:bg-gray-50 cursor-pointer transition-colors">
      <div className="flex flex-col gap-2">
        <div className="flex items-start justify-between gap-3">
          <div className="flex-1 min-w-0">
            <h3 className="text-xs font-medium truncate leading-tight">
              {getNotificationTitle(notification)}
            </h3>
          </div>
          <time className="text-[10px] text-gray-400 shrink-0 mt-0.5 whitespace-nowrap">
            {format(new Date(notification.created_at), 'MMM d, h:mm a')}
          </time>
        </div>
        
        <p className="text-[11px] text-gray-500 line-clamp-2 leading-relaxed">
          {getNotificationText(notification)}
        </p>
        
        <div className="flex items-center gap-2 mt-1">
          <NotificationBadge type={notification.type} domain={notification.domain} />
        </div>
      </div>
    </div>
  );
};