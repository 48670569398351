import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";
import { create } from 'zustand';

interface Keyword {
  id: string;
  keyword: string;
  user_id: string;
  created_at: string;
}

interface KeywordStore {
  keywords: Keyword[];
  setKeywords: (keywords: Keyword[]) => void;
}

const useKeywordStore = create<KeywordStore>((set) => ({
  keywords: [],
  setKeywords: (keywords) => set({ keywords }),
}));

export const useKeywords = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const { keywords, setKeywords } = useKeywordStore();

  const fetchKeywords = async () => {
    if (!user?.id) return;
    
    const { data, error } = await supabase
      .from('subreddit_keywords')
      .select('*')
      .eq('user_id', user.id)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching keywords:', error);
      return;
    }

    setKeywords(data || []);
  };

  const addKeyword = async (keyword: string) => {
    if (!user?.id) return;
    
    const { data, error } = await supabase
      .from('subreddit_keywords')
      .insert([{ 
        keyword: keyword.trim(), 
        user_id: user.id,
        created_at: new Date().toISOString() 
      }])
      .select()
      .single();

    if (error) throw error;
    
    // Update local state immediately
    setKeywords([data, ...keywords]);
    return data;
  };

  const deleteKeyword = async (id: string) => {
    if (!user?.id) return;

    await supabase
      .from('keyword_matches')
      .delete()
      .eq('keyword_id', id);

    const { error } = await supabase
      .from('subreddit_keywords')
      .delete()
      .eq('id', id);

    if (error) throw error;
    await fetchKeywords();
  };

  useEffect(() => {
    fetchKeywords();
  }, [user?.id]);

  return {
    keywords,
    addKeyword,
    deleteKeyword,
    fetchKeywords,
    isLoading: false
  };
}; 