import { Flame, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";

interface InitialEmptyStateProps {
  isSearching: boolean;
  currentStep: number;
  onStartSearch: () => void;
}

export const InitialEmptyState = ({ 
  isSearching, 
  currentStep, 
  onStartSearch 
}: InitialEmptyStateProps) => {
  const [showRefresh, setShowRefresh] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSearching) {
      timeout = setTimeout(() => {
        setShowRefresh(true);
      }, 30000);
    } else {
      setShowRefresh(false);
    }
    return () => clearTimeout(timeout);
  }, [isSearching]);

  return (
    <div className="text-center py-12">
      <div className="h-[120px] flex flex-col items-center justify-center relative"> {/* Container for all content */}
        <AnimatePresence mode="wait">
          {!isSearching ? (
            <motion.div
              key="initial"
              initial={{ opacity: 1 }}
              exit={{ 
                opacity: 0,
                transition: { duration: 0.4 }
              }}
              className="absolute inset-0 flex flex-col items-center justify-center"
            >
              <h3 className="text-lg font-medium text-gray-900">No mentions found</h3>
              <p className="mt-2 text-sm text-gray-500">
                Get started by searching for new mentions across social platforms.
              </p>
            </motion.div>
          ) : showRefresh ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="absolute inset-0 flex flex-col items-center justify-center gap-4"
            >
              <RefreshCw className="h-6 w-6 text-gray-400" />
              <p className="text-sm text-gray-500">Please refresh the page</p>
            </motion.div>
          ) : (
            <motion.div 
              key="searching"
              initial={{ opacity: 0 }}
              animate={{ 
                opacity: 1,
                transition: {
                  duration: 0.4,
                  delay: 0.4
                }
              }}
              className="absolute inset-0 flex flex-col items-center justify-center gap-6"
            >
              <div className="w-48 h-1 bg-gray-100 rounded-full overflow-hidden">
                <div className="h-full bg-gray-300 animate-[loading_1s_ease-in-out_infinite]" />
              </div>
              <p className="text-sm text-gray-500">
                Come back in a few minutes. While you wait, check out the{' '}
                <Link to="/feed" className="text-blue-600 hover:text-blue-800 underline">
                  Feed
                </Link>
                .
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence mode="wait">
        {!isSearching && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mt-2" // Reduced from mt-6
          >
            <Button 
              onClick={onStartSearch}
              variant="outline"
              className="bg-white border-gray-200 text-gray-700 hover:bg-gray-50 hover:text-gray-900 gap-2"
            >
              <Flame className="h-4 w-4" />
              Start Search
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};