import { useNavigate } from 'react-router-dom';
import { ArrowRight, ArrowUpRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from "@/contexts/AuthContext";
import { useEffect } from "react";
import FloatingIcons from '@/components/ui/floating-icons';
import FloatingNotifications from '@/components/ui/floating-notifications';
import { Header } from '@/components/landing/Header';

const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user && sessionStorage.getItem('from_auth')) {
      sessionStorage.removeItem('from_auth');
      navigate('/dashboard');
    }
  }, [user, navigate]);

  return (
    <div className="min-h-screen flex flex-col relative">
      {/* Grid Background */}
      <div 
        className="absolute inset-0 w-full"
        style={{
          backgroundImage: `
            linear-gradient(rgb(100 100 100 / 0.03) 1px, transparent 1px),
            linear-gradient(to right, rgb(100 100 100 / 0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          zIndex: -1
        }}
      />
      
      <Header />
      
      {/* Main content */}
      <main className="flex-1 pt-[72px]">
        {/* Hero Section */}
        <section className="relative min-h-screen">
          {/* Floating Icons */}
          <FloatingIcons />

          {/* Content Container */}
          <div className="container mx-auto px-4 pt-8 pb-24 md:pt-12 md:pb-32 relative z-10">
            <div className="mx-auto max-w-[64rem] space-y-8">
              {/* New Badge */}
              <div className="flex justify-center">
                <span className="inline-flex items-center rounded-full border border-gray-200 dark:border-gray-800 px-3 py-1 text-sm text-muted-foreground">
                  <span className="mr-2 h-1.5 w-1.5 rounded-full bg-primary animate-flicker"></span>
                  <b>New</b>: Find customers on YouTube & X
                </span>
              </div>
              
              {/* Hero Title */}
              <h1 className="text-center text-4xl font-medium tracking-tight sm:text-5xl md:text-6xl lg:text-7xl">
                Find customers among
                <br />
                <span className="bg-gradient-to-r from-primary/60 to-primary bg-clip-text text-transparent">
                  billions of internet posts
                </span>
              </h1>
              
              {/* Hero Description */}
              <p className="mx-auto max-w-2xl text-center text-lg text-muted-foreground font-light leading-relaxed">
                An AI Agent built to scrape billions of social posts, so you can find your ideal customers where they hang out. Early bird access for <b>$19</b>/month.
              </p>
              
              {/* CTA Buttons */}
              <div className="flex flex-col items-center justify-center gap-4 sm:flex-row">
                <Button
                  onClick={() => navigate('/auth')}
                  className="inline-flex h-11 items-center justify-center rounded-full bg-primary px-8 text-sm font-medium text-primary-foreground transition-colors hover:bg-primary/90 group"
                >
                  Early Bird Access ($19/month)
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Button>
                <Button
                  variant="outline"
                  onClick={() => navigate('/docs')}
                  className="inline-flex h-11 items-center justify-center rounded-full border border-gray-200 dark:border-gray-800 px-8 text-sm font-medium transition-colors hover:bg-gray-100 dark:hover:bg-gray-800"
                >
                  Documentation
                </Button>
              </div>

              {/* Trusted By Section */}
              <div className="mt-24 space-y-4">
                <p className="text-center text-sm text-muted-foreground font-light">
                  Trusted by founders & teams everywhere
                </p>
                <div className="relative mx-auto max-w-2xl">
                  {/* Fade gradients */}
                  <div className="absolute left-0 top-0 bottom-0 w-24 bg-gradient-to-r from-white to-transparent z-10"></div>
                  <div className="absolute right-0 top-0 bottom-0 w-24 bg-gradient-to-l from-white to-transparent z-10"></div>
                  
                  {/* Scrolling content */}
                  <div className="overflow-hidden relative">
                    <div className="flex whitespace-nowrap animate-marquee">
                      {[...Array(2)].flatMap((_, i) => (
                        ['Response AI', 'Saral Influencers', 'GreatLab', 'Quolum', 'Linq', 'Bionic Talent'].map((company) => (
                          <span 
                            key={`${company}-${i}`} 
                            className="inline-block mx-4 text-xl font-semibold tracking-tight text-muted-foreground/50"
                          >
                            {company}
                          </span>
                        ))
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Video Section */}
              <div className="relative mt-8">
                <div className="relative mx-auto max-w-4xl rounded-xl overflow-hidden">
                  <div className="aspect-[1.91/1]">
                    <video 
                      autoPlay 
                      loop 
                      muted 
                      playsInline
                      className="w-full h-full object-cover rounded-xl border border-gray-200"
                    >
                      <source src="/seerfinaltutorial.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>

              {/* Notifications Section */}
              <section className="container mx-auto px-4 pb-0">
                <div className="relative h-[150px]">
                  <FloatingNotifications />
                </div>
              </section>

              {/* Stats Section */}
              <section className="container mx-auto px-4 pt-2">
                <div className="mx-auto max-w-[64rem]">
                  <div className="rounded-2xl bg-white p-12 shadow-[0_1px_3px_0_rgb(0,0,0,0.1)]">
                    {/* Header */}
                    <div className="space-y-4">
                      <span className="text-base text-muted-foreground">
                        100+ Data Sources
                      </span>
                      <h2 className="text-4xl font-medium tracking-tight">
                        Unlimited alerts, one price.
                      </h2>
                      <p className="text-xl text-muted-foreground max-w-3xl">
                        Instead of charging you into oblivion, you unlock unlimited keywords & monitoring for just $19. That way, you can find more customers for less.
                      </p>

                      {/* Stats Grid */}
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 pt-6">
                        {/* Stat 1 */}
                        <div className="space-y-2">
                          <h3 className="text-4xl font-medium text-[#f97316]">1B+</h3>
                          <h4 className="font-medium">Social Posts</h4>
                          <p className="text-muted-foreground text-sm leading-relaxed">
                            Reddit, YouTube, X, G2, Quora..and a few dozen more. Monitor every single post for keywords that matter to you.
                          </p>
                        </div>

                        {/* Stat 2 */}
                        <div className="space-y-2">
                          <h3 className="text-4xl font-medium text-[#f97316]">Infinite</h3>
                          <h4 className="font-medium">Updates & Alerts</h4>
                          <p className="text-muted-foreground text-sm leading-relaxed">
                            Our AI agent works around the clock & never misses a post, so you never miss a potential customer. No limits.
                          </p>
                        </div>

                        {/* Stat 3 */}
                        <div className="space-y-2">
                          <h3 className="text-4xl font-medium text-[#f97316]">#1</h3>
                          <h4 className="font-medium">for relevancy & accuracy</h4>
                          <p className="text-muted-foreground text-sm leading-relaxed">
                            Seer uses AI-powered post analysis so you're only shown potential customers having relevant conversations, not nonsense.
                          </p>
                        </div>

                        {/* Stat 4 */}
                        <div className="space-y-2">
                          <h3 className="text-4xl font-medium text-[#f97316]">Hot Leads</h3>
                          <h4 className="font-medium">on auto-pilot</h4>
                          <p className="text-muted-foreground text-sm leading-relaxed">
                            Seer scrapes 1M+ posts from 100+ sources every <b>10 minutes</b>. Then, Seer finds you customers automatically.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Lists Section */}
              <section className="container mx-auto px-4 py-16">
                <div className="mx-auto max-w-[64rem] space-y-12">
                  {/* Header */}
                  <div className="text-center space-y-4">
                    <h2 className="text-4xl font-medium">Potential customers across hundreds of communities</h2>
                    <p className="text-xl text-muted-foreground">
                      Don't miss a single opportunity to sell to your ideal leads.
                    </p>
                    
                    {/* Action Buttons */}
                    <div className="flex justify-center gap-3 pt-4">
                      <Button 
                        variant="outline" 
                        className="rounded-full px-6"
                        onClick={() => navigate('/docs')}
                      >
                        Documentation
                      </Button>
                      <Button 
                        variant="outline" 
                        className="rounded-full px-6"
                        onClick={() => window.open('https://x.com/ollyrosewell', '_blank')}
                      >
                        Ask us anything
                      </Button>
                    </div>
                  </div>

                  {/* Filter Cards Grid */}
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Card 1 - Reddit */}
                    <div className="rounded-2xl border border-gray-200 bg-white p-8">
                      <div className="space-y-4">
                        <div className="h-10 w-10 rounded-full bg-orange-100 flex items-center justify-center">
                          <svg viewBox="0 0 24 24" className="h-5 w-5 text-orange-500">
                            <path fill="currentColor" d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z"/>
                          </svg>
                        </div>
                        <h3 className="text-xl font-medium">Reddit</h3>
                        <p className="text-muted-foreground text-sm leading-relaxed">
                          Monitor subreddits and find customers discussing problems your product solves.
                        </p>
                        <div className="flex items-center text-gray-600 hover:text-gray-900 cursor-pointer" onClick={() => navigate('/auth')}>
                          <span className="text-sm">Monitor Reddit</span>
                          <ArrowUpRight className="ml-2 h-4 w-4" />
                        </div>
                      </div>
                    </div>

                    {/* Card 2 - X (Twitter) */}
                    <div className="rounded-2xl border border-gray-200 bg-white p-8">
                      <div className="space-y-4">
                        <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center">
                          <svg className="h-5 w-5 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                          </svg>
                        </div>
                        <h3 className="text-xl font-medium">X (formerly Twitter!)</h3>
                        <p className="text-muted-foreground text-sm leading-relaxed">
                          Track conversations and engage with potential customers in real-time.
                        </p>
                        <div className="flex items-center text-gray-600 hover:text-gray-900 cursor-pointer" onClick={() => navigate('/auth')}>
                          <span className="text-sm">Monitor X</span>
                          <ArrowUpRight className="ml-2 h-4 w-4" />
                        </div>
                      </div>
                    </div>

                    {/* Card 3 - YouTube */}
                    <div className="rounded-2xl border border-gray-200 bg-white p-8">
                      <div className="space-y-4">
                        <div className="h-10 w-10 rounded-full bg-red-100 flex items-center justify-center">
                          <svg className="h-5 w-5 text-red-600" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
                          </svg>
                        </div>
                        <h3 className="text-xl font-medium">YouTube</h3>
                        <p className="text-muted-foreground text-sm leading-relaxed">
                          Find relevant video content and comments where your audience hangs out.
                        </p>
                        <div className="flex items-center text-gray-600 hover:text-gray-900 cursor-pointer" onClick={() => navigate('/auth')}>
                          <span className="text-sm">Monitor YouTube</span>
                          <ArrowUpRight className="ml-2 h-4 w-4" />
                        </div>
                      </div>
                    </div>

                    {/* Card 4 - IndieHackers */}
                    <div className="rounded-2xl border border-gray-200 bg-white p-8">
                      <div className="space-y-4">
                        <div className="h-10 w-10 rounded-full bg-black flex items-center justify-center">
                          <span className="text-white font-bold text-xs">IH</span>
                        </div>
                        <h3 className="text-xl font-medium">IndieHackers</h3>
                        <p className="text-muted-foreground text-sm leading-relaxed">
                          Connect with indie founders and discover opportunities in the maker community.
                        </p>
                        <div className="flex items-center text-gray-600 hover:text-gray-900 cursor-pointer" onClick={() => navigate('/auth')}>
                          <span className="text-sm">Monitor IndieHackers</span>
                          <ArrowUpRight className="ml-2 h-4 w-4" />
                        </div>
                      </div>
                    </div>

                    {/* Card 5 - Quora */}
                    <div className="rounded-2xl border border-gray-200 bg-white p-8">
                      <div className="space-y-4">
                        <div className="h-10 w-10 rounded-full flex items-center justify-center">
                          <span className="text-[#B92B27] font-serif text-3xl font-bold">Q</span>
                        </div>
                        <h3 className="text-xl font-medium">Quora</h3>
                        <p className="text-muted-foreground text-sm leading-relaxed">
                          Find questions and discussions related to your product or industry.
                        </p>
                        <div className="flex items-center text-gray-600 hover:text-gray-900 cursor-pointer" onClick={() => navigate('/auth')}>
                          <span className="text-sm">Monitor Quora</span>
                          <ArrowUpRight className="ml-2 h-4 w-4" />
                        </div>
                      </div>
                    </div>

                    {/* Card 6 - G2 */}
                    <div className="rounded-2xl border border-gray-200 bg-white p-8">
                      <div className="space-y-4">
                        <div className="h-10 w-10 rounded-full bg-[#FF492C] flex items-center justify-center">
                          <span className="text-white font-bold">G</span>
                        </div>
                        <h3 className="text-xl font-medium">G2</h3>
                        <p className="text-muted-foreground text-sm leading-relaxed">
                          Track reviews and ratings of your product and competitors on G2.
                        </p>
                        <div className="flex items-center text-gray-600 hover:text-gray-900 cursor-pointer" onClick={() => navigate('/auth')}>
                          <span className="text-sm">Monitor G2</span>
                          <ArrowUpRight className="ml-2 h-4 w-4" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Pricing Section */}
              <section id="pricing" className="container mx-auto px-4">
                <div className="mx-auto max-w-[64rem] space-y-8 text-center">
                  {/* Header */}
                  <div className="space-y-4">
                    <div className="inline-flex items-center rounded-full border border-gray-200 dark:border-gray-800 px-4 py-1.5">
                      <span className="text-sm font-medium">Unlimited Plans</span>
                    </div>
                    <h2 className="text-4xl font-medium">One simple plan to choose from</h2>
                    <p className="text-lg text-muted-foreground">
                      With this plan, you get unlimited alerts & keywords. (Plus 1-to-1 support).
                    </p>
                  </div>

                  {/* Pricing Card */}
                  <div className="max-w-xl mx-auto mt-12">
                    {/* Early Bird Monthly Plan */}
                    <div className="rounded-xl border-2 border-primary bg-background p-8 relative">
                      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                        <span className="bg-primary text-primary-foreground text-xs font-medium px-3 py-1 rounded-full">
                          EARLY BIRD OFFER
                        </span>
                      </div>
                      <div className="space-y-6">
                        <div>
                          <h3 className="text-2xl font-medium">Early Bird Monthly</h3>
                          <p className="text-muted-foreground mt-1">Limited time offer</p>
                        </div>

                        <div className="space-y-1">
                          <div className="flex items-center justify-center gap-2">
                            <span className="text-2xl font-medium text-muted-foreground line-through">$29</span>
                            <p className="text-5xl font-medium">$19</p>
                          </div>
                          <p className="text-muted-foreground">per month</p>
                        </div>

                        <div className="mt-8">
                          <Button
                            onClick={() => window.location.href = 'https://getseer.com/auth'}
                            className="inline-flex h-11 w-full items-center justify-center rounded-full bg-primary px-8 text-sm font-medium text-primary-foreground transition-colors hover:bg-primary/90"
                          >
                            Get Started
                            <ArrowRight className="ml-2 h-4 w-4" />
                          </Button>
                        </div>

                        <ul className="space-y-4 pt-8">
                          {[
                            'Unlimited monitoring & alerts',
                            'Unlimited saved comments & sentiment analysis',
                            '1-to-1 support',
                            'Access to 1,000+ communities, monitored 24/7',
                          ].map((feature, index) => (
                            <li key={index} className="flex items-start gap-3">
                              <svg className="h-5 w-5 text-primary flex-shrink-0" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <polyline points="20 6 9 17 4 12"></polyline>
                              </svg>
                              <span className="text-muted-foreground text-sm text-left">{feature}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* Secure Payment Note */}
                  <div className="mt-8 flex items-center justify-center gap-2 text-sm text-muted-foreground">
                    <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                      <path d="M7 11V7a5 5 0 0110 0v4"></path>
                    </svg>
                    Payments are secure & encrypted
                  </div>
                </div>
              </section>

              {/* Footer */}
              <footer className="relative bg-white border-t border-gray-200 dark:border-gray-800">
                <div className="container mx-auto px-4 py-12">
                  <div className="grid grid-cols-2 gap-8">
                    {/* Product */}
                    <div className="space-y-4">
                      <h4 className="text-sm font-semibold">Product</h4>
                      <ul className="space-y-3">
                        <li>
                          <button onClick={() => document.querySelector('.grid-cols-1.md\\:grid-cols-3').scrollIntoView({ behavior: 'smooth' })} className="text-sm text-muted-foreground hover:text-foreground">
                            Features
                          </button>
                        </li>
                        <li>
                          <button onClick={() => document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' })} className="text-sm text-muted-foreground hover:text-foreground">
                            Pricing
                          </button>
                        </li>
                      </ul>
                    </div>

                    {/* Legal */}
                    <div className="space-y-4">
                      <h4 className="text-sm font-semibold">Legal</h4>
                      <ul className="space-y-3">
                        <li>
                          <button onClick={() => navigate('/privacy')} className="text-sm text-muted-foreground hover:text-foreground">
                            Privacy
                          </button>
                        </li>
                        <li>
                          <button onClick={() => navigate('/terms')} className="text-sm text-muted-foreground hover:text-foreground">
                            Terms
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* Bottom Section */}
                  <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-800">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                      <div className="flex items-center gap-2">
                        <img 
                          src="/lovable-uploads/4dfd401e-6daf-4d78-8f5e-7f7296483c4f.png"
                          alt="Logo" 
                          className="h-6 w-auto"
                        />
                        <span className="text-sm text-muted-foreground">
                          © 2024 Seer. All rights reserved.
                        </span>
                      </div>
                      <div className="flex items-center gap-4">
                        <a href="https://twitter.com/ollyrosewell" target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-foreground">
                          <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                          </svg>
                        </a>
                        <a href="https://github.com/your-handle" target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-foreground">
                          <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.17 6.839 9.49.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.604-3.369-1.34-3.369-1.34-.454-1.156-1.11-1.464-1.11-1.464-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.203 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.935.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.137 20.167 22 16.418 22 12c0-5.523-4.477-10-10-10z"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Landing;
