import { ResultsTable } from "./ResultsTable";
import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";
import { EmptyState } from "./EmptyState";

interface KeywordSearchResultsProps {
  mentions: any[];
  buttonText: string;
  buttonClassName: string;
  currentPage: number;
  onPageChange: (page: number) => void;
  itemsPerPage?: number;
  isLoading?: boolean;
  error?: Error | null;
  allMentionsCount: number; // Add this prop to track total mentions before filtering
}

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { 
    opacity: 0, 
    y: 20,
    scale: 0.95
  },
  show: { 
    opacity: 1, 
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15
    }
  }
};

export const KeywordSearchResults = ({ 
  mentions,
  buttonText,
  buttonClassName,
  currentPage,
  onPageChange,
  itemsPerPage = 15,
  isLoading = false,
  error = null,
  allMentionsCount = 0 // Add this prop to track total mentions before filtering
}: KeywordSearchResultsProps) => {

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
        <span className="ml-3 text-sm text-gray-500">Loading mentions...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-sm text-red-500">
          Error loading mentions: {error.message}
        </p>
      </div>
    );
  }

  if (!mentions.length) {
    return <EmptyState hasExistingMatches={allMentionsCount > 0} />;
  }

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
    >
      <ResultsTable
        results={mentions}
        currentPage={currentPage}
        onPageChange={onPageChange}
        itemsPerPage={itemsPerPage}
        motionItem={item}
      />
    </motion.div>
  );
};
