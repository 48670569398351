import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { HelpCircle } from "lucide-react";

export const ContextModalHeader = () => {
  return (
    <DialogHeader>
      <div className="flex items-center gap-2">
        <DialogTitle className="text-xl">Add Context</DialogTitle>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <HelpCircle className="h-4 w-4 text-muted-foreground cursor-help" />
            </TooltipTrigger>
            <TooltipContent className="max-w-[300px] text-xs">
              <p className="text-gray-600 leading-relaxed">
                Adding detailed context about your business is crucial for our AI agent to find relevant leads and opportunities. The more specific you are about your domain, offer, and target customers, the better we can identify valuable discussions and potential customers.
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <DialogDescription className="text-gray-500 mt-2">
        Seer's AI agent will analyse posts relevant to your business. Add context here.
      </DialogDescription>
    </DialogHeader>
  );
};