import { Auth } from "@/components/Auth";
import { useAuth } from "@/contexts/AuthContext";
import { Navigate, Link, useLocation } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

const AuthPage = () => {
  const { user } = useAuth();
  const location = useLocation();
  const state = location.state as { subscriptionRequired?: boolean; returnUrl?: string };

  // If user is already authenticated and has no subscription requirement, redirect to dashboard
  if (user && !state?.subscriptionRequired) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md">
        <Link 
          to="/" 
          className="inline-flex items-center text-sm text-gray-600 hover:text-gray-900 mb-4"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to home
        </Link>
        <div className="bg-white rounded-xl shadow-sm border p-8">
          {state?.subscriptionRequired ? (
            <div className="text-center mb-6">
              <h2 className="text-xl font-semibold text-red-600">Subscription Required</h2>
              <p className="text-sm text-gray-600 mt-2">
                Please subscribe to access this feature
              </p>
            </div>
          ) : null}
          <Auth returnUrl={state?.returnUrl} />
        </div>
      </div>
    </div>
  );
};

export default AuthPage;