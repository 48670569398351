import { useState, useEffect, forwardRef } from 'react';
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Check } from "lucide-react";
import { cn } from "@/lib/utils";

interface ContextFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  multiline?: boolean;
  onEnter?: () => void;
  isComplete?: boolean;
  nextFieldRef?: React.RefObject<HTMLTextAreaElement | HTMLInputElement>;
  minLength?: number;
  maxLength?: number;
}

export const ContextField = forwardRef<HTMLTextAreaElement | HTMLInputElement, ContextFieldProps>(({
  label,
  value,
  onChange,
  placeholder,
  multiline = false,
  onEnter,
  isComplete,
  nextFieldRef,
  minLength,
  maxLength
}, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    
    if (maxLength && newValue.length > maxLength) {
      setError(`Maximum ${maxLength} characters allowed`);
      return;
    }
    
    onChange(newValue);
    setError(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (minLength && value.length < minLength) {
        setError(`Minimum ${minLength} characters required`);
        return;
      }
      if (value.trim()) {
        onEnter?.();
        if (nextFieldRef?.current) {
          nextFieldRef.current.focus();
        }
      }
    } else if (e.key === 'Tab') {
      if (nextFieldRef?.current) {
        e.preventDefault();
        nextFieldRef.current.focus();
      }
    }
  };

  const InputComponent = multiline ? Textarea : Input;

  return (
    <div className="space-y-2 relative">
      <label className="text-base font-medium text-gray-700">{label}</label>
      <div className="relative">
        <InputComponent
          ref={ref as any}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          maxLength={maxLength}
          className={cn(
            "text-gray-600 placeholder:text-gray-400/70",
            multiline && "min-h-[100px] resize-none",
            "pr-8",
            error && "border-red-300 focus-visible:ring-red-300"
          )}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            if (minLength && value.length < minLength) {
              setError(`Minimum ${minLength} characters required`);
            }
          }}
          onKeyDown={handleKeyDown}
        />
        {isComplete && !error && (
          <Check 
            className={cn(
              "absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-emerald-500",
              "animate-in fade-in duration-300"
            )}
          />
        )}
      </div>
      {error && (
        <p className="text-xs text-red-500 mt-1">{error}</p>
      )}
    </div>
  );
});

ContextField.displayName = "ContextField";