import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';

export const useLaunchAgent = () => {
  const [isLaunching, setIsLaunching] = useState(false);
  const { toast } = useToast();

  const launchAgent = async () => {
    try {
      setIsLaunching(true);
      
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.user) {
        throw new Error('No active session');
      }

      // First fetch the user's keywords
      console.log("Fetching keywords for user:", session.user.id);
      const { data: keywords, error: keywordError } = await supabase
        .from('subreddit_keywords')
        .select('keyword')
        .eq('user_id', session.user.id);

      if (keywordError) {
        console.error("Error fetching keywords:", keywordError);
        throw keywordError;
      }

      const keywordList = keywords.map(k => k.keyword);
      console.log("Fetched keywords:", keywordList);
      
      console.log("Calling trigger-hello-world function with user_id:", session.user.id);
      
      const response = await supabase.functions.invoke('trigger-hello-world', {
        body: { 
          user_id: session.user.id,
          keywords: keywordList
        }
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      console.log("Successfully called trigger-hello-world:", response.data);

      toast({
        title: "🎉 Agent launched successfully",
        description: "Your keywords are being processed. Results will appear in your feed shortly.",
      });

    } catch (error: any) {
      console.error('Error launching agent:', error);
      toast({
        title: "Error launching agent",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsLaunching(false);
    }
  };

  return {
    launchAgent,
    isLaunching
  };
};