import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Search, Plus, ChevronDown } from "lucide-react";
import { KeywordManagement } from "./KeywordManagement";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";

interface SocialKeywordFilterProps {
  keywords: Array<{ id: string; keyword: string; created_at: string }>;
  selectedKeywords: string[];
  onKeywordToggle: (keyword: string) => void;
}

export const SocialKeywordFilter = ({ 
  keywords, 
  selectedKeywords, 
  onKeywordToggle 
}: SocialKeywordFilterProps) => {
  const [isKeywordModalOpen, setIsKeywordModalOpen] = useState(false);
  const visibleKeywords = keywords.slice(0, 3);
  const remainingKeywords = keywords.slice(3);

  return (
    <div className="flex items-center gap-2 mb-4">
      <div className="relative flex-1 max-w-md">
        <div 
          onClick={() => setIsKeywordModalOpen(true)}
          className={`relative flex items-center gap-1 bg-white border ${keywords.length === 0 ? 'border-gray-200 hover:border-gray-300' : 'border-gray-200'} rounded-lg shadow-sm pl-9 pr-2 py-2 min-h-[42px] transition-colors cursor-pointer`}
        >
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-3.5 w-3.5 text-gray-400" />
          {keywords.length === 0 ? (
            <span className="text-sm text-gray-400">No keywords added yet</span>
          ) : (
            <div className="flex items-center gap-1.5 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent">
              {visibleKeywords.map((kw) => (
                <span
                  key={kw.id}
                  className={`inline-flex items-center gap-1 px-2 py-0.5 text-xs rounded-full whitespace-nowrap cursor-pointer transition-all hover:scale-[1.02] ${
                    selectedKeywords.includes(kw.keyword)
                      ? 'bg-primary text-primary-foreground shadow-sm'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent modal from opening when clicking keywords
                    onKeywordToggle(kw.keyword);
                  }}
                >
                  {kw.keyword}
                </span>
              ))}
              
              {remainingKeywords.length > 0 && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      className="h-6 px-2 text-xs text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                      onClick={(e) => e.stopPropagation()} // Prevent modal from opening when clicking dropdown
                    >
                      See {remainingKeywords.length} more
                      <ChevronDown className="ml-1 h-3 w-3" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent 
                    align="start"
                    className="w-[200px] bg-white p-2"
                  >
                    {remainingKeywords.map((kw) => (
                      <DropdownMenuItem
                        key={kw.id}
                        className={`text-sm cursor-pointer ${
                          selectedKeywords.includes(kw.keyword)
                            ? 'bg-primary/10 text-primary'
                            : ''
                        }`}
                        onClick={() => onKeywordToggle(kw.keyword)}
                      >
                        {kw.keyword}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          )}
        </div>
      </div>
      <Button 
        onClick={() => setIsKeywordModalOpen(true)}
        variant="outline"
        size="sm"
        className="h-[42px] px-3 text-xs flex items-center gap-1.5 border-gray-200 hover:bg-gray-50"
      >
        <Plus className="h-3.5 w-3.5" />
        Manage Keywords
      </Button>

      <KeywordManagement 
        isOpen={isKeywordModalOpen}
        onClose={() => setIsKeywordModalOpen(false)}
      />
    </div>
  );
};