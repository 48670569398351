import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useState } from "react";
import { EmptyState } from "./EmptyState";
import { Pagination } from "./Pagination";
import { motion } from "framer-motion";
import { Comment } from "./types";
import { ResultCard } from "./ResultCard";
import { isTwitterResult } from "./utils";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface ResultsTableProps {
  results: Comment[];
  currentPage: number;
  onPageChange: (page: number) => void;
  itemsPerPage?: number;
  motionItem: any;
}

export const ResultsTable = ({ 
  results, 
  currentPage, 
  onPageChange,
  itemsPerPage = 15,
  motionItem
}: ResultsTableProps) => {
  const [selectedComment, setSelectedComment] = useState<Comment | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentResults = results.slice(startIndex, endIndex);
  const totalPages = Math.ceil(results.length / itemsPerPage);

  const handleCommentSelect = (comment: Comment, index: number) => {
    setSelectedComment(comment);
    setSelectedIndex(index);
  };

  const handleNext = () => {
    if (selectedIndex < currentResults.length - 1) {
      const nextComment = currentResults[selectedIndex + 1];
      setSelectedComment(nextComment);
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      const prevComment = currentResults[selectedIndex - 1];
      setSelectedComment(prevComment);
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const getDisplayAuthor = (result: Comment) => {
    if (isTwitterResult(result)) {
      try {
        if (result.author && result.author.startsWith('{')) {
          const authorData = JSON.parse(result.author);
          return authorData.userName || result.author;
        }
      } catch (e) {
        console.error('Error parsing Twitter author data:', e);
      }
    }
    return result.author;
  };

  const normalizeDate = (result: Comment): string => {
    try {
      if (isTwitterResult(result)) {
        // Handle Twitter dates (ISO string)
        if (!result.created_at) {
          console.error('Twitter result missing created_at:', result);
          return new Date().toISOString();
        }
        
        const date = new Date(result.created_at);
        if (!isNaN(date.getTime())) {
          return date.toISOString();
        }
      } else {
        // Handle Reddit dates (Unix timestamp in seconds)
        if (!result.created_utc) {
          console.error('Reddit result missing created_utc:', result);
          return new Date().toISOString();
        }
        
        // Ensure we're working with a number
        const timestamp = typeof result.created_utc === 'string' 
          ? parseInt(result.created_utc) * 1000 
          : result.created_utc * 1000;
        
        if (isNaN(timestamp)) {
          console.error('Invalid Reddit timestamp:', result.created_utc);
          return new Date().toISOString();
        }
        
        const date = new Date(timestamp);
        if (!isNaN(date.getTime())) {
          return date.toISOString();
        }
      }
      
      console.error('Could not normalize date for result:', result);
      return new Date().toISOString(); // Fallback to current date
    } catch (error) {
      console.error('Error normalizing date:', error, result);
      return new Date().toISOString(); // Fallback to current date
    }
  };

  if (!results.length) {
    return <EmptyState hasExistingMatches={true} />;
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {currentResults.map((result, idx) => (
          <motion.div
            key={idx}
            variants={motionItem}
            layout
          >
            <ResultCard 
              result={{
                ...result,
                id: result.id || `${result.source}-${idx}`,
                source: result.source || (isTwitterResult(result) ? 'twitter' : 'reddit'),
                created_at: normalizeDate(result)
              }}
              onSelect={() => handleCommentSelect(result, idx)}
            />
          </motion.div>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="mt-6">
          <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}

      <Dialog open={!!selectedComment} onOpenChange={() => {
        setSelectedComment(null);
        setSelectedIndex(-1);
      }}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <span className="text-gray-600">
                {selectedComment && (isTwitterResult(selectedComment) ? 'Tweet by' : 'Comment by')}
              </span>
              {selectedComment && getDisplayAuthor(selectedComment)}
            </DialogTitle>
          </DialogHeader>
          <div className="mt-4 space-y-4 h-[300px] flex flex-col">
            <div className="flex-1 overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100">
              <p className="text-sm text-gray-600 whitespace-pre-wrap">
                {selectedComment && (isTwitterResult(selectedComment) 
                  ? selectedComment.text 
                  : selectedComment.body)}
              </p>
            </div>
            <div className="pt-4 border-t flex justify-between items-center text-xs text-gray-500">
              <span>
                {selectedComment && (isTwitterResult(selectedComment) 
                  ? `Posted on Twitter` 
                  : `Posted in r/${selectedComment.subreddit}`)}
              </span>
              <span>
                {selectedComment && new Date(isTwitterResult(selectedComment) 
                  ? selectedComment.created_at 
                  : selectedComment.created_utc * 1000).toLocaleDateString()}
              </span>
            </div>
          </div>
          <div className="flex justify-between mt-6">
            <Button
              variant="outline"
              size="sm"
              onClick={handlePrevious}
              disabled={selectedIndex <= 0}
              className="text-xs gap-1"
            >
              <ChevronLeft className="h-4 w-4" />
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={handleNext}
              disabled={selectedIndex >= currentResults.length - 1}
              className="text-xs gap-1"
            >
              Next
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};