import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";

export interface FeedItem {
  comment_id: string;
  author: string;
  body?: string;  // for Reddit
  text?: string;  // for Twitter
  created_utc: number;
  score: number;
  permalink: string;
  source: 'reddit' | 'twitter';
  keyword: string;
  sentiment: string;
  sentiment_reasoning: string | null;
}

export const useFeedData = () => {
  const [items, setItems] = useState<FeedItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [batchCount, setBatchCount] = useState(0);
  const itemsPerBatch = 10000; // Increased to 10k per batch
  const maxBatches = 10; // Increased to 10 batches total

  const extractTwitterUsername = (url: string): string => {
    try {
      const match = url.match(/(?:twitter\.com|x\.com)\/([^\/]+)/);
      return match ? match[1] : 'unknown';
    } catch (error) {
      console.error('Error extracting Twitter username:', error);
      return 'unknown';
    }
  };

  const fetchData = async (currentOffset: number) => {
    try {
      setIsLoading(true);
      const { data: twitterData, error: twitterError } = await supabase
        .from('tweet_matches')
        .select('*')
        .order('created_at', { ascending: sortOrder === 'asc' })
        .range(currentOffset, currentOffset + itemsPerBatch - 1);

      if (twitterError) throw twitterError;

      const transformedTwitterData: FeedItem[] = (twitterData || []).map(item => ({
        comment_id: item.id,
        author: item.author,
        body: item.text,
        text: item.text,
        created_utc: new Date(item.created_at).getTime() / 1000,
        score: item.score,
        permalink: item.url,
        source: 'twitter' as const,
        keyword: item.keyword,
        sentiment: item.sentiment || '',
        sentiment_reasoning: item.sentiment_reasoning
      }));

      const { data: redditData, error: redditError } = await supabase
        .from('apify_reddit_results')
        .select('*')
        .order('created_at', { ascending: sortOrder === 'asc' })
        .range(currentOffset, currentOffset + itemsPerBatch - 1);

      if (redditError) throw redditError;

      const transformedRedditData: FeedItem[] = (redditData || []).map(item => ({
        comment_id: item.id,
        author: item.author,
        body: item.body,
        created_utc: new Date(item.created_at).getTime() / 1000,
        score: item.score,
        permalink: item.permalink,
        source: 'reddit' as const,
        keyword: item.keyword,
        sentiment: item.sentiment || '',
        sentiment_reasoning: item.sentiment_reasoning
      }));

      const combinedData = [...transformedTwitterData, ...transformedRedditData].sort((a, b) => {
        return sortOrder === 'desc' 
          ? b.created_utc - a.created_utc 
          : a.created_utc - b.created_utc;
      });

      setItems(prevItems => {
        const newItems = currentOffset === 0 ? combinedData : [...prevItems, ...combinedData];
        return newItems;
      });

      setHasMore(combinedData.length === itemsPerBatch);
      setBatchCount(prev => prev + 1);
    } catch (error) {
      console.error('Error in fetchData:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial load of first batch
  useEffect(() => {
    setItems([]);
    setOffset(0);
    setBatchCount(0);
    setHasMore(true);
    console.log('Starting initial data load...');
    fetchData(0);
  }, [sortOrder]);

  // Load next batch
  useEffect(() => {
    if (!isLoading && hasMore && batchCount > 0 && batchCount < maxBatches) {
      console.log(`Triggering batch ${batchCount + 1} fetch from offset ${offset}...`);
      fetchData(offset);
    }
  }, [batchCount, isLoading, hasMore]);

  return {
    comments: items,
    isLoading,
    sortOrder,
    setSortOrder,
    hasMore
  };
};