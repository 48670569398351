import { Layout, Inbox, Share2, BookOpen, Settings, Plus, AlertTriangle, HelpCircle, Clock, Bug, Puzzle, Eye, Bookmark, Mail, Twitter } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { ContextModal } from "../context/ContextModal";
import { useEffect, useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { useQuery } from "@tanstack/react-query";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { BugReportModal } from "../BugReportModal";

export const SidebarNav = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [hasContext, setHasContext] = useState(true);
  const [isContextModalOpen, setIsContextModalOpen] = useState(false);
  const [isBugReportOpen, setIsBugReportOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const { data: inboxCount = 0 } = useQuery({
    queryKey: ['inbox-count', user?.id],
    queryFn: async () => {
      console.log('Fetching inbox count for user:', user?.id);
      if (!user?.id) return 0;
      
      const { count: keywordCount, error: keywordError } = await supabase
        .from('keyword_matches')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', user.id)
        .in('sentiment', ['High', 'Moderate']);

      if (keywordError) {
        console.error('Error fetching keyword matches count:', keywordError);
        throw keywordError;
      }

      const { count: tweetCount, error: tweetError } = await supabase
        .from('tweet_matches')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', user.id)
        .in('sentiment', ['High', 'Moderate']);

      if (tweetError) {
        console.error('Error fetching tweet matches count:', tweetError);
        throw tweetError;
      }

      const totalCount = (keywordCount || 0) + (tweetCount || 0);
      console.log('Total inbox count:', totalCount, '(Keywords:', keywordCount, ', Tweets:', tweetCount, ')');
      return totalCount;
    },
    enabled: !!user?.id
  });

  useEffect(() => {
    if (user) {
      checkForContext();
    }
  }, [user]);

  const checkForContext = async () => {
    try {
      const { data } = await supabase
        .from('user_context')
        .select('*')
        .eq('user_id', user?.id)
        .single();
      
      setHasContext(!!data?.domain && !!data?.offer_context && !!data?.customer_context);
    } catch (error) {
      console.error('Error checking context:', error);
      setHasContext(false);
    }
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:oliver@getseer.com';
  };

  const handleTwitterClick = () => {
    window.open('https://twitter.com/messages/compose?recipient_id=1319764991701094405', '_blank');
  };

  return (
    <nav className="flex-1 px-3">
      <div className="space-y-0.5 mb-6">
        <Link to="/dashboard">
          <button className={`w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 ${isActive('/dashboard') ? 'bg-gray-50 text-gray-900' : 'text-gray-600'}`}>
            <Layout className="h-4 w-4" />
            Dashboard
          </button>
        </Link>
        <Link to="/playground">
          <button className={`w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 ${isActive('/playground') ? 'bg-gray-50 text-gray-900' : 'text-gray-600'}`}>
            <Puzzle className="h-4 w-4" />
            Playground
          </button>
        </Link>
        <Link to="/inbox">
          <button className={`w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 ${isActive('/inbox') ? 'bg-gray-50 text-gray-900' : 'text-gray-600'}`}>
            <div className="flex items-center gap-2 flex-1">
              <Inbox className="h-4 w-4" />
              Inbox
              {inboxCount > 0 && (
                <span className="bg-green-100 text-green-700 text-[10px] font-medium px-2 py-0.5 rounded border-2 border-green-200">
                  {inboxCount}
                </span>
              )}
            </div>
          </button>
        </Link>
        <Link to="/search">
          <button className={`w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 ${isActive('/search') ? 'bg-gray-50 text-gray-900' : 'text-gray-600'}`}>
            <Eye className="h-4 w-4" />
            Search
          </button>
        </Link>
        <Link to="/playground?tab=saved">
          <button className={`w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 ${location.pathname === '/playground' && location.search.includes('tab=saved') ? 'bg-gray-50 text-gray-900' : 'text-gray-600'}`}>
            <Bookmark className="h-4 w-4" />
            Saved Comments
          </button>
        </Link>
        {/* Social nav temporarily hidden
        <Link to="/social">
          <button className={`w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 ${isActive('/social') ? 'bg-gray-50 text-gray-900' : 'text-gray-600'}`}>
            <Share2 className="h-4 w-4" />
            Social
          </button>
        </Link>
        */}
      </div>

      <div className="space-y-0.5 mb-6">
        <div className="relative">
          <button 
            onClick={() => setIsHelpOpen(!isHelpOpen)}
            className="w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 text-gray-600"
          >
            <HelpCircle className="h-4 w-4" />
            Help
          </button>
          <div 
            className={`overflow-hidden transition-all duration-200 ease-in-out ${
              isHelpOpen ? 'max-h-20 opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <button 
              onClick={handleTwitterClick}
              className="w-full text-left pl-9 py-2 text-[13px] hover:bg-gray-50 text-gray-600 transition-colors flex items-center gap-2"
            >
              <Twitter className="h-3.5 w-3.5" />
              DM me on X
            </button>
            <button 
              onClick={handleEmailClick}
              className="w-full text-left pl-9 py-2 text-[13px] hover:bg-gray-50 text-gray-600 transition-colors flex items-center gap-2"
            >
              <Mail className="h-3.5 w-3.5" />
              oliver@getseer.com
            </button>
          </div>
        </div>

        <button 
          onClick={() => setIsBugReportOpen(true)}
          className="w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 text-gray-600"
        >
          <Bug className="h-4 w-4" />
          Found a bug?
        </button>

        <Link to="/docs">
          <button className={`w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 ${isActive('/docs') ? 'bg-gray-50 text-gray-900' : 'text-gray-600'}`}>
            <BookOpen className="h-4 w-4" />
            Documentation
          </button>
        </Link>
        <Link to="/settings">
          <button className={`w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 ${isActive('/settings') ? 'bg-gray-50 text-gray-900' : 'text-gray-600'}`}>
            <Settings className="h-4 w-4" />
            Settings
          </button>
        </Link>
        <div className="relative">
          <button 
            className="w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 text-gray-600"
            onClick={() => setIsContextModalOpen(true)}
          >
            <Plus className="h-4 w-4" />
            Context
          </button>
          {!hasContext && (
            <AlertTriangle className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-amber-500" />
          )}
        </div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <button 
                className="w-full text-left px-3 py-2 rounded-lg text-[13px] hover:bg-gray-50 flex items-center gap-2 text-gray-400"
              >
                <Clock className="h-4 w-4" />
                Schedules
              </button>
            </TooltipTrigger>
            <TooltipContent className="max-w-[300px]">
              <div className="space-y-2">
                <p className="text-xs">Alert waves come twice a day: at 1pm & 8pm UTC.</p>
                <p className="text-xs text-gray-500">1M+ comments are scraped from various communities twice-daily, just prior to the alert waves so the Agent finds leads as recent as possible.</p>
                <p className="text-xs">You'll receive alert emails to {user?.email} at these times.</p>
                <div className="mt-4">
                  <Link to="/docs" className="text-xs hover:underline">
                    Learn more
                  </Link>
                </div>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      <ContextModal 
        isOpen={isContextModalOpen} 
        onClose={() => setIsContextModalOpen(false)} 
      />

      <BugReportModal
        open={isBugReportOpen}
        onOpenChange={setIsBugReportOpen}
      />
    </nav>
  );
};
