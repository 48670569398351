import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { KeywordSearchResults } from "./KeywordSearchResults";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { useState } from "react";
import { Slash } from "lucide-react";

export const SocialTabNav = ({ counts }: { counts: any }) => {
  return (
    <TabsList className="h-10 p-1 bg-transparent space-x-2">
      <TabsTrigger 
        value="all"
        className="data-[state=active]:bg-transparent data-[state=active]:border-b-2 data-[state=active]:border-primary data-[state=active]:shadow-none rounded-none px-3 py-2 h-9 text-sm font-medium text-muted-foreground data-[state=active]:text-foreground relative"
      >
        All
        <span className="ml-2 text-xs bg-muted px-1.5 py-0.5 rounded-full">
          {counts.all}
        </span>
      </TabsTrigger>
      
      <TabsTrigger 
        value="reddit"
        className="data-[state=active]:bg-transparent data-[state=active]:border-b-2 data-[state=active]:border-primary data-[state=active]:shadow-none rounded-none px-3 py-2 h-9 text-sm font-medium text-muted-foreground data-[state=active]:text-foreground relative"
      >
        Reddit
        <span className="ml-2 text-xs bg-muted px-1.5 py-0.5 rounded-full">
          {counts.reddit}
        </span>
      </TabsTrigger>

      <TabsTrigger 
        value="x"
        className="data-[state=active]:bg-transparent data-[state=active]:border-b-2 data-[state=active]:border-primary data-[state=active]:shadow-none rounded-none px-3 py-2 h-9 text-sm font-medium text-muted-foreground data-[state=active]:text-foreground relative"
      >
        X (Twitter)
        <span className="ml-2 text-xs bg-muted px-1.5 py-0.5 rounded-full">
          {counts.x}
        </span>
      </TabsTrigger>
      
      <TabsTrigger 
        value="youtube"
        disabled
        className="data-[state=active]:bg-transparent data-[state=active]:border-b-2 data-[state=active]:border-primary data-[state=active]:shadow-none rounded-none px-3 py-2 h-9 text-sm font-medium text-muted-foreground data-[state=active]:text-foreground relative opacity-60"
      >
        YouTube
        <span className="ml-1 text-xs text-muted-foreground">(coming soon)</span>
        <Slash className="absolute right-1 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground/40" />
      </TabsTrigger>
    </TabsList>
  );
};

export const SocialTabContent = ({ mentions, selectedKeywords, selectedSentiments }: { 
  mentions: any[], 
  selectedKeywords: string[],
  selectedSentiments: string[]
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuth();

  // Fetch tweet matches
  const { data: tweetMentions = [] } = useQuery({
    queryKey: ['tweet_matches', user?.id],
    queryFn: async () => {
      if (!user?.id) return [];
      
      const { data, error } = await supabase
        .from('tweet_matches')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });
      
      if (error) {
        console.error('Error fetching tweet matches:', error);
        throw error;
      }
      
      return data || [];
    },
    enabled: !!user?.id
  });

  // Filter function for both types of mentions
  const filterMatches = (matches: any[]) => {
    // First filter out AutoModerator comments
    const withoutAutoMod = matches.filter(match => match.author !== 'AutoModerator');

    // Then apply keyword and sentiment filters
    const filtered = withoutAutoMod.filter(match => {
      const matchesKeyword = selectedKeywords.length === 0 || selectedKeywords.includes(match.keyword);
      const matchesSentiment = selectedSentiments.length === 0 || (
        match.sentiment && selectedSentiments.includes(match.sentiment.toLowerCase())
      );
      return matchesKeyword && matchesSentiment;
    });

    // Create a map to track unique comments based on content and author
    const uniqueMap = new Map();
    
    return filtered.filter(match => {
      // Create a unique key combining author and content
      const content = match.text || match.body; // Handle both tweet and reddit content
      const key = `${match.author}-${content}`;
      
      if (uniqueMap.has(key)) {
        return false; // Skip this duplicate
      }
      
      uniqueMap.set(key, true);
      return true;
    });
  };

  // Filter both types of mentions
  const filteredMentions = filterMatches(mentions);
  const filteredTweetMentions = filterMatches(tweetMentions);

  // Log the filtering results
  console.log('Original mentions count:', mentions.length);
  console.log('Filtered mentions count (without duplicates and AutoMod):', filteredMentions.length);
  console.log('Original tweet mentions count:', tweetMentions.length);
  console.log('Filtered tweet mentions count (without duplicates and AutoMod):', filteredTweetMentions.length);

  // Combine and sort for "all" tab
  const allMentions = [...filteredMentions, ...filteredTweetMentions].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });

  // Calculate total mentions before filtering
  const totalMentions = mentions.length + tweetMentions.length;

  return (
    <>
      <TabsContent value="all" className="mt-0">
        <KeywordSearchResults 
          mentions={allMentions}
          buttonText="Use Monitor" 
          buttonClassName="text-xs bg-[#f8f9fa] text-gray-600 hover:bg-gray-100"
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          itemsPerPage={15}
          allMentionsCount={totalMentions}
        />
      </TabsContent>

      <TabsContent value="reddit" className="mt-0">
        <KeywordSearchResults 
          mentions={filteredMentions}
          buttonText="Use Monitor" 
          buttonClassName="text-xs bg-[#f8f9fa] text-gray-600 hover:bg-gray-100"
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          itemsPerPage={15}
          allMentionsCount={mentions.length}
        />
      </TabsContent>
      
      <TabsContent value="x" className="mt-0">
        <KeywordSearchResults 
          mentions={filteredTweetMentions}
          buttonText="Use Monitor" 
          buttonClassName="text-xs bg-[#f8f9fa] text-gray-600 hover:bg-gray-100"
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          itemsPerPage={15}
          allMentionsCount={tweetMentions.length}
        />
      </TabsContent>
      
      <TabsContent value="youtube" className="mt-0">
        <div className="text-center py-8 text-muted-foreground">
          YouTube integration coming soon
        </div>
      </TabsContent>
    </>
  );
};