import { useState, useEffect, useRef } from "react";
import Sidebar from "@/components/Sidebar";
import { Input } from "@/components/ui/input";
import { MessageCircle, Twitter, Youtube, Search as SearchIcon, X, ChevronLeft, ChevronRight, Flame } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { Card } from "@/components/ui/card";
import { formatDistanceToNow } from "date-fns";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Badge } from "@/components/ui/badge";
import { useAuth } from "@/contexts/AuthContext";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { useSearchData } from "@/hooks/useSearchData";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { useToast } from "@/components/ui/use-toast";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.1
    }
  }
};

const item = {
  hidden: { 
    opacity: 0, 
    y: 20,
    scale: 0.95
  },
  show: { 
    opacity: 1, 
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      duration: 0.5
    }
  }
};

const styles = {
  '@keyframes indeterminate': {
    '0%': {
      transform: 'translateX(-100%) scaleX(0.5)'
    },
    '100%': {
      transform: 'translateX(100%) scaleX(0.5)'
    }
  }
} as const;

// Function to detect CJK characters
const containsCJKCharacters = (text: string): boolean => {
  // This regex covers Chinese, Japanese, Korean characters
  const cjkRegex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf\u2e80-\u2eff\u31c0-\u31ef\u3200-\u32ff\u3300-\u33ff\uac00-\ud7af]/;
  return cjkRegex.test(text);
};

const Search = () => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const scrollViewportRef = useRef<HTMLDivElement>(null);
  const { comments, isLoading } = useSearchData(keywords);
  const { toast } = useToast();
  const { user } = useAuth();
  const [selectedComment, setSelectedComment] = useState<any | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      if (!keywords.includes(inputValue.trim())) {
        setKeywords([...keywords, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !inputValue && keywords.length > 0) {
      e.preventDefault(); // Prevent any default backspace behavior
      const newKeywords = keywords.slice(0, -1);
      setKeywords(newKeywords);
    }
  };

  const removeKeyword = (keywordToRemove: string) => {
    const newKeywords = keywords.filter(k => k !== keywordToRemove);
    setKeywords(newKeywords);
  };

  const getTwitterAuthorFromUrl = (url: string) => {
    try {
      const matches = url.match(/https?:\/\/(?:www\.)?x\.com\/([^/]+)/);
      return matches ? matches[1] : 'unknown';
    } catch (error) {
      console.error('Error extracting Twitter author:', error);
      return 'unknown';
    }
  };

  const getFormattedDate = (comment: any) => {
    try {
      // For Reddit comments (Unix timestamp)
      if (comment.created_utc) {
        return formatDistanceToNow(new Date(comment.created_utc * 1000), { addSuffix: true });
      }
      // For Twitter posts (ISO string)
      if (comment.created_at) {
        return formatDistanceToNow(new Date(comment.created_at), { addSuffix: true });
      }
      return 'Date unavailable';
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date unavailable';
    }
  };

  const filteredComments = comments.filter(comment => {
    if (!selectedSource) return true;
    if (selectedSource === 'reddit') return !comment.twitter_url;
    if (selectedSource === 'twitter') return !!comment.twitter_url;
    return true;
  });

  const startIndex = (currentPage - 1) * 20;
  const endIndex = startIndex + 20;
  const currentComments = filteredComments.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredComments.length / 20);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    const viewport = scrollViewportRef.current?.querySelector('[data-radix-scroll-area-viewport]');
    if (viewport instanceof HTMLElement) {
      viewport.scrollTop = 0;
    }
  };

  const handleNext = () => {
    if (selectedIndex < currentComments.length - 1) {
      const nextComment = currentComments[selectedIndex + 1];
      setSelectedComment(nextComment);
      setSelectedIndex(selectedIndex + 1);
    } else if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      handlePageChange(nextPage);
      setSelectedIndex(0);
      const nextPageStartIndex = (nextPage - 1) * 20;
      const nextComment = filteredComments[nextPageStartIndex];
      setSelectedComment(nextComment);
    }
  };

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      const prevComment = currentComments[selectedIndex - 1];
      setSelectedComment(prevComment);
      setSelectedIndex(selectedIndex - 1);
    } else if (currentPage > 1) {
      const prevPage = currentPage - 1;
      handlePageChange(prevPage);
      const lastIndexOfPrevPage = Math.min(20 - 1, filteredComments.slice((prevPage - 1) * 20, prevPage * 20).length - 1);
      setSelectedIndex(lastIndexOfPrevPage);
      const prevPageStartIndex = (prevPage - 1) * 20;
      const prevComment = filteredComments[prevPageStartIndex + lastIndexOfPrevPage];
      setSelectedComment(prevComment);
    }
  };

  const handleCommentClick = (comment: any, index: number) => {
    setSelectedComment(comment);
    setSelectedIndex(index);
  };

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.textContent = `
      @keyframes indeterminate {
        0% {
          transform: translateX(-100%) scaleX(0.5);
        }
        100% {
          transform: translateX(100%) scaleX(0.5);
        }
      }
    `;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white">
      <Sidebar />
      <div className="flex-1 flex flex-col min-h-0">
        <div className="flex-shrink-0 sticky top-0 z-10 backdrop-blur-sm bg-white/80 p-8 pb-4 border-b">
          <div className="w-full max-w-2xl mx-auto">
            <div className="relative mb-8">
              <div className="absolute inset-0 w-full h-full pointer-events-none select-none">
                <MessageCircle 
                  className="absolute text-gray-400/40 animate-float-1" 
                  style={{ top: '-20px', left: '20%' }}
                  size={24}
                />
                <Twitter 
                  className="absolute text-gray-400/40 animate-float-2" 
                  style={{ top: '-10px', right: '25%' }}
                  size={24}
                />
                <Youtube 
                  className="absolute text-gray-400/40 animate-float-3" 
                  style={{ top: '10px', left: '30%' }}
                  size={24}
                />
                <MessageCircle 
                  className="absolute text-gray-400/40 animate-float-4" 
                  style={{ top: '20px', right: '20%' }}
                  size={20}
                />
              </div>

              <div className="flex flex-col items-center gap-3">
                <div className="flex items-center justify-center gap-2">
                  <img 
                    src="/lovable-uploads/4dfd401e-6daf-4d78-8f5e-7f7296483c4f.png" 
                    alt="Seer Logo" 
                    className="w-6 h-6" 
                    draggable="false"
                  />
                  <h1 className="text-lg font-medium text-gray-700">Social Search</h1>
                </div>

                <div className="px-4 py-2 bg-gray-50/80 border border-gray-200 rounded-lg text-sm text-gray-400 flex items-center gap-2">
                  <Flame className="h-4 w-4 text-orange-500" />
                  A search engine for leads across social networks
                </div>
              </div>
            </div>

            <div className="relative">
              <div 
                className="relative flex items-center pl-12 pr-3 h-12 w-full rounded-2xl border border-gray-200 bg-white shadow-sm hover:shadow-sm focus-within:shadow-sm cursor-text"
                onClick={() => document.getElementById('searchInput')?.focus()}
              >
                <SearchIcon 
                  className="absolute left-4 text-gray-300/50 pointer-events-none" 
                  size={16}
                />
                <div className="flex items-center gap-2 min-w-0 flex-1 overflow-x-auto scrollbar-none">
                  <div className="flex items-center gap-1.5 flex-nowrap">
                    {keywords.map((keyword, index) => (
                      <Badge
                        key={index}
                        className="bg-green-50 text-green-700 border border-green-200 hover:bg-green-100 transition-colors px-2 py-1 text-xs rounded-md flex items-center gap-1 shrink-0"
                      >
                        {keyword}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            removeKeyword(keyword);
                          }}
                          className="hover:text-green-800"
                        >
                          <X size={12} />
                        </button>
                      </Badge>
                    ))}
                  </div>
                  <input
                    id="searchInput"
                    type="text"
                    placeholder={keywords.length === 0 ? "Search for leads in millions of posts..." : ""}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    onKeyDown={handleKeyDown}
                    className="flex-1 border-0 bg-transparent p-0 text-sm focus:outline-none focus:ring-0 min-w-[20px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col min-h-0 px-8">
          <div className="w-full max-w-4xl mx-auto flex flex-col h-full">
            <Tabs defaultValue="feed" className="flex flex-col flex-1">
              <div className="flex items-center justify-between py-4 flex-shrink-0">
                <div className="flex items-center gap-4">
                  <TabsList className="bg-transparent border-b p-0">
                    <TabsTrigger 
                      value="feed" 
                      className="px-4 py-2 h-10 data-[state=active]:border-b-2 data-[state=active]:border-primary"
                    >
                      Feed
                    </TabsTrigger>
                  </TabsList>
                  <ToggleGroup 
                    type="single" 
                    value={selectedSource || ''} 
                    onValueChange={(value) => setSelectedSource(value || null)}
                    className="flex items-center gap-2"
                  >
                    <ToggleGroupItem 
                      value="reddit" 
                      className="p-2 rounded transition-opacity duration-200 data-[state=off]:opacity-30 hover:opacity-100"
                    >
                      <img 
                        src="/redditlogo.png"
                        alt="Reddit"
                        className="h-4 w-4"
                      />
                    </ToggleGroupItem>
                    <ToggleGroupItem 
                      value="twitter" 
                      className="p-2 rounded transition-opacity duration-200 data-[state=off]:opacity-30 hover:opacity-100"
                    >
                      <Twitter className="h-4 w-4 text-blue-400" />
                    </ToggleGroupItem>
                  </ToggleGroup>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    {isLoading && (
                      <div className="w-24 h-8 flex items-center">
                        <div className="w-full h-0.5 bg-gray-100/50 relative overflow-hidden">
                          <div 
                            className="absolute inset-0 bg-blue-500/20"
                            style={{
                              animation: 'indeterminate 1s ease-out infinite',
                              transformOrigin: '0% 50%'
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="h-8 w-8 p-0"
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="h-8 w-8 p-0"
                    >
                      <ChevronRight className="h-4 w-4" />
                    </Button>
                  </div>
                  <span className="text-sm text-gray-500">
                    {`${keywords.length > 0 ? filteredComments.length : comments.length} results found`}
                  </span>
                </div>
              </div>

              <TabsContent value="feed" className="flex-1 min-h-0 relative">
                <ScrollArea className="h-[calc(100vh-280px)]" ref={scrollViewportRef}>
                  {isLoading && comments.length === 0 ? (
                    <div className="text-center py-12 text-gray-500">
                      Loading results...
                    </div>
                  ) : (
                    <motion.div 
                      className="space-y-3 pb-16"
                      variants={container}
                      initial="hidden"
                      animate="show"
                    >
                      {currentComments.length === 0 ? (
                        <div className="text-center py-12 text-gray-500">
                          No comments found
                        </div>
                      ) : (
                        currentComments.map((comment, index) => {
                          const isTwitter = !!comment.twitter_url;
                          const author = isTwitter 
                            ? getTwitterAuthorFromUrl(comment.twitter_url)
                            : comment.author;

                          return (
                            <motion.div 
                              key={comment.id || `${comment.source}-${index}`}
                              variants={item}
                            >
                              <Card 
                                className="p-4 hover:shadow-sm transition-all duration-200 cursor-pointer group"
                                onClick={() => handleCommentClick(comment, index)}
                              >
                                <div className="flex items-center justify-between gap-4">
                                  <div className="flex items-center gap-3 min-w-0 flex-1">
                                    {isTwitter ? (
                                      <Twitter className="h-4 w-4 text-blue-400" />
                                    ) : (
                                      <img 
                                        src="/redditlogo.png"
                                        alt="Reddit"
                                        className="h-4 w-4 flex-shrink-0"
                                      />
                                    )}
                                    <div className="flex items-center gap-2 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate group-hover:text-blue-600">
                                        {isTwitter ? `@${author}` : `r/${comment.subreddit}`}
                                      </p>
                                      <span className="text-xs text-gray-500">
                                        • {isTwitter ? 'Twitter' : `u/${comment.author}`}
                                      </span>
                                      {comment.score && (
                                        <span className="text-[10px] bg-orange-50 text-orange-600 px-1.5 py-0.5 rounded">
                                          {comment.score}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-3 flex-shrink-0">
                                    <span className="text-[11px] text-gray-400">
                                      {getFormattedDate(comment)}
                                    </span>
                                    <a 
                                      href={comment.permalink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-xs text-blue-600 hover:text-blue-700 font-medium"
                                    >
                                      View →
                                    </a>
                                  </div>
                                </div>
                                <p className="text-[13px] text-gray-400 line-clamp-2 mt-2 group-hover:text-gray-600">
                                  {comment.body || comment.text}
                                </p>
                              </Card>
                            </motion.div>
                          );
                        })
                      )}
                    </motion.div>
                  )}
                </ScrollArea>
                
                {currentComments.length > 0 && (
                  <div className="absolute bottom-0 left-0 right-0 border-t bg-white p-4 flex justify-center">
                    <div className="flex items-center gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="h-8 w-8 p-0"
                      >
                        <ChevronLeft className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="h-8 w-8 p-0"
                      >
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                )}
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>

      <Dialog 
        open={!!selectedComment} 
        onOpenChange={() => {
          setSelectedComment(null);
          setSelectedIndex(-1);
        }}
      >
        <DialogContent className="max-w-2xl h-[500px] p-6 flex flex-col">
          {selectedComment && (
            <>
              {/* Header */}
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  {selectedComment.twitter_url ? (
                    <Twitter className="h-4 w-4 text-blue-400" />
                  ) : (
                    <img 
                      src="/redditlogo.png"
                      alt="Reddit"
                      className="h-4 w-4"
                    />
                  )}
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-medium text-gray-900">
                      {selectedComment.twitter_url 
                        ? `@${getTwitterAuthorFromUrl(selectedComment.twitter_url)}` 
                        : `r/${selectedComment.subreddit}`}
                    </p>
                    <span className="text-xs text-gray-500">
                      • {selectedComment.twitter_url ? 'Twitter' : `u/${selectedComment.author}`}
                    </span>
                    {selectedComment.score && (
                      <span className="text-[10px] bg-orange-50 text-orange-600 px-1.5 py-0.5 rounded">
                        {selectedComment.score}
                      </span>
                    )}
                  </div>
                </div>
                <span className="text-xs text-gray-400">
                  {getFormattedDate(selectedComment)}
                </span>
              </div>

              {/* Scrollable Content */}
              <div className="flex-1 min-h-0 border rounded-lg bg-gray-50/50">
                <ScrollArea className="h-full">
                  <div className="p-4">
                    <p className="text-sm text-gray-600 whitespace-pre-wrap">
                      {selectedComment.body || selectedComment.text}
                    </p>
                  </div>
                </ScrollArea>
              </div>

              {/* Footer */}
              <div className="flex items-center justify-between mt-4 pt-4 border-t">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handlePrevious}
                  disabled={selectedIndex <= 0 && currentPage === 1}
                  className="text-xs gap-1"
                >
                  <ChevronLeft className="h-4 w-4" />
                  Previous
                </Button>
                <a 
                  href={selectedComment.permalink || selectedComment.twitter_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xs text-blue-600 hover:text-blue-700 font-medium"
                >
                  View on {selectedComment.twitter_url ? 'Twitter' : 'Reddit'} →
                </a>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleNext}
                  disabled={selectedIndex >= currentComments.length - 1 && currentPage === totalPages}
                  className="text-xs gap-1"
                >
                  Next
                  <ChevronRight className="h-4 w-4" />
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Search;
