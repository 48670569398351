import { TableHead, TableHeader, TableRow, TableCell } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";

interface TableHeaderComponentProps {
  areAllSelected: boolean;
  onSelectAll: (checked: boolean) => void;
  selectedCount: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  sortOrder: 'asc' | 'desc';
  onSortChange: (order: 'asc' | 'desc') => void;
  sentimentSort?: 'asc' | 'desc' | null;
  onSentimentSortChange?: (order: 'asc' | 'desc' | null) => void;
}

export const TableHeaderComponent = ({
  areAllSelected,
  onSelectAll,
  selectedCount,
  currentPage,
  totalPages,
  onPageChange,
  sortOrder,
  onSortChange,
  sentimentSort,
  onSentimentSortChange,
}: TableHeaderComponentProps) => {
  return (
    <TableHeader>
      <TableRow>
        <TableHead className="w-[40px] bg-transparent">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Checkbox
                    checked={areAllSelected}
                    onCheckedChange={onSelectAll}
                    aria-label="Select all"
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-xs">Select all comments</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </TableHead>
        <TableHead className="w-[100px] font-medium text-gray-500 text-xs bg-transparent">Author</TableHead>
        <TableHead className="w-[300px] font-medium text-gray-500 text-xs bg-transparent">Comment</TableHead>
        <TableHead className="w-[100px] font-medium text-gray-500 text-xs bg-transparent">Matched</TableHead>
        <TableHead className="w-[100px] font-medium text-gray-500 text-xs bg-transparent">
          <div className="flex items-center gap-2">
            <span>Sentiment</span>
            <button 
              onClick={() => {
                if (!sentimentSort) onSentimentSortChange?.('desc');
                else if (sentimentSort === 'desc') onSentimentSortChange?.('asc');
                else onSentimentSortChange?.(null);
              }}
              className="text-gray-400 hover:text-gray-600"
            >
              {sentimentSort === 'desc' && <ArrowDown className="h-3 w-3" />}
              {sentimentSort === 'asc' && <ArrowUp className="h-3 w-3" />}
              {!sentimentSort && <ArrowUpDown className="h-3 w-3" />}
            </button>
          </div>
        </TableHead>
        <TableHead className="w-[200px] font-medium text-gray-500 text-xs bg-transparent">Reasoning</TableHead>
        <TableHead className="w-[140px] font-medium text-gray-500 text-xs bg-transparent whitespace-nowrap">Posted</TableHead>
        <TableHead className="w-[60px] text-center font-medium text-gray-500 text-xs bg-transparent">Likes</TableHead>
        <TableHead className="w-[60px] font-medium text-gray-500 text-xs bg-transparent">Link</TableHead>
      </TableRow>
    </TableHeader>
  );
};