import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { format } from 'date-fns';
import { Brain, ChevronLeft, ChevronRight, HelpCircle, Twitter } from "lucide-react";
import { Link } from "react-router-dom";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { ScrollArea } from "@/components/ui/scroll-area";

interface BaseMessage {
  id: string;
  keyword: string;
  author: string;
  created_at: string;
  sentiment: string;
  sentiment_reasoning: string | null;
  permalink: string;
}

interface RedditMessage extends BaseMessage {
  type: 'reddit';
  body: string;
  subreddit: string;
  comment_created_at: string;
}

interface TwitterMessage extends BaseMessage {
  type: 'twitter';
  text: string;
  twitter_url: string;
  retweet_count?: number;
  like_count?: number;
}

type Message = RedditMessage | TwitterMessage;

interface MessageDetailProps {
  message: Message | null;
  onNext?: () => void;
  onPrevious?: () => void;
  hasNext?: boolean;
  hasPrevious?: boolean;
}

export const MessageDetail = ({ message, onNext, onPrevious, hasNext, hasPrevious }: MessageDetailProps) => {
  if (!message) {
    return (
      <div className="h-full flex items-center justify-center text-xs text-gray-500">
        Select a message to view details
      </div>
    );
  }

  const getSentimentStyles = (sentiment: string) => {
    if (sentiment === 'High') {
      return 'bg-emerald-50/60 text-emerald-700 border-2 border-emerald-200';
    }
    return 'bg-yellow-50/60 text-yellow-700 border-2 border-yellow-200';
  };

  // Get the first character of the author name, with null check
  const authorInitial = message.author ? message.author.charAt(0).toUpperCase() : '?';

  return (
    <div className="max-w-2xl mx-auto h-full flex flex-col">
      <div className="flex items-center gap-4 mb-6 flex-shrink-0">
        <div className="h-8 w-8 rounded-full bg-emerald-100 flex items-center justify-center">
          <span className="text-emerald-700 font-medium text-xs">
            {authorInitial}
          </span>
        </div>
        <div>
          <h2 className="text-sm font-medium text-gray-900">
            {message.type === 'reddit' 
              ? `New mention from r/${message.subreddit}`
              : 'New mention on X'}
          </h2>
          <div className="flex items-center gap-2 text-xs text-gray-500 mt-1">
            <span>{format(new Date(message.created_at), 'MMM d, yyyy')}</span>
            <span>•</span>
            <Badge 
              variant="secondary" 
              className={getSentimentStyles(message.sentiment)}
            >
              {message.sentiment} Sentiment
            </Badge>
          </div>
        </div>
        {message.type === 'reddit' ? (
          <img 
            src="/redditlogo.png" 
            alt="Reddit"
            className="h-5 w-5 opacity-10 ml-auto"
          />
        ) : (
          <Twitter className="h-5 w-5 text-blue-400 opacity-10 ml-auto" />
        )}
      </div>

      <ScrollArea className="flex-1 -mx-2 px-2">
        <div className="bg-white rounded-lg p-8 shadow-sm border border-gray-100">
          <p className="text-xs text-gray-600 leading-relaxed whitespace-pre-wrap">
            {message.type === 'reddit' ? message.body : message.text}
          </p>
          
          {message.sentiment_reasoning && (
            <div className="mt-6 bg-blue-50/50 rounded-lg border border-blue-100 p-4">
              <div className="flex items-start gap-3">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="cursor-help">
                        <Brain className="h-4 w-4 text-blue-600 mt-0.5" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p className="text-xs max-w-[300px]">{message.sentiment_reasoning}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <p className="text-xs text-gray-600 leading-relaxed flex-1">
                  {message.sentiment_reasoning}
                </p>
              </div>
            </div>
          )}

          <div className="mt-4 flex items-center gap-1">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center gap-1 text-xs text-gray-500 hover:text-gray-700 cursor-help transition-colors">
                    <HelpCircle className="h-4 w-4" />
                    <span>What is this?</span>
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-[300px]">
                  <div className="space-y-2">
                    <p className="text-xs">How sentiment is determined:</p>
                    <p className="text-xs text-gray-500">Our AI analyzes each mention using your provided context about your product, customers, and website, along with the content and tone of the message.</p>
                    <p className="text-xs text-gray-500">This helps provide more accurate and contextual sentiment analysis specific to your business.</p>
                    <div className="mt-4">
                      <Link to="/docs" className="text-xs hover:underline">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <div className="mt-6 pt-6 border-t flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Badge 
                variant="secondary" 
                className="bg-blue-50/60 text-blue-700 border-2 border-blue-200"
              >
                {message.keyword}
              </Badge>
              <Badge 
                variant="secondary" 
                className="bg-emerald-50/60 text-emerald-700 border-2 border-emerald-200"
              >
                {message.author}
              </Badge>
            </div>
            <a 
              href={message.type === 'reddit' 
                ? (message.permalink.startsWith('http') ? message.permalink : `https://reddit.com${message.permalink}`)
                : message.twitter_url
              } 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-xs text-blue-600 hover:text-blue-800"
            >
              View on {message.type === 'reddit' ? 'Reddit' : 'X'}
            </a>
          </div>

          {message.type === 'twitter' && message.retweet_count !== undefined && message.like_count !== undefined && (
            <div className="flex items-center justify-between text-xs text-gray-500 pt-4 mt-4 border-t">
              <span>Retweets: {message.retweet_count}</span>
              <span>Likes: {message.like_count}</span>
            </div>
          )}
        </div>
      </ScrollArea>

      <div className="flex justify-between mt-20 flex-shrink-0 pb-6">
        <Button
          variant="outline"
          size="sm"
          onClick={onPrevious}
          disabled={!hasPrevious}
          className="text-xs gap-1"
        >
          <ChevronLeft className="h-4 w-4" />
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={onNext}
          disabled={!hasNext}
          className="text-xs gap-1"
        >
          Next
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};