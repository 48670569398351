import { useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { formatDistanceToNow } from "date-fns";
import { MoreHorizontal, ChevronLeft, ChevronRight, Brain, ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { TableHeaderComponent } from "./TableHeader";
import { FeedItem } from "@/hooks/useFeedData";
import { ScrollArea } from "@/components/ui/scroll-area";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { ApifyResult } from "@/hooks/useApifyResults";

interface CommentTableProps {
  comments: FeedItem[];
  currentPage: number;
  onPageChange: (page: number) => void;
  selectedComments: string[];
  onCommentSelect: (commentIds: string[]) => void;
  sortOrder: 'asc' | 'desc';
  onSortChange: (order: 'asc' | 'desc') => void;
  platformFilter: 'all' | 'reddit' | 'twitter';
  onPlatformChange: (platform: 'all' | 'reddit' | 'twitter') => void;
  sentimentSort?: 'asc' | 'desc' | null;
  onSentimentSortChange?: (order: 'asc' | 'desc' | null) => void;
}

export const CommentTable = ({ 
  comments, 
  currentPage, 
  onPageChange,
  selectedComments,
  onCommentSelect,
  sortOrder,
  onSortChange,
  platformFilter,
  onPlatformChange,
  sentimentSort,
  onSentimentSortChange,
}: CommentTableProps) => {
  const [selectedComment, setSelectedComment] = useState<FeedItem | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const { toast } = useToast();
  const { user } = useAuth();
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  
  // Filter out AutoModerator comments
  const filteredComments = comments.filter(comment => comment.author !== 'AutoModerator');
  const currentComments = filteredComments.slice(startIndex, endIndex);

  // Add console logs at the component level
  console.log("All comments data:", comments.map(comment => ({
    id: comment.comment_id,
    sentiment: (comment as ApifyResult).sentiment,
    sentiment_reasoning: (comment as ApifyResult).sentiment_reasoning
  })));

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const currentPageCommentIds = currentComments.map(comment => comment.comment_id);
      onCommentSelect([...selectedComments, ...currentPageCommentIds.filter(id => !selectedComments.includes(id))]);
    } else {
      const currentPageCommentIds = currentComments.map(comment => comment.comment_id);
      onCommentSelect(selectedComments.filter(id => !currentPageCommentIds.includes(id)));
    }
  };

  const handleSelectComment = (commentId: string, checked: boolean) => {
    if (checked) {
      onCommentSelect([...selectedComments, commentId]);
    } else {
      onCommentSelect(selectedComments.filter(id => id !== commentId));
    }
  };

  const handleCommentClick = (comment: FeedItem, index: number) => {
    setSelectedComment(comment);
    setSelectedIndex(index);
  };

  const handleNext = () => {
    if (selectedIndex < currentComments.length - 1) {
      const nextComment = currentComments[selectedIndex + 1];
      setSelectedComment(nextComment);
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      const prevComment = currentComments[selectedIndex - 1];
      setSelectedComment(prevComment);
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const formatLink = (item: FeedItem | ApifyResult) => {
    if (item.source === 'twitter') {
      return item.permalink;
    }
    // Remove any potential duplicate reddit.com in the URL
    const url = item.permalink;
    if (url.startsWith('https://reddit.com')) {
      return url;
    }
    if (url.startsWith('https://www.reddit.com')) {
      return url;
    }
    if (url.startsWith('/r/')) {
      return `https://reddit.com${url}`;
    }
    return `https://reddit.com${url}`;
  };

  const isSelected = (commentId: string) => selectedComments.includes(commentId);
  const areAllSelected = currentComments.length > 0 && 
    currentComments.every(comment => selectedComments.includes(comment.comment_id));

  const handleSaveComment = async () => {
    if (!selectedComment || !user) return;

    try {
      const { error } = await supabase
        .from('saved_comments')
        .insert([{
          user_id: user.id,
          comment_id: selectedComment.comment_id,
          author: selectedComment.author,
          body: selectedComment.body,
          created_utc: selectedComment.created_utc,
          score: selectedComment.score,
          permalink: selectedComment.permalink
        }]);

      if (error && error.code !== '23505') { // Ignore duplicate key errors
        throw error;
      }

      toast({
        title: "Comment saved",
        description: "The comment has been added to your saved comments.",
      });
    } catch (error) {
      console.error('Error saving comment:', error);
      toast({
        variant: "destructive",
        title: "Error saving comment",
        description: "There was an error saving your comment. Please try again.",
      });
    }
  };

  return (
    <>
      {/* Outer container that takes full width of its parent */}
      <div className="w-full mt-2">
        {/* Table scroll container with shadow indicators */}
        <div className="relative">
          <div className="overflow-x-auto border rounded-md">
            <Table className="w-[1200px]"> {/* Changed from w-[1300px] */}
              <TableHeaderComponent 
                areAllSelected={areAllSelected}
                onSelectAll={handleSelectAll}
                selectedCount={selectedComments.length}
                currentPage={currentPage}
                totalPages={Math.ceil(filteredComments.length / itemsPerPage)}
                onPageChange={onPageChange}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                sentimentSort={sentimentSort}
                onSentimentSortChange={onSentimentSortChange}
              />
              <TableBody>
                {currentComments.map((comment, index) => (
                  <TableRow 
                    key={comment.comment_id} 
                    className="group hover:bg-gray-50/50"
                  >
                    <TableCell className="w-[50px] border-0 py-3">
                      <Checkbox 
                        checked={isSelected(comment.comment_id)}
                        onCheckedChange={(checked) => handleSelectComment(comment.comment_id, checked as boolean)}
                      />
                    </TableCell>

                    <TableCell className="w-[150px] border-0 py-3 whitespace-nowrap">
                      <span className="px-2 py-1 text-xs rounded-full bg-blue-50 text-blue-700 border border-blue-200">
                        {comment.author}
                      </span>
                    </TableCell>

                    <TableCell className="w-[300px] border-0 py-3">
                      <div className="flex items-center gap-2">
                        <div 
                          className="flex-1 cursor-pointer hover:text-gray-900" 
                          onClick={() => handleCommentClick(comment, index)}
                        >
                          <div 
                            className="text-xs text-gray-600 whitespace-nowrap overflow-hidden text-ellipsis"
                            title={comment.text}
                          >
                            {comment.text?.length > 30 
                              ? `${comment.text.substring(0, 30)}...` 
                              : comment.text}
                          </div>
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="opacity-0 group-hover:opacity-100 h-6 w-6 p-0 flex-shrink-0"
                          onClick={() => handleCommentClick(comment, index)}
                        >
                          <MoreHorizontal className="h-3.5 w-3.5" />
                        </Button>
                      </div>
                    </TableCell>

                    <TableCell className="w-[120px] border-0 py-3 whitespace-nowrap">
                      <span className="px-2 py-1 text-xs rounded-full bg-blue-50 text-blue-700 border border-blue-200">
                        {(comment as ApifyResult).keyword}
                      </span>
                    </TableCell>

                    <TableCell className="w-[100px] border-0 py-3">
                      <span className={`px-2 py-1 text-xs rounded-full ${
                        (comment as ApifyResult).sentiment === 'High' 
                          ? 'bg-emerald-50 text-emerald-700 border border-emerald-200'
                          : (comment as ApifyResult).sentiment === 'Moderate'
                          ? 'bg-yellow-50 text-yellow-700 border border-yellow-200'
                          : 'bg-red-50 text-red-700 border border-red-200'
                      }`}>
                        {(comment as ApifyResult).sentiment || '-'}
                      </span>
                    </TableCell>

                    <TableCell className="w-[300px] border-0 py-3">
                      <div className="flex items-center gap-2">
                        <div 
                          className="flex-1 text-xs text-gray-600 whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer hover:text-gray-900"
                          onClick={() => handleCommentClick(comment, index)}
                          title={(comment as ApifyResult).sentiment_reasoning || '-'}
                        >
                          {(comment as ApifyResult).sentiment_reasoning?.length > 30 
                            ? `${(comment as ApifyResult).sentiment_reasoning.substring(0, 30)}...` 
                            : (comment as ApifyResult).sentiment_reasoning || '-'}
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="opacity-0 group-hover:opacity-100 h-6 w-6 p-0 flex-shrink-0"
                          onClick={() => handleCommentClick(comment, index)}
                        >
                          <MoreHorizontal className="h-3.5 w-3.5" />
                        </Button>
                      </div>
                    </TableCell>

                    <TableCell className="w-[120px] text-xs text-gray-500 border-0 py-3 whitespace-nowrap">
                      {formatDistanceToNow(new Date(comment.created_utc * 1000), { addSuffix: true })}
                    </TableCell>

                    <TableCell className="w-[80px] text-center border-0 py-3 whitespace-nowrap">
                      <span className="px-2 py-1 rounded-full text-xs">
                        {comment.score}
                      </span>
                    </TableCell>

                    <TableCell className="w-[100px] border-0 py-3 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs rounded-full ${
                        comment.source === 'reddit' 
                          ? 'bg-orange-50 text-orange-700 border border-orange-200'
                          : 'bg-blue-50 text-blue-700 border border-blue-200'
                      }`}>
                        {comment.source === 'reddit' ? 'Reddit' : 'Twitter'}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>

      <Dialog 
        open={!!selectedComment} 
        onOpenChange={() => {
          setSelectedComment(null);
          setSelectedIndex(-1);
        }}
      >
        <DialogContent className="max-w-xl h-[600px] flex flex-col overflow-hidden p-6">
          {/* Author header - fixed */}
          <div className="flex-none flex items-center justify-between pb-6">
            <div className="flex items-center gap-2">
              <span className="px-2 py-1 text-xs rounded-full bg-blue-50 text-blue-700 border border-blue-200">
                {selectedComment?.author}
              </span>
              <span className="text-xs text-gray-400">
                {selectedComment && formatDistanceToNow(new Date(selectedComment.created_utc * 1000), { addSuffix: true })}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="px-2 py-1 text-xs rounded-full bg-emerald-50 text-emerald-700 border border-emerald-200">
                {selectedComment?.score} points
              </span>
            </div>
          </div>

          {/* Scrollable content */}
          <ScrollArea className="flex-1 min-h-0">
            <div className="space-y-6">
              {/* Comment body */}
              <div className="text-sm text-gray-600 leading-relaxed">
                {selectedComment?.body}
              </div>
            </div>
          </ScrollArea>

          {/* Sentiment analysis - fixed at bottom */}
          {(selectedComment as ApifyResult)?.sentiment && (
            <div className="flex-none pt-6 border-t">
              <div className="flex items-center gap-3 mb-4">
                <span className={`px-2 py-1 text-xs rounded-full ${
                  (selectedComment as ApifyResult).sentiment === 'High' 
                    ? 'bg-emerald-50 text-emerald-700 border border-emerald-200'
                    : (selectedComment as ApifyResult).sentiment === 'Moderate'
                    ? 'bg-yellow-50 text-yellow-700 border border-yellow-200'
                    : 'bg-red-50 text-red-700 border border-red-200'
                }`}>
                  {(selectedComment as ApifyResult).sentiment} Sentiment
                </span>
              </div>

              {(selectedComment as ApifyResult)?.sentiment_reasoning && (
                <div className="bg-gray-50 border border-gray-100 rounded-lg p-4">
                  <div className="flex gap-3">
                    <Brain className="h-4 w-4 text-blue-500 mt-0.5 flex-shrink-0" />
                    <div className="text-xs text-gray-600 leading-relaxed">
                      {(selectedComment as ApifyResult).sentiment_reasoning}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Footer actions - fixed */}
          <div className="flex-none flex items-center justify-between pt-6 border-t mt-6">
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={handleSaveComment}
                className="h-8 text-xs px-3 rounded-full hover:bg-gray-50"
              >
                Save Comment
              </Button>
              <Button
                variant="outline"
                size="sm"
                className={`h-8 text-xs px-3 rounded-full ${
                  selectedComment?.source === 'twitter' 
                    ? 'hover:bg-gray-50' 
                    : 'bg-red-50/50 text-red-700 border border-red-200 hover:bg-red-50'
                }`}
                onClick={() => selectedComment && window.open(formatLink(selectedComment), '_blank')}
              >
                View on {selectedComment?.source === 'twitter' ? 'Twitter' : 'Reddit'}
              </Button>
            </div>

            <div className="flex items-center gap-2">
              <Button
                variant="ghost"
                size="sm"
                onClick={handlePrevious}
                disabled={selectedIndex <= 0}
                className="h-8 text-xs"
              >
                <ChevronLeft className="h-4 w-4 mr-1" />
                Previous
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleNext}
                disabled={selectedIndex >= currentComments.length - 1}
                className="h-8 text-xs"
              >
                Next
                <ChevronRight className="h-4 w-4 ml-1" />
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
