import { useState } from "react";
import { Bug } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { ContextField } from "./context/ContextField";

interface BugReportModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const BugReportModal = ({ open, onOpenChange }: BugReportModalProps) => {
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const { user } = useAuth();

  const handleSubmit = async () => {
    if (!user?.email) return;
    
    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from('bug_reports')
        .insert([
          {
            user_id: user.id,
            user_email: user.email,
            location,
            description
          }
        ]);

      if (error) throw error;

      toast({
        title: "Bug report submitted",
        description: "Thank you for helping us improve the app!"
      });

      onOpenChange(false);
      setLocation("");
      setDescription("");
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error submitting bug report",
        description: error.message
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-xl">
            <Bug className="h-5 w-5" />
            Found a bug?
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 pt-4">
          <div className="text-sm text-gray-600">
            Help us improve by reporting any issues you encounter. We'll look into it right away!
          </div>

          <ContextField
            label="Where in the app did you find this bug?"
            value={location}
            onChange={setLocation}
            placeholder="e.g., Dashboard, Settings page, etc."
            minLength={5}
          />

          <ContextField
            label="What happened exactly?"
            value={description}
            onChange={setDescription}
            placeholder="Please describe what you were doing and what went wrong..."
            multiline
            minLength={5}
          />

          <div className="flex justify-end">
            <Button 
              onClick={handleSubmit}
              disabled={
                isSubmitting || 
                location.length < 5 || 
                description.length < 5
              }
            >
              {isSubmitting ? "Submitting..." : "Submit Report"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};