import { useEffect } from "react";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";

const LoadingCheckout = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const createCheckoutSession = async () => {
      try {
        console.log('Creating checkout session for trial...');
        const { data, error } = await supabase.functions.invoke('create-checkout', {
          body: { trial: true },
        });
        
        if (error) {
          console.error('Checkout error:', error);
          toast({
            variant: "destructive",
            title: "Error creating checkout session",
            description: error.message
          });
          navigate('/auth');
          return;
        }

        if (data?.url) {
          console.log('Redirecting to checkout:', data.url);
          window.location.href = data.url;
        }
      } catch (error: any) {
        console.error('Error:', error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to create checkout session"
        });
        navigate('/auth');
      }
    };

    createCheckoutSession();
  }, [navigate, toast]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-50 to-white">
      <div className="flex flex-col items-center gap-4 max-w-md text-center px-4">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
        <h2 className="text-xl font-semibold text-gray-700">Setting up your trial</h2>
        <p className="text-gray-500 text-sm">
          Please wait while we prepare your checkout session. You'll be redirected to complete your trial signup.
        </p>
      </div>
    </div>
  );
};

export default LoadingCheckout;