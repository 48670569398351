import { Button } from "@/components/ui/button";
import { ContextField } from "../context/ContextField";
import { Loader2, Plus } from "lucide-react";

interface KeywordInputProps {
  value: string;
  onChange: (value: string) => void;
  onAdd: () => void;
  isAdding: boolean;
}

export const KeywordInput = ({ value, onChange, onAdd, isAdding }: KeywordInputProps) => {
  return (
    <div className="space-y-6">
      <ContextField
        label="Add a new keyword"
        value={value}
        onChange={onChange}
        onEnter={onAdd}
        placeholder="Enter keyword"
        isComplete={isAdding}
      />

      <div className="flex justify-end">
        <Button onClick={onAdd} disabled={isAdding || !value.trim()}>
          {isAdding ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Adding...
            </>
          ) : (
            'Add Keyword'
          )}
        </Button>
      </div>
    </div>
  );
};