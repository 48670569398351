import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { useEffect } from "react";
import { useToast } from "@/hooks/use-toast";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, loading, signOut } = useAuth();
  const { toast } = useToast();
  const location = useLocation();
  const isLoadingCheckout = location.pathname === '/loading-checkout';
  
  const { data: subscriptionStatus, isLoading: checkingSubscription, error: subscriptionError } = useQuery({
    queryKey: ['subscription', user?.id],
    queryFn: async () => {
      if (!user) return null;
      console.log('Checking subscription status for user:', user.id);
      
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) {
        console.error('No active session found');
        await signOut();
        return null;
      }

      try {
        const { data, error } = await supabase.functions.invoke('check-subscription', {
          headers: {
            Authorization: `Bearer ${session.access_token}`
          }
        });
        
        if (error) {
          console.error('Error checking subscription:', error);
          if (error.message?.includes('JWT')) {
            await signOut();
            return null;
          }
          throw error;
        }
        
        console.log('Subscription status:', data);
        return data;
      } catch (error) {
        console.error('Subscription check failed:', error);
        toast({
          variant: "destructive",
          title: "Error checking subscription",
          description: "Please try logging in again"
        });
        return null;
      }
    },
    enabled: !!user,
    retry: 1, // Only retry once to avoid infinite loops with auth issues
    retryDelay: 1000 // Wait 1 second before retrying
  });

  useEffect(() => {
    if (subscriptionStatus && !subscriptionStatus.hasAccess && !isLoadingCheckout) {
      toast({
        title: "Subscription required",
        description: "Please subscribe to access this feature",
        variant: "destructive"
      });
    }
  }, [subscriptionStatus, toast, isLoadingCheckout]);
  
  // Don't render anything while checking auth status
  if (loading || checkingSubscription) {
    return null;
  }
  
  // If no user, redirect to auth page with a return URL
  if (!user) {
    return <Navigate to="/auth" replace state={{ returnUrl: location.pathname }} />;
  }

  // Allow access to loading-checkout even with incomplete subscription
  if (isLoadingCheckout) {
    return <>{children}</>;
  }

  // If subscription check failed, redirect to auth
  if (subscriptionError) {
    return <Navigate to="/auth" replace />;
  }

  // If no subscription access, redirect to auth with subscription required flag
  if (subscriptionStatus && !subscriptionStatus.hasAccess) {
    return <Navigate to="/auth" replace state={{ subscriptionRequired: true }} />;
  }

  return <>{children}</>;
};