import { Globe, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import Sidebar from "@/components/Sidebar";
import NotificationPanel from "@/components/NotificationPanel";
import { useAuth } from "@/contexts/AuthContext";
import { useState } from "react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDomains } from "@/hooks/useDomains";
import { DomainGrid } from "@/components/DomainGrid";
import { AddDomainButton } from "@/components/AddDomainButton";

const Competitors = () => {
  const { user } = useAuth();
  const [newDomain, setNewDomain] = useState("");
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { data: domains = [] } = useDomains();

  const addDomainMutation = useMutation({
    mutationFn: async (domain: string) => {
      console.log('Adding new domain:', domain);
      const { data, error } = await supabase
        .from('domain_cards')
        .insert([
          {
            text: domain.trim(),
            domain: domain.trim(),
            user_id: user?.id,
            completed: false
          }
        ])
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domains', user?.id] });
      setNewDomain("");
      toast({
        title: "Domain added",
        description: "Your domain has been added successfully"
      });
    },
    onError: (error: any) => {
      toast({
        variant: "destructive",
        title: "Error adding domain",
        description: error.message
      });
    }
  });

  const deleteDomainMutation = useMutation({
    mutationFn: async (id: number) => {
      console.log('Deleting domain:', id);
      const { error } = await supabase
        .from('domain_cards')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domains', user?.id] });
      toast({
        title: "Domain deleted",
        description: "Domain has been removed successfully"
      });
    },
    onError: (error: any) => {
      toast({
        variant: "destructive",
        title: "Error deleting domain",
        description: error.message
      });
    }
  });

  const handleAddDomain = () => {
    if (!newDomain.trim() || !user) return;
    addDomainMutation.mutate(newDomain);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddDomain();
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white">
      <Sidebar />
      
      <div className="flex-1 flex flex-col min-w-0">
        <div className="sticky top-0 z-10 backdrop-blur-sm bg-white/80 p-6 pb-4 border-b">
          <div className="max-w-[1200px] mx-auto">
            <div className="flex items-center justify-between mb-2">
              <div>
                <h1 className="text-xl font-semibold text-gray-900">Competitors</h1>
                <p className="text-xs text-gray-500 mt-1">Overview of your competitors</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6 pt-2">
          <div className="max-w-[1200px] mx-auto">
            {/* Content will go here */}
          </div>
        </div>
      </div>

      <NotificationPanel />
    </div>
  );
};

export default Competitors;
