import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";

export interface ApifyResult {
  id: string;
  user_id: string;
  keyword: string;
  body?: string;  // for Reddit
  text?: string;  // for Twitter
  score: number;
  author: string;
  created_at: string;
  created_utc: number;  // Add this for compatibility
  comment_id: string;   // Add this for compatibility
  sentiment: string;
  sentiment_reasoning: string | null;
  permalink?: string;  // for Reddit
  url?: string;       // for Twitter
  source: 'reddit' | 'twitter';
}

// Transform functions to ensure data consistency
const transformRedditData = (item: any): ApifyResult => ({
  id: item.id,
  user_id: item.user_id,
  keyword: item.keyword,
  body: item.body,
  score: item.score,
  author: item.author,
  created_at: item.created_at,
  created_utc: new Date(item.created_at).getTime() / 1000,
  comment_id: item.id,
  sentiment: item.sentiment,
  sentiment_reasoning: item.sentiment_reasoning,
  permalink: item.permalink,
  source: 'reddit'
});

const transformTwitterData = (item: any): ApifyResult => ({
  id: item.id,
  user_id: item.user_id,
  keyword: item.keyword,
  text: item.text,
  body: item.text, // Use text as body for consistency
  score: item.score,
  author: item.author,
  created_at: item.created_at,
  created_utc: new Date(item.created_at).getTime() / 1000,
  comment_id: item.id,
  sentiment: item.sentiment,
  sentiment_reasoning: item.sentiment_reasoning,
  url: item.url,
  source: 'twitter'
});

export const useApifyResults = () => {
  const { user } = useAuth();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const { data: comments = [], isLoading } = useQuery({
    queryKey: ['apify_results', user?.id],
    queryFn: async () => {
      // Add logging to debug
      console.log('Fetching data for user:', user?.id);

      // Fetch Reddit results
      const { data: redditData, error: redditError } = await supabase
        .from('apify_reddit_results')
        .select('*')
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false });

      if (redditError) {
        console.error('Reddit fetch error:', redditError);
        throw redditError;
      }
      console.log('Reddit results:', redditData?.length);

      // Fetch Twitter results with debug
      console.log('Fetching Twitter data for user:', user?.id);

      const { data: twitterData, error: twitterError } = await supabase
        .from('apify_twitter_results')
        .select('*')
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false });

      if (twitterError) {
        console.error('Twitter fetch error:', twitterError);
        // Log more details about the error
        console.error('Error details:', {
          code: twitterError.code,
          message: twitterError.message,
          details: twitterError.details,
          hint: twitterError.hint
        });
        throw twitterError;
      }

      console.log('Raw Twitter response:', {
        data: twitterData,
        error: twitterError,
        count: twitterData?.length
      });

      // Transform Reddit data
      const redditResults = (redditData || []).map(item => ({
        id: item.id,
        user_id: item.user_id,
        keyword: item.keyword,
        body: item.text,
        text: item.text,
        score: item.score,
        author: item.author,
        created_at: item.created_at,
        created_utc: new Date(item.created_at).getTime() / 1000,
        comment_id: item.id,
        sentiment: item.sentiment || '',
        sentiment_reasoning: item.sentiment_reasoning,
        permalink: item.url,
        source: 'reddit' as const
      }));

      // Add debug log for first transformed result
      console.log('First transformed Reddit result:', redditResults[0]);

      // Transform Twitter data
      const twitterResults = (twitterData || []).map(item => ({
        id: item.id,
        user_id: item.user_id,
        keyword: item.keyword,
        text: item.text,
        body: item.text,
        score: item.score || 0,
        author: item.author,
        created_at: item.created_at,
        created_utc: new Date(item.created_at).getTime() / 1000,
        comment_id: item.id,
        sentiment: item.sentiment || '',
        sentiment_reasoning: item.sentiment_reasoning,
        permalink: item.url,
        source: 'twitter' as const
      }));

      console.log('Transformed Reddit results:', redditResults.length);
      console.log('Transformed Twitter results:', twitterResults.length);

      // Combine and sort all results
      const allResults = [...redditResults, ...twitterResults].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return sortOrder === 'desc' ? 
          dateB.getTime() - dateA.getTime() : 
          dateA.getTime() - dateB.getTime();
      });

      console.log('Total combined results:', allResults.length);
      return allResults;
    },
    enabled: !!user?.id
  });

  return { comments, isLoading, sortOrder, setSortOrder };
};