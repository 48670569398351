import { Twitter, MessageSquareQuote, Youtube, Plus, AlertTriangle, CheckCircle, Flame, ArrowDownLeft } from "lucide-react";
import { useEffect, useState } from "react";
import Sidebar from "@/components/Sidebar";
import NotificationPanel from "@/components/NotificationPanel";
import { ContextModal } from "@/components/context/ContextModal";
import { KeywordManagement } from "@/components/social/KeywordManagement";
import { SocialPlatformCard } from "@/components/dashboard/SocialPlatformCard";
import { TutorialVideo } from "@/components/dashboard/TutorialVideo";
import { ReleasesTable } from "@/components/dashboard/ReleasesTable";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { AgentModal } from "@/components/playground/AgentModal";

const Dashboard = () => {
  const [isContextModalOpen, setIsContextModalOpen] = useState(false);
  const [isKeywordModalOpen, setIsKeywordModalOpen] = useState(false);
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false);
  const [hasKeywords, setHasKeywords] = useState(false);
  const [hasContext, setHasContext] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      checkKeywords();
      checkContext();
    }
  }, [user]);

  // Add subscription to context changes
  useEffect(() => {
    if (!user?.id) return;

    const contextSubscription = supabase
      .channel('context_changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'user_context',
        filter: `user_id=eq.${user.id}`
      }, () => {
        checkContext();
      })
      .subscribe();

    const keywordSubscription = supabase
      .channel('keyword_changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'subreddit_keywords',
        filter: `user_id=eq.${user.id}`
      }, () => {
        checkKeywords();
      })
      .subscribe();

    return () => {
      contextSubscription.unsubscribe();
      keywordSubscription.unsubscribe();
    };
  }, [user?.id]);

  const checkKeywords = async () => {
    try {
      const { data } = await supabase
        .from('subreddit_keywords')
        .select('id')
        .eq('user_id', user?.id)
        .limit(1);
      setHasKeywords(!!data?.length);
    } catch (error) {
      console.error('Error checking keywords:', error);
    }
  };

  const checkContext = async () => {
    try {
      const { data } = await supabase
        .from('user_context')
        .select('id')
        .eq('user_id', user?.id)
        .limit(1);
      setHasContext(!!data?.length);
    } catch (error) {
      console.error('Error checking context:', error);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white">
      <Sidebar />
      
      <div className="flex-1 flex flex-col min-w-0">
        <div className="sticky top-0 z-10 backdrop-blur-sm bg-white/80 p-6 pb-4 border-b">
          <div className="max-w-[1200px] mx-auto">
            <div className="flex items-center justify-between mb-2">
              <div>
                <h1 className="text-xl font-semibold text-gray-900">Dashboard</h1>
                <p className="text-xs text-gray-500 mt-1">Overview of your monitoring activity</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          <div className="max-w-[1200px] mx-auto space-y-8">
            <div>
              <TutorialVideo />
              
              <div className="grid grid-cols-2 gap-4 mt-6">
                <Card 
                  onClick={() => setIsAgentModalOpen(true)}
                  className="cursor-pointer hover:bg-gray-50/50 transition-colors border border-emerald-400/40 hover:border-emerald-500 relative"
                >
                  <div className="absolute -top-2 -right-2 bg-[#eefcf5] text-emerald-800 px-2 py-0.5 rounded-md text-xs font-medium flex items-center gap-1 border border-emerald-400/40 animate-pulse-border">
                    <ArrowDownLeft className="w-3 h-3 animate-bounce" /> Start Here
                  </div>
                  <CardHeader className="pb-3">
                    <CardTitle className="text-sm font-medium flex items-center gap-2">
                      {hasKeywords ? (
                        <CheckCircle className="w-4 h-4 text-green-500" />
                      ) : (
                        <Flame className="w-4 h-4 text-emerald-600" />
                      )}
                      Launch Social Agent
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-xs text-muted-foreground">
                      Start monitoring social conversations by setting up your AI agent. Add keywords and business context to find relevant discussions.
                    </p>
                    {!hasKeywords && (
                      <div className="mt-3 flex items-center gap-2 text-amber-600 bg-amber-50 rounded-md p-2">
                        <AlertTriangle className="h-4 w-4" />
                        <span className="text-xs">No keywords added yet</span>
                      </div>
                    )}
                  </CardContent>
                </Card>

                <Card 
                  className="cursor-pointer hover:bg-gray-50/50 transition-colors"
                  onClick={() => setIsContextModalOpen(true)}
                >
                  <CardHeader className="pb-3">
                    <CardTitle className="text-sm font-medium flex items-center gap-2">
                      {hasContext ? (
                        <CheckCircle className="w-4 h-4 text-green-500" />
                      ) : (
                        <Plus className="w-4 h-4" />
                      )}
                      Add Context
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-xs text-muted-foreground">
                      Add business context to help our AI better understand your needs. Describe your domain, offer, and target customers.
                    </p>
                    {!hasContext && (
                      <div className="mt-3 flex items-center gap-2 text-amber-600 bg-amber-50 rounded-md p-2">
                        <AlertTriangle className="h-4 w-4" />
                        <span className="text-xs">No context added yet</span>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <SocialPlatformCard
                icon={Twitter}
                title="X (Twitter) Monitoring"
                description="Track conversations, mentions, and engagement on X. Monitor brand sentiment and competitor activity in real-time."
              />
              <SocialPlatformCard
                icon={MessageSquareQuote}
                title="Reddit Discussions"
                description="Follow subreddit conversations, track mentions, and analyze community sentiment about your brand or industry."
              />
              <Card className="p-6 relative opacity-60">
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-sm font-medium text-gray-500">YouTube Analytics</h3>
                  </div>
                  <Youtube className="h-4 w-4 text-gray-400 ml-2" />
                </div>
                <p className="text-xs text-gray-400 leading-relaxed">
                  Monitor comments, track video mentions, and analyze audience engagement across YouTube channels.
                </p>
              </Card>
            </div>

            <ReleasesTable />
          </div>
        </div>
      </div>

      <NotificationPanel />
      
      {isContextModalOpen && (
        <ContextModal 
          isOpen={isContextModalOpen} 
          onClose={() => setIsContextModalOpen(false)} 
        />
      )}
      {isKeywordModalOpen && (
        <KeywordManagement 
          isOpen={isKeywordModalOpen} 
          onClose={() => setIsKeywordModalOpen(false)} 
        />
      )}
      {isAgentModalOpen && (
        <AgentModal 
          isOpen={isAgentModalOpen} 
          onClose={() => setIsAgentModalOpen(false)} 
        />
      )}
    </div>
  );
};

export default Dashboard;
