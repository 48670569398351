import { useState, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Search, Plus, X } from "lucide-react";
import { Badge } from "@/components/ui/badge";

interface FeedKeywordFilterProps {
  onKeywordAdd: (keyword: string) => void;
  selectedKeywords?: string[];
  onKeywordRemove?: (keyword: string) => void;
  onSearch?: (keywords: string[]) => void;
}

export const FeedKeywordFilter = ({ 
  onKeywordAdd, 
  selectedKeywords = [], 
  onKeywordRemove,
  onSearch
}: FeedKeywordFilterProps) => {
  const [searchInput, setSearchInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (onSearch) {
      onSearch(selectedKeywords);
    }
  }, [selectedKeywords, onSearch]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && searchInput.trim()) {
      handleAddKeyword();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Backspace' && !searchInput && selectedKeywords.length > 0 && onKeywordRemove) {
      onKeywordRemove(selectedKeywords[selectedKeywords.length - 1]);
    }
  };

  const handleAddKeyword = () => {
    if (searchInput.trim()) {
      onKeywordAdd(searchInput.trim());
      setSearchInput("");
    }
  };

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <div className="w-[400px] flex-shrink-0">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
            <div 
              className="flex items-center pl-9 pr-3 h-[34px] rounded-md border border-input bg-background cursor-text overflow-hidden"
              onClick={handleContainerClick}
            >
              <div 
                className="flex items-center gap-1.5 overflow-x-auto scrollbar-none"
                style={{ width: '100%' }}
              >
                <div className="flex items-center gap-1.5 flex-nowrap">
                  {selectedKeywords.map((keyword) => (
                    <Badge 
                      key={keyword}
                      variant="secondary" 
                      className="flex-shrink-0 whitespace-nowrap px-1.5 py-0.5 text-xs leading-none flex items-center gap-1 bg-[#FEF7CD] text-yellow-800 border border-[#F8E89C] hover:bg-[#FEF7CD]"
                    >
                      {keyword}
                      {onKeywordRemove && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onKeywordRemove(keyword);
                          }}
                          className="text-yellow-900 ml-0.5"
                        >
                          <X className="h-2.5 w-2.5" />
                        </button>
                      )}
                    </Badge>
                  ))}
                </div>
                <input
                  ref={inputRef}
                  type="text"
                  placeholder={selectedKeywords.length === 0 ? "Filter by keywords..." : ""}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  onKeyDown={handleKeyDown}
                  className="flex-shrink min-w-[80px] max-w-[140px] border-0 bg-transparent p-0 pl-1 text-sm focus:outline-none focus:ring-0 truncate"
                />
              </div>
            </div>
          </div>
        </div>
        <Button 
          onClick={handleAddKeyword}
          variant="outline"
          size="sm"
          className="h-9 px-3 text-xs flex items-center gap-1.5 flex-shrink-0"
        >
          <Plus className="h-3.5 w-3.5" />
          Add Filter
        </Button>
      </div>
    </div>
  );
};